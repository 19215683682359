import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  mainPageContainer: theme.mainPageContainer,
  aRight: theme.aRight,
  MarAuto: theme.MarAuto,
  container: {
    height: '100%',
  },
  splitterLayout: {
    //height: `calc(100% - ${theme.sparePathBar + 5}px)`
    display: 'flex',
    flex: 1
  },
  saveButton: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.text.secondary,
    "&:hover": {
      backgroundColor: theme.palette.info.light,
    },
  },
  dialogBox: {
    width: 200,
  },
  box1: {
    display: 'inline-block',
    background: '#ccc',
    border: '1px solid black',
    textAlign: 'center',
    padding: '10px',
    boxSizing: 'border-box',
    marginBottom: '10px',
    overflow: 'hidden',
    position: 'relative',
    margin: '20px',
  },
  floatingDragger: {
    width: '100%',
    height: '100%',
    padding: 1
  },
  editSpareIcon: {
    color: theme.palette.primary.main
  },
  titleIcon: {
    display: 'inline-block'
  },
})
);
