import React, {useEffect, useState} from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

// components
import Layout from "./Layout";

// pages
import Error from "../pages/error";
import Login from "../pages/login";

import useStyles from "./styles";

// context
import { 
  useUserDispatch,
  setIsAuthenticated,
  useIsAuthenticated,
  setUserinfo
} from "../context/UserContext";

import { SnackbarProvider } from 'notistack';
import ListSpares from "pages/spares/listspares"
import EditSpares from "pages/spares/editspare/EditSpare";
import NoAuth from 'pages/error/NoAuth';
import Config from 'config';
/*import cldr from 'cldr-data';
import Globalize from "globalize";*/

export default function App() {
  // global
  const isAuthenticated = useIsAuthenticated();
  const classes = useStyles();
  const userDispatcher = useUserDispatch();
  const [loginChecked, setLoginChecked] = useState(false);

  let local = navigator.language;
  let lang = local.split("-")[1];

 /* Globalize.load(cldr("supplemental/likelySubtags"));
Globalize.load(cldr("supplemental/numberingSystems"));
Globalize.load(cldr("main/es/numbers"));
Globalize.load(cldr("main/en/numbers"));
  var numberFormatter = Globalize().numberFormatter({ maximumFractionDigits: 2 });

  console.log("NUMERO: " + numberFormatter("1001.25"));*/

  const check_for_auth = async () => {
    try {
      let response = await fetch(Config.AUTHBRIDGE_URL + "/userinfo", {
        credentials: 'include', // fetch won't send cookies unless you set credentials
        cache: "no-cache",
        mode: 'cors'
      });
      if (response.ok) {
          let ui = await response.json();
          setIsAuthenticated(userDispatcher, true);
          setUserinfo(userDispatcher, ui);
          setLoginChecked(true);
      } else {
        setIsAuthenticated(userDispatcher, false);
        setLoginChecked(true);
        loadSessionData();
      }
    } catch (e) {
      console.log("ERROR checking user: " + e.message);
      setIsAuthenticated(userDispatcher, false);
      setLoginChecked(true);
    }
  }

  const loadSessionData = () =>{
  }

  useEffect(()=>{
    if (!isAuthenticated) {
      check_for_auth();
    }
  }, []);

  return (

  <SnackbarProvider 
    maxSnack={3} 
    anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
    }}
    classes={{ 
      root: classes.snack,
    }}>

      <HashRouter>
        <Switch>
        {loginChecked && <Route exact path="/" component={MainPageRoute}/>}
          <Route path="/app/noauth" component={NoAuth} />
        {loginChecked && <PrivateRoute path="/app/*" component={Layout} />}
        </Switch>
      </HashRouter>
      
    </SnackbarProvider>
  );

  // #######################################################################

  function MainPageRoute() {
    return (
      <>
      {isAuthenticated?(<Redirect to="/app/spares" />)
      :
      (window.location.replace(Config.AUTHBRIDGE_URL + '/auth/login'))
      }
      </>
    );
  }

  function PrivateRoute({ component, ...rest }) {
    return (
      <>
      {isAuthenticated?(<Route
        {...rest}
        render={props => React.createElement(component, props)}
      />)
      :
      (
            <Redirect
              to={{
                pathname: "/app/noauth",
              }}
            />
          )
        }

      </>
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
            React.createElement(component, props)
        }
      />
    );
  }
}
