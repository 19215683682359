/**
 * Agilemind Soft - Spareclick API
 * Spares API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: alex@agilemindsoft.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import SpareBrand from './SpareBrand';
import SpareCompany from './SpareCompany';

/**
* The SpareSearch model module.
* @module model/SpareSearch
* @version 1.0.0
*/
export default class SpareSearch {
    /**
    * Constructs a new <code>SpareSearch</code>.
    * @alias module:model/SpareSearch
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>SpareSearch</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/SpareSearch} obj Optional instance to populate.
    * @return {module:model/SpareSearch} The populated <code>SpareSearch</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SpareSearch();
                        
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('company')) {
                obj['company'] = SpareCompany.constructFromObject(data['company']);
            }
            if (data.hasOwnProperty('brand')) {
                obj['brand'] = SpareBrand.constructFromObject(data['brand']);
            }
        }
        return obj;
    }

    /**
    * @member {String} id
    */
    'id' = undefined;
    /**
    * @member {String} description
    */
    'description' = undefined;
    /**
    * @member {String} title
    */
    'title' = undefined;
    /**
    * @member {module:model/SpareCompany} company
    */
    'company' = undefined;
    /**
    * @member {module:model/SpareBrand} brand
    */
    'brand' = undefined;




}
