import React, { useState, useRef, useEffect } from "react";
import useStyles from "./styles";

import {
  Button,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Zoom
} from "@material-ui/core";

import {
  Delete as DeleteIcon,
  DoneOutlined as DoneOutlinedIcon,
  Edit as EditIcon,
  Favorite as FavoriteIcon,
  Layers as LayersIcon,
  Visibility as VisibilityIcon,
} from '@material-ui/icons';

import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';

import { useSnackbar } from 'notistack';

import { ResizableBox } from 'react-resizable';
import "./styles.css";
import Draggable from 'react-draggable'; // Both at the same time

import Config from "config";

import {
  useSpareDispatch,
  useCurrentSpare,
  useLoadingSpare,
  useIsSpareModified,
  resetCurrentSpare,
  updateSpareContext,
  setEditingMode,
  useIsEditingSpare,
  setBrowsingMode,
  toggleLoadingSpare,
  setCurrentSpare,
  setRefsModified,
  useIsSpareRefsModified,
  useIsSpareAreasModified
} from "context/SpareContext";
import { errorHandler, showNetworkErrorMsg } from 'utils/fetchutils';

import PageTitle from "components/PageTitle"
import SpareEditor from 'components/spclick/spareeditor';
import SpSpinner from 'components/spclick/SpSpinner';
import SpareTable from 'components/spclick/SpareTable';
import { useRoles } from "context/UserContext";

import AreYouSureDlg from 'components/spclick/AreYouSureDlg';
import { setData, useCursor, useData, useSpareTreeDispatch } from "context/SpareTreeContext";
import { useSpareTreeDriver } from "components/spclick/SpareTree/SpareTreeDriver";
import LevelSelector from 'components/spclick/levelselector';
import Classification from 'apiclient/model/Classification'
import { checkAuth } from "components/spclick/Can";
import Render from "components/spclick/Render";
import { useLocation } from "react-router";

export default function EditSpares(props) {
  const classes = useStyles();
  const { match: { params } } = props;

  const location = useLocation();
  const spareIdParam = params.spareId;

  const spareDispatcher = useSpareDispatch();
  const currentSpare = useCurrentSpare();
  const loadingSpare = useLoadingSpare();
  const isEditingSpare = useIsEditingSpare();
  const userModifications = useIsSpareModified();
  const isSpareRefsModified = useIsSpareRefsModified();
  const isSpareAreasModified = useIsSpareAreasModified();

  const roles = useRoles();

  const { enqueueSnackbar } = useSnackbar();

  const areasRef = useRef([]);
  const refsRef = useRef([]);

  const [spareDescription, setSpareDescription] = useState(currentSpare ? currentSpare.description : "");

  const [breadcrumbData, setBreadcrumbData] = useState([]);

  const areas2Json = (areas) => {
    let newAreas = [];

    newAreas = areas.map((area) => {
      return JSON.parse(JSON.stringify(area.toJSON(), (k, v) => Array.isArray(v) ? v.filter(e => e !== null) : (v === null ? '' : v)));
    });

    return newAreas;
  }

  const onStart = () => {
    //this.setState({activeDrags: ++this.state.activeDrags});
  };

  const onStop = () => {
    //this.setState({activeDrags: --this.state.activeDrags});
  };

  const dragHandlers = { onStart: onStart, onStop: onStop };

  const [floating, setFloating] = useState(false);

  const toggleFloating = () => {
    setFloating(!floating);
  }

  const floatingCss = {
    w: 565,
    h: 285,
    topOffset: 300,
    leftOffset: 200,
    topOverflow: -500
  }

  const [showRemoveDlg, setShowRemoveDlg] = useState(false);
  const spareTreeCursor = useCursor();
  const spareTreeData = useData();
  const spareTreeDispatcher = useSpareTreeDispatch();
  const { onToggle } = useSpareTreeDriver();

  const removeSpareTreeNode = (node2Remove) => {
    const pathIdx = node2Remove.path.split("$$$");

    let parent = spareTreeData;
    let idx = 0;
    while (idx < pathIdx.length - 1) {
      parent = parent.children[pathIdx[idx]];
      idx++;
    }

    parent.children.splice(pathIdx[idx], 1);

    // If no more brothers, parent should also be removed
    if (parent.children.length === 0) {
      removeSpareTreeNode(parent);
    } else {
      setData(spareTreeDispatcher, Object.assign({}, spareTreeData));

      // Close parent node to reload child nodes path on expand
      onToggle(parent, false);
    }
  }

  const onDeleteSpare = () => {
    setShowRemoveDlg(true);
  }

  const updateSpare = (spare) => {
    fetch(Config.SPARESAPI_URL + "/" + spare.id, {
      method: "PUT",
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(spare)
    }).then(response => {
      if (response.ok) {
        updateSpareContext(spareDispatcher, {
          isModified: {
            refs: false,
            areas: false
          }
        });

        enqueueSnackbar('Cambios guardados correctamente!', {
          variant: 'success',
          autoHideDuration: 2500,
        });
      } else {
        errorHandler(response.status, 'No se ha podido guardar el despiece!', enqueueSnackbar);
      }
    }).catch((e) => {
      showNetworkErrorMsg(enqueueSnackbar);
    })
  }

  const removeSpare = (spare) => {
    fetch(Config.SPARESAPI_URL + "/" + spare.id, {
      method: 'DELETE',
      credentials: "include",
    }).then((response) => {
      if (response.ok) {
        resetCurrentSpare(spareDispatcher);
        removeSpareTreeNode(spareTreeCursor);
        enqueueSnackbar('Despiece eliminado correctamente!', {
          variant: 'success',
          autoHideDuration: 2500,
        });
      } else {
        errorHandler(response.status, "No se ha podido eliminar el despiece!", enqueueSnackbar);
      }
    }).catch((e) => {
      showNetworkErrorMsg(enqueueSnackbar);
    })
  }

  const dialActions = [
    { icon: <LayersIcon />, name: 'Float', onClick: toggleFloating },
    { icon: <DeleteIcon />, name: 'Delete', onClick: onDeleteSpare },
    { icon: <FavoriteIcon />, name: 'Like' },
  ];

  const onSpareEditClickHandler = () => {
    setEditingMode(spareDispatcher);
    setSpareDescription(currentSpare.description);
  }

  const onSpareBrowseClickHandler = () => {
    setBrowsingMode(spareDispatcher);
  }

  const loadSpare = async (id, callback) => {
    fetch(Config.SPARESAPI_URL + "/" + id, {
      credentials: 'include', // fetch won't send cookies unless you set credentials
    }).then((resp) => {
      if (resp.ok) {
        resp.json().then((data) => {
          toggleLoadingSpare(spareDispatcher);
          setCurrentSpare(spareDispatcher, data, false);
          callback(data);
          toggleLoadingSpare(spareDispatcher);
        });
      } else {
        errorHandler(resp.status, null, enqueueSnackbar);
      }
    }).catch((e) => {
      showNetworkErrorMsg(enqueueSnackbar);
    })
  }

  const refs2Spare = (refs) =>{
    if (refs) {
      return refs.map(r=>r.ref);
    } else {
      return [];
    }
  }

  useEffect(() => {
    areasRef.current = [];

    let bcData = [{
      "title": "Despieces"
    }];

    if (currentSpare) {
      bcData = bcData.concat(currentSpare.classifications.map((level) => {
        return {
          title: level
        }
      }));
 
      setBreadcrumbData(bcData);
    } else {
      console.log("CLASSIFICATIONS: " + (currentSpare ? JSON.stringify(currentSpare.classifications) : "null"));
    }
  }, [currentSpare]);

  useEffect(() => {
    if (spareIdParam) {
      loadSpare(spareIdParam, (spare) => {
        if (params.mode === Config.SpareActions.EDIT.outcome) {
          setEditingMode(spareDispatcher);
        }
        setSpareDescription(spare.description);
      })
    }

  }, [location]);

  return (
    <>

      { // Spare path breadcrumb
        !loadingSpare && currentSpare && (
          <div className={classes.classificationsContainerLevelsBox}>
            <LevelSelector
              levelsArr={currentSpare.classifications}
              spareId={currentSpare.id}
              companyId={currentSpare.company_id}
              brandId={currentSpare.brand_id}
              onChangeCallBack={(levels) => {

                return new Promise((resolve, reject) => {
                  const url = Config.PUT_CLASSIFICATIONSAPI_URL.replace('$$spareId', currentSpare.id);
                  fetch(url, {
                    method: "PUT",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    credentials: 'include',
                    body: JSON.stringify(Classification.constructFromObject({
                      company_id: currentSpare.company_id,
                      brand_id: currentSpare.brand_id,
                      path: levels
                    }))
                  }).then(response => {
                    if (response.ok) {
                      enqueueSnackbar('Cambios guardados correctamente!', {
                        variant: 'success',
                        autoHideDuration: 2000,
                      });
                      resolve(true);
                    } else {
                      errorHandler(response.status, null, enqueueSnackbar);
                    }
                  }).catch((e) => {
                    showNetworkErrorMsg(enqueueSnackbar);
                  });
                });
              }} />
          </div>
        )}

      { // Page title with icons to edit/browse spare

        ((currentSpare && currentSpare.description && currentSpare.description.length > 0)
          || checkAuth(roles[0], "spares:edit", null)) &&
        <PageTitle
          title={
            isEditingSpare ?
              <TextField id="standard-basic"
                style={{ minWidth: '50%' }}
                label="Introduzca una descripción para el despiece"
                value={spareDescription}
                onChange={(event) => {
                  setSpareDescription(event.target.value);
                  setRefsModified(spareDispatcher, true);
                }} />
              :
              currentSpare?isEditingSpare?spareDescription:currentSpare.description
                : "No hay despiece seleccionado"
          }
          titleAdornement={
            currentSpare &&
            <div className={classes.titleIcon}>
              <Render perform="spares:edit">
                {currentSpare.acls === 'write' ?
                  !isEditingSpare ?
                    <Tooltip title="Editar este despiece">
                      <IconButton
                        className={classes.editSpareIcon}
                        onClick={onSpareEditClickHandler}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    :
                    <Tooltip title="Navegar este despiece">
                      <IconButton
                        className={classes.editSpareIcon}
                        onClick={onSpareBrowseClickHandler}>
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                  : <span></span>
                }
              </Render>
            </div>
          }>

          <div style={{ flex: 1 }}>
            <Zoom in={userModifications} timeout={1000}>
              <Button
                variant="contained"
                className={classes.saveButton}
                startIcon={<DoneOutlinedIcon />}
                onClick={() => {

                  if (isSpareAreasModified) {
                    currentSpare.areas = areas2Json(areasRef.current);
                  }

                  if (isSpareRefsModified) {
                    currentSpare.refs = refs2Spare(refsRef.current);
                  }

                  currentSpare.description_translations = [];
                  currentSpare.classification_translations = [];
                  currentSpare.description = spareDescription;
                  updateSpare(currentSpare);

                }}>
                Guardar
            </Button>
            </Zoom>
          </div>
        </PageTitle>
      }
      <Paper className={classes.mainPageContainer}
        style={{ position: 'relative' }}>

        {floating && (
          <>
            <SpareEditor
              dialActions={dialActions}
              areasRef={areasRef}
              refsRef={refsRef} />

            <Draggable
              axis="both"
              bounds={{ left: -(floatingCss.leftOffset + (floatingCss.w*0.9)), top: -floatingCss.topOffset, right: (window.innerWidth-floatingCss.leftOffset) - (floatingCss.w *0.1), bottom: (window.innerHeight - (floatingCss.w / 2)) }}
              handle="#dragger"  {...dragHandlers}
              position={null}>
              <div id="xx" style={{
                zIndex: 2000,
                position: 'fixed',
                top: floatingCss.topOffset,
                left: floatingCss.leftOffset
              }}>
                <ResizableBox className="box no-cursor"
                  width={floatingCss.w} height={floatingCss.h} axis="both"
                  minConstraints={[floatingCss.w, floatingCss.h]}
                  resizeHandles={['e', 'se', 'ne', 's']}>

                  <div id="dragger" className={classes.floatingDragger}>
                    <SpareTable
                      floating={floating}
                      areasRef={areasRef}
                      refsRef={refsRef} />
                  </div>
                </ResizableBox>
              </div>
            </Draggable>
          </>
        )}
        {!floating && (
          <SplitterLayout id="splitterbox"  primaryIndex={0} percentage={true} primaryMinSize={30} 
            customClassName={classes.splitterLayout}>
            <SpareEditor
              dialActions={dialActions}
              areasRef={areasRef}
              refsRef={refsRef} />
            <SpareTable
              floating={floating}
              areasRef={areasRef}
              refsRef={refsRef}/>
          </SplitterLayout>
        )}

        {loadingSpare &&
          <SpSpinner />
        }
      </Paper>

      {currentSpare &&
        showRemoveDlg &&
        <AreYouSureDlg
          detail1={"Se va a eliminar el despiece: "}
          detail2={"\"" + JSON.stringify(currentSpare.classifications).replace(/\[|\]|"/g, '').replace(/,/g, ' ') + "\""}
          question={"Está seguro?"}
          ok={() => {
            setShowRemoveDlg(false);
            removeSpare(currentSpare);
          }}
          cancel={() => {
            setShowRemoveDlg(false);
          }} />
      }
    </>
  );

}
