class utils {

  /**
   * Returns offset from html page top-left corner for some element
   *
   * @param node {HTMLElement} - html element
   * @returns {Object} - object with offsets, e.g. {x: 100, y: 200}
   */
   getOffset = (node) => {
    var boxCoords = node.getBoundingClientRect();

    return {
      x: Math.round(boxCoords.left + window.pageXOffset),
      y: Math.round(boxCoords.top + window.pageYOffset)
    };
  }

  /**
   * Returns correct coordinates (incl. offsets)
   *
   * @param x {number} - x-coordinate
   * @param y {number} - y-coordinate
   * @returns {Object} - object with recalculated coordinates, e.g. {x: 100, y: 200}
   */
   getRightCoords = (state, x, y) => {

    return {
      x: x - state.offset['x'],
      y: y - state.offset['y']
    };
  }

  /**
   * TODO: will use same method of app.js
   * @deprecated
   */
  id = (str) => {
    return document.getElementById(str);
  }

  findByClassName = (str) => {
    return document.getElementsByClassName(str);
  }

  /**
   * TODO: will use same method of app.js
   * @deprecated
   */
  hide = (node) => {
    node.style.display = 'none';

    return this;
  }

  /**
   * TODO: will use same method of app.js
   * @deprecated
   */
  show = (node) => {
    node.style.display = 'block';

    return this;
  }

  /**
   * Escape < and > (for code output)
   *
   * @param str {string} - a string with < and >
   * @returns {string} - a string with escaped < and >
   */
  encode = (str) => {
    return str.replace(/</g, '&lt;').replace(/>/g, '&gt;');
  }

  /**
   * TODO: will use same method of app.js
   * @deprecated
   */
  foreach = (arr, func) => {
    for (var i = 0, count = arr.length; i < count; i++) {
      func(arr[i], i);
    }
  }

  /**
   * TODO: will use same method of app.js
   * @deprecated
   */
  foreachReverse = (arr, func) => {
    for (var i = arr.length - 1; i >= 0; i--) {
      func(arr[i], i);
    }
  }

  /**
   * Display debug info to some block
   */
  debug = () => {
    var output = document.getElementById('debug');

    return function() {
      output.innerHTML = [].join.call(arguments, ' ');
    };
  }

  /**
   * TODO: will use same method of app.js
   * @deprecated
   */
  stopEvent = (e) => {
    e.stopPropagation();
    e.preventDefault();

    return this;
  }

  /**
   * TODO: will use same method of app.js
   * @deprecated
   */
  extend = (obj, options) => {
    var target = {};

    for (var name in obj) {
      if (obj.hasOwnProperty(name)) {
        target[name] = options[name] ? options[name] : obj[name];
      }
    }

    return target;
  }

  inherits = () => {
    var F = function() {};

    return function(Child, Parent) {
      F.prototype = Parent.prototype;
      Child.prototype = new F();
      Child.prototype.constructor = Child;
    };
  }
};

export default new utils();
