import React from 'react';
import { Box } from '@material-ui/core';
import useStyles from './styles'

export default function ContentWrapper(props) {
    const classes = useStyles();

    return (
        <Box className={classes.contentBox}>
            {props.children}
        </Box>
    )
}