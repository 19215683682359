import { makeStyles } from "@material-ui/styles";


export default makeStyles(theme => ({
    root: {
        color: theme.palette.primary.main,
        "&:link": {
            color: theme.palette.primary.main,
        },
        "&:visited": {
            color: theme.palette.primary.main,
        },
    }
}))