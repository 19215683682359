import React from "react";

var SparePickerStateContext = React.createContext();
var SparePickerDispatchContext = React.createContext();

const reducer = (state, action) => {
  console.log("-------------> " + JSON.stringify(action));
  switch (action.type) {
    case "TOGGLE_ITEMDIALOG":
      return {...state,
        isSpareItemDialogOpened: !state.isSpareItemDialogOpened,
        spare: action.spare,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const SparePickerProvider = ({children}) => {
  var [state, dispatch] = React.useReducer(reducer, {
    isSpareItemDialogOpened: false,
  });

  return (
    <SparePickerStateContext.Provider value={state}>
      <SparePickerDispatchContext.Provider value={dispatch}>
        {children}
      </SparePickerDispatchContext.Provider>
    </SparePickerStateContext.Provider>
  );
}

function useSparePickerState() {
  var context = React.useContext(SparePickerStateContext);
  if (context === undefined) {
    throw new Error("useSparePickerState must be used within a SparePickerProvider");
  }
  return context;
}

function useSparePickerDispatch() {
  var context = React.useContext(SparePickerDispatchContext);
  if (context === undefined) {
    throw new Error("useSpareDispatch must be inside a SparePickerProvider");
  }
  return context;
}

export {
  SparePickerProvider, useSparePickerState, useSparePickerDispatch,
  toggleSparePickerItemDialog
}

const toggleSparePickerItemDialog = (dispatch, spare) =>{
  let zz = {
    type: 'TOGGLE_ITEMDIALOG',
  };

  if (spare) {
    zz.spare = spare;
  }
  dispatch(zz);
}
