import React from 'react';
import {
    FormControl,
    MenuItem,
    Select,
} from "@material-ui/core";
import useStyles from "./styles";

const LanguageSelect = (props) => {
    const handleLangSelectionChange = props.handleLangSelectionChange;
    const lang = props.initialValue;

    const classes = useStyles();

    const availableLangs = [{
        id: "en",
        title: "English"
    }, {
        id: "es",
        title: "Español"
    }, {
        id: "fr",
        title: "Français"
    }, {
        id: "it",
        title: "Italiano"
    }, {
        id: "pt",
        title: "Português"
    }];

    return (
        <FormControl>
        <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={lang}
            onChange={handleLangSelectionChange}
            label="Language">
            {
                availableLangs.map(lang => <MenuItem key={lang.id} value={lang.id}>{lang.title}</MenuItem>)
            }
        </Select>
        </FormControl>
    )
}

export default LanguageSelect