import Rectangle from './classes/Rectangle.js';
import Circle from './classes/Circle.js';
import Polygon from './classes/Polygon.js';

const createOverlay = (viewerVar, spare) => {
    let overlay = viewerVar.svgOverlay(
      spare.picture !== undefined?spare.picture.width:1
    );

    window.addEventListener('resize', function() {
        overlay.resize();
    });

    return overlay;
 }

 function generateOverlayAreas(spare, domElements, appState, onAreaClickListener) {
    let elements = [];
    let element;
    let coords;

    spare.areas.map( (area,idx) => {

      let attributes = {
        id: domElements.svg.childElementCount
      };

      switch (area.shape) {
        case 'rectangle':
          if (typeof area.coords == 'string') {
            let [_left, _bottom, _right, _top] = area.coords.split(',');
            _left = parseFloat(_left);
            _top = parseFloat(_top);
            _right = parseFloat(_right);
            _bottom = parseFloat(_bottom);

            coords = {
              x: (_left < _right?_left:_right),
              y: (_top < _bottom?_top:_bottom),
              width: Math.abs(_right - _left),
              height: Math.abs(_bottom - _top)
            };
          } else {
            coords = area.coords;
          }

          element = new Rectangle(
            coords,
            attributes, {
              offset: appState.offset,
              editType: appState.editType
            },onAreaClickListener
          );
        break;

        case 'circle':
          if (typeof area.coords == 'string') {
            let [_cx, _cy, _radius] = area.coords.split(',');
            _cx = parseFloat(_cx);
            _cy = parseFloat(_cy);
            _radius = parseFloat(_radius);
  
            coords = {
                cx: _cx,
                cy: _cy,
                radius: _radius
            };
          } else {
            coords = area.coords;
          }

          element = new Circle(
            coords,
            attributes, {
              offset: appState.offset,
              editType: appState.editType
            },
            onAreaClickListener
          );
        break;
        case 'polygon':
          let _points = area.coords.points;

          element = new Polygon({
              points: _points
            },
            attributes, {
              offset: appState.offset,
              editType: appState.editType
            },
            onAreaClickListener
          );
        break;
        default:
        break;
      }

      /*
      _left = parseFloat(_left);
      _top = parseFloat(_top);
      _right = parseFloat(_right);
      _bottom = parseFloat(_bottom);

      let element = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
      element.setAttribute("x", (_left < _right?_left:_right));
      element.setAttribute("y", (_top < _bottom?_top:_bottom));
      element.setAttribute("width", Math.abs(_right - _left));
      element.setAttribute("height", Math.abs(_bottom - _top));
      element.setAttribute("style", "cursor: pointer;");
      element.setAttribute("fill", "transparent");
      element.setAttribute("stroke", "#FE9700");
      element.setAttribute("stroke-width", "1");
      element.setAttribute('data-node', JSON.stringify(area));*/

      /*element.setAttribute('onClick', (ev) => {
        ReactDOM.render(
        React.createElement(SpareItemDialog, { spare: ev.target.getAttribute('data-node')}, null),
        document.getElementById('yy'));
      });*/
     /* element.addEventListener('click', function(ev) {
        ev.preventDefault();
          console.log('click', ev.target.getAttribute('data-node'));*/

          /*    ReactDOM.render(
                React.createElement(SparePickerProvider, {},
                React.createElement(SpareItemDialog, { spareItem: ev.target.getAttribute('data-node')}, null)
              ), document.getElementById('yy'));*/

   /*   });*/
                
      element._attributes = attributes;
      element.ref_link = area.ref_link;
      elements.push(element);

    });

    return elements;
}



 export {
    createOverlay,
    generateOverlayAreas
 }