import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Typography,
  Zoom,
} from "@material-ui/core";
import {
  Delete as TrashIcon,
} from "@material-ui/icons";
import { DropzoneArea } from "material-ui-dropzone";

import { useSnackbar } from "notistack";

import useStyles from "./styles";
import Config from "config";

function getSteps() {
  return ["Nombre corto de la empresa", "Imagen del logo"];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return "Nombre corto";
    case 1:
      return "Logo";
    default:
      return "Unknown stepIndex";
  }
}

const CreateCompanyDlg = ({ open, onSubmit, onClose }) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = React.useState(0);

  const steps = getSteps();

  const handleNext = () => {
    if (!company.name || company.name.length === 0) {
      enqueueSnackbar('Debe introducir un nombre!', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [company, setCompany] = useState({
    name: "INTERNACO2",
    logo: null,
  });

  const submitHandler = () => {

    fetch(Config.BRANDSAPI_URL, {
      method: "POST",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: company.name,
        logo: company.logo
      })
    }).then((response) => {
      if (response.ok) {
        enqueueSnackbar('Empresa creada correctamente!', {
          variant: 'success',
          autoHideDuration: 2000,
        });
        onSubmit();
      } else if (response.status === 401) {
        enqueueSnackbar('Operación no autorizada!', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      } else if (response.status === 409) {
        enqueueSnackbar('Ya existe una empresa con ese nombre!', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      } else if (response.status === 429) {
        enqueueSnackbar('Ha superado el máximo de empresas que puede crear. Si necesita más empresas póngase en contacto con Soporte.', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      } else {
        enqueueSnackbar('No se ha podido realizar la operación!', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    }).catch((e) => {
      enqueueSnackbar('No se ha podido realizar la operación!', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    });
  }

  const handleCapturePicture = (files) => {
    const fileReader = new FileReader();

    fileReader.readAsDataURL(files);
    // toggleLoadingSpare(spareDispatcher);

    fileReader.onload = (e) => {

      var image = new Image();
      image.src = e.target.result;

      image.onload = function () {
        // access image size here 
        setCompany({ ...company, logo: e.target.result });

        /*      setCurrentSpare(spareDispatcher, spare);
              toggleLoadingSpare(spareDispatcher);*/
      }
    }
  };

  const onRemoveImageHandler = () => {
    setCompany({ ...company, logo: null });
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Alta de empresa</DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.root}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel classes={{ label: classes.stepLabel }}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === 0 ? (
              <div className={classes.screen1}>
                <Typography size="md" className={classes.details}>
                  Un nombre por el que sus clientes la reconozcan y que{" "}
                  <span
                    style={{ fontWeight: "bold", textDecoration: "underlined" }}>
                    legalmente
                  </span>{" "}
                  pueda utilizar
                </Typography>
                <TextField
                  required
                  inputProps={{ maxLength: 20 }}
                  variant="outlined"
                  value={company.name}
                  label="Nombre corto"
                  onChange={(event) =>
                    setCompany({ ...company, name: event.target.value.toUpperCase() })
                  }></TextField>
                <div className={classes.nextButtonContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}>
                    Siguiente
                  </Button>
                </div>
              </div>
            ) : (
                <div className={classes.screen2}>
                  {company.logo ?
                    <div className={classes.logoImageContainer}>
                      <IconButton
                        className={classes.logoImageTrashIcon}
                        color="inherit"
                        onClick={onRemoveImageHandler}>
                        <TrashIcon />
                      </IconButton>
                      <img src={company.logo} alt="imagen no válida" className={classes.logo} />



                    </div>
                    : <><DropzoneArea
                      onDrop={handleCapturePicture}
                      dropzoneClass={classes.MuiDropzoneAreaRoot}
                      dropzoneParagraphClass={classes.MuiDropzoneAreaText}
                      filesLimit={1}
                      showPreviews={false}
                      showPreviewsInDropzone={false}
                      acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                    />
                      <div className={classes.logoMaxSizeWarning}>
                        <Typography size="sm">
                          Tamaño máximo 320x200. Si la imagen supera estas dimensiones será automáticamente redimensionada.
                      </Typography>
                      </div>
                    </>
                  }
                  <div className={classes.screen2ButtonsContainer}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleBack}
                      className={classes.button}>
                      Anterior
                  </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={submitHandler}
                      className={classes.button}>
                      Enviar
                  </Button>
                  </div>

                </div>
              )}
          </div>
        </div>
      </DialogContent>
    </Dialog>

  );
};

export default CreateCompanyDlg;
