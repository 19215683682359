import React, {
    forwardRef, useState, useEffect, useRef
} from "react"

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Fab,
    Link,
    TextField
} from "@material-ui/core";

import {
    Typography
} from "../../components/Wrappers";
import { DropzoneDialog } from 'material-ui-dropzone';

import {
    Add as AddIcon,
    ArrowDownward,
    MenuBook as BookIcon,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    Delete,
    DeleteOutline,
    Edit,
    EditOutlined,
    ExpandMore as ExpandMoreIcon,
    FilterList,
    FirstPage,
    LastPage,
    People as PeopleIcon,
    Remove,
    SaveAlt,
    Search,
    Security,
    ViewColumn
} from "@material-ui/icons";

import MaterialTable, {
    MTableToolbar,
} from "material-table";

import {
    checkAuth,
} from "components/spclick/Can";

import Render from 'components/spclick/Render';
import { useSnackbar } from 'notistack';

import useStyles from "./styles";
import PageTitle from "../../components/PageTitle/PageTitle";

import Config from 'config';
import { useRoles } from "context/UserContext";
import CreateCompanyDlg from 'components/spclick/CreateCompanyDlg';
import BrandAclsDialog from "components/spclick/BrandAclsDialog";
import ContentWrapper from "components/spclick/ContentWrapper";
import { useTheme } from "@material-ui/styles";
import MyBreadCrumb from 'components/spclick/MyBreadCrumb';


export default function EditBrand(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();

    const roles = useRoles();
    const [currentBrand, setCurrentBrand] = useState({
        name: ''
    });
    const [companies, setCompanies] = useState(null);
    const [showBrandLogoDlg, setShowBrandLogoDlg] = useState(false);
    const [showCreateCompanyDlg, setShowCreateCompanyDlg] = useState(false);

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddIcon {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    const brandsTableOptions = {
        filterType: 'checkbox',
        responsive: "simple",
        actionsColumnIndex: -1,
        paging: false,
        addRowPosition: 'first',
        search: false,
        header: false,
        rowStyle: {
            ...theme.text.regular,
        }
    };

    const brandsTableColumns = [{
        field: 'name',
        title: 'Marca',
        searchable: true,
    }, {
        field: 'roles[0]',
        title: 'Rol',
        editable: 'never',
        searchable: false,
    }, {
        field: 'logo',
        title: 'Logo',
        editable: 'never',
        searchable: false,
        render: rowData => (
            <Render perform="spares:edit">
                <Link href="#" onClick={(e) => {
                    setShowBrandLogoDlg(true);
                    setCurrentBrand(rowData);
                    e.preventDefault();
                }}>
                    {
                        rowData._links.logo ?
                            (<Typography
                                variant="h5"
                                size="sm"
                                className={classes.typo}>
                                <img src={rowData._links.logo + "?d=" + Date.now()} alt="Pinche aquí para añadir logo" style={{ maxWidth: '200px' }} />
                            </Typography>
                            )
                            : (
                                <Typography
                                    variant="h2"
                                    size="sm"
                                    className={classes.typo}>Añadir logo</Typography>
                            )
                    }
                </Link>
            </Render>
        )
    }];

    const createCompanyDlgCloseHandler = () => {
        setShowCreateCompanyDlg(false);
    }

    const createCompanyDlgSubmitHandler = () => {
        setShowCreateCompanyDlg(false);
        loadCompanies();
    }

    const uploadBrandLogoHandler = (files) => {
        const fileReader = new FileReader();

        fileReader.readAsDataURL(files);
        fileReader.onload = (e) => {

            var image = new Image();
            image.src = e.target.result;

            image.onload = function () {
                updateBrand(currentBrand, currentBrand.name, e.target.result);
            }
        }
    };

    const createBrand = (company, brandName) => {
        const request_body = {
            name: brandName,
            logo: null
        }

        fetch(company._links.navigation.self + "/brands", {
            method: "POST",
            credentials: 'include',
            body: JSON.stringify(request_body),
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
            if (response.ok) {
                enqueueSnackbar('Marca añadida correctamente!', {
                    variant: 'success',
                    autoHideDuration: 2000,
                });
                response.json().then((newBrand) => {
                    const companyIndex = companies.findIndex((c) => c.name === company.name);
                    newBrand.companyIndex = companyIndex;
                    company.brands.unshift(newBrand);

                    companies[companyIndex] = company;
                    setCompanies([...companies]);
                })

            } else if (response.status === 401) {
                enqueueSnackbar('Operación no autorizada!', {
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            } else {
                enqueueSnackbar('No se ha podido realizar la operación!', {
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            }
        }).catch((e) => {
            enqueueSnackbar('No se ha podido realizar la operación!', {
                variant: 'error',
                autoHideDuration: 2000,
            });
        });
    }

    const updateCompany = (company, logo) => {

        const request_body = {
            name: company.name,
            logo: logo
        }

        fetch(company._links.navigation.self, {
            method: "PUT",
            credentials: 'include',
            body: JSON.stringify(request_body),
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
            if (response.ok) {
                enqueueSnackbar('Empresa actualizado correctamente!', {
                    variant: 'success',
                    autoHideDuration: 2000,
                });
                response.json().then((newCompany) => {
                    const companyIndex = companies.findIndex((c) => c.name === company.name);
                    updateCompanyIndex(newCompany, companyIndex);
                    companies[companyIndex] = newCompany;
                    setCompanies([...companies]);
                })

            } else if (response.status === 401) {
                enqueueSnackbar('Operación no autorizada!', {
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            } else if (response.status === 409) {
                enqueueSnackbar('Ese nombre de empresa ya está siendo utilizado!', {
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            } else {
                enqueueSnackbar('No se ha podido realizar la operación!', {
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            }
        }).catch((e) => {
            enqueueSnackbar('No se ha podido realizar la operación!', {
                variant: 'error',
                autoHideDuration: 2000,
            });
        });
    }

    const updateBrand = (brand, brand_name, logo) => {

        const request_body = {
            name: brand_name,
            logo: logo
        }

        fetch(brand._links.navigation.self, {
            method: "PUT",
            credentials: 'include',
            body: JSON.stringify(request_body),
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
            if (response.ok) {
                enqueueSnackbar('Marca actualizada correctamente!', {
                    variant: 'success',
                    autoHideDuration: 2000,
                });
                response.json().then((newBrand) => {
                    // Refresh current company's brands
                    const company = companies[brand.companyIndex];

                    newBrand.companyIndex = brand.companyIndex;

                    const brandIndex = company.brands.findIndex((brandItem) => brandItem.name === brand.name);
                    company.brands[brandIndex] = newBrand;

                    // Refresh current companies list
                    setCompanies([...companies]);
                })

            } else if (response.status === 401) {
                enqueueSnackbar('Operación no autorizada!', {
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            } else {
                enqueueSnackbar('No se ha podido realizar la operación!', {
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            }
        }).catch((e) => {
            enqueueSnackbar('No se ha podido realizar la operación!', {
                variant: 'error',
                autoHideDuration: 2000,
            });
        });
    }

    const deleteBrand = (company_name, brand) => {
        fetch(brand._links.navigation.self, {
            method: "DELETE",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
            if (response.ok) {
                // Refresh current company's brands
                const company = companies[brand.companyIndex];

                const brandIndex = company.brands.findIndex((brandItem) => brandItem.name === brand.name);
                company.brands.splice(brandIndex, 1);

                // Refresh current companies list
                setCompanies([...companies]);

                enqueueSnackbar('Marca eliminada correctamente!', {
                    variant: 'success',
                    autoHideDuration: 2000,
                });

            } else if (response.status === 401) {
                enqueueSnackbar('Operación no autorizada!', {
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            } else {
                enqueueSnackbar('No se ha podido realizar la operación!', {
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            }
        }).catch((e) => {
            enqueueSnackbar('No se ha podido realizar la operación!', {
                variant: 'error',
                autoHideDuration: 2000,
            });
        });
    }

    const loadCompanies = () => {
        fetch(Config.BRANDSAPI_URL + "?roles=adm", {
            credentials: 'include',
            headers: {
                "Content-type": "application/json",
            }
        }).then((response) => {
            if (response.ok) {
                response.json().then((items) => {
                    items.companies.forEach((c, idx) => {
                        updateCompanyIndex(c, idx);
                    });

                    setCompanies(sortCompanies(items.companies));
                })
            } else {
                enqueueSnackbar('No se ha podido obtener el listado de marcas. Por favor, vuelva a intentarlo más tarde', {
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            }
        }).catch((e) => {
            enqueueSnackbar('Se ha producido un error. Por favor, vuelva a intentarlo más tarde', {
                variant: 'error',
                autoHideDuration: 2000,
            });
        });
    }

    const updateCompanyIndex = (company, idx) => {
        company.brands.forEach((b) => b.companyIndex = idx);
    }
    const sortCompanies = (companies) => {

        companies.sort((c1, c2) => {
            return c1.name > c2.name;
        })

        companies.forEach((comp) => {
            comp.brands.sort((b1, b2) => {
                return b1.name > b2.name
            });
        })

        return companies;
    }

    // COMPANY DETAIL
    const CompanyDetailPanel = ({ company }) => {

        const [aclsDialogOpen, setAclsDialogOpen] = useState(false);
        const brandAclsData = useRef({});

        // Security ACLS dialog
        const openBranAclsDialog = async (rowData) => {

            const url = rowData._links.acls;

            const resp = await fetch(url, {
                credentials: 'include',
            });

            if (resp.ok) {
                const data = await resp.json();

                brandAclsData.current.cname = company.name;
                brandAclsData.current.bname = rowData.name;
                brandAclsData.current.acls = data;
                brandAclsData.current.url = url;
                setAclsDialogOpen(true);
            } else if (resp.status === 401) {
                enqueueSnackbar('Operación no autorizada!', {
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            } else {
                enqueueSnackbar('No se ha podido realizar la operación!', {
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            }
        };

        const handleAclsDialogClose = () => {
            setAclsDialogOpen(false);
        };

        return (
            <div className={classes.brandsTableBox}>
                <MaterialTable
                    className={classes.datatable}
                    icons={tableIcons}
                    responsive="simple"
                    title={""}
                    data={company.brands}
                    columns={brandsTableColumns}
                    options={brandsTableOptions}
                    color="primary"
                    style={{ 'width': '100%' }}
                    editable={checkAuth(roles[0], "brands:edit", null) ? {
                        isEditable: rowData => true,
                        onRowAdd: newData =>
                            new Promise((resolve, reject) => {
                                createBrand(company, newData.name);
                                resolve();
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    const dataUpdate = [...companies];
                                    const index = oldData.tableData.id;
                                    dataUpdate[index] = newData;
                                    updateBrand(company.brands.filter((b) => b.name === oldData.name)[0], newData.name, null);
                                    resolve();
                                }, 1);
                            }),
                        onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    const dataDelete = [...companies];
                                    const index = oldData.tableData.id;
                                    dataDelete.splice(index, 1);

                                    const companyIndex = oldData.companyIndex;
                                    deleteBrand(companies[companyIndex].name, oldData);

                                    resolve();
                                }, 1000);
                            })
                    } : {}}
                    components={{
                        Toolbar: props => (
                            <MTableToolbar {...props}
                                classes={{
                                    root: classes.muitableActionRoot
                                }}
                            >
                            </MTableToolbar>
                        )
                    }}
                    actions={[{
                        tooltip: "Opciones de seguridad",
                        onClick: (e, rowData) => openBranAclsDialog(rowData),
                        icon: () => <Security />
                    }]}>
                </MaterialTable>

                {
                    aclsDialogOpen && <BrandAclsDialog
                        open={aclsDialogOpen}
                        handleAclsDialogClose={handleAclsDialogClose}
                        cname={brandAclsData.current.cname}
                        bname={brandAclsData.current.bname}
                        acls={brandAclsData.current.acls}
                        brandAclsUrl={brandAclsData.current.url}
                    />
                }
            </div>
        )
    }

    const CompanyHeader = ({ company, showLogoDialogHandler }) => {
        const [newCompany, setNewCompany] = useState({ name: company.name });
        const [editing, setEditing] = useState(false);

        const editCompanyHandler = (event) => {
            event.stopPropagation();
            setEditing(true);
        }

        return (
            <>
                <div className={classes.companyHeaderContainer}>
                    <div className={classes.companyHeaderName}>
                        {
                            editing ?
                                <TextField id="standard-basic"
                                    label=""
                                    autoFocus={true}
                                    value={newCompany.name}
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={e => {
                                        setNewCompany({
                                            ...newCompany, name: e.target.value
                                        })
                                    }} />
                                : <Typography
                                    variant="h5"
                                    className={classes.companyName}>{company.name}</Typography>
                        }
                    </div>
                    <div className={classes.companyHeaderLogo}>
                        <Render perform="spares:edit">
                            { }
                            <Link href="#" onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                showLogoDialogHandler(true);
                            }}>
                                {
                                    company._links.logo ?
                                        (<Typography
                                            variant="h5"
                                            size="sm"
                                            className={classes.typo}>

                                            <img src={company._links.logo + "?d=" + Date.now()} alt="Pinche aquí para añadir logo" style={{ maxWidth: '200px' }} />

                                        </Typography>)
                                        : (<Typography
                                            variant="h2"
                                            size="sm"
                                            className={classes.typo}>Click para añadir logo</Typography>)
                                }
                            </Link>
                        </Render>
                    </div>
                    <div className={classes.companyHeaderActions}>
                        {
                            editing ?
                                <>
                                    <Fab aria-label="save" size="small"
                                        className={classes.companyHeaderAction}
                                        classes={{
                                            root: classes.companyHeaderActionRoot,
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            company.name = newCompany.name;
                                            updateCompany(company);
                                        }}>
                                        <Check />
                                    </Fab>
                                    <Fab aria-label="cancel" size="small"
                                        className={classes.companyHeaderAction}
                                        classes={{
                                            root: classes.companyHeaderActionRoot,
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setEditing(false);
                                            setNewCompany({ name: company.name });
                                        }}>
                                        <Clear />
                                    </Fab>
                                </>
                                :
                                <>
                                    <Fab aria-label="company users" size="small"
                                        title="Gestionar usuarios"
                                        className={classes.companyHeaderAction}
                                        classes={{
                                            root: classes.companyHeaderActionRoot,
                                        }}
                                        onClick={() => {
                                            props.history.push({
                                                pathname: '/app/companyusers/' + company.name,
                                            })
                                        }}>
                                        <PeopleIcon />
                                    </Fab>
                                    <Fab aria-label="catalog" size="small"
                                        title="Gestionar el catálogo de referencias"
                                        className={classes.companyHeaderAction}
                                        classes={{
                                            root: classes.companyHeaderActionRoot,
                                        }}
                                        onClick={() => {
                                            props.history.push({
                                                pathname: '/app/catalog/' + company.id + "/" + company.name,
                                            })
                                        }}>
                                        <BookIcon />
                                    </Fab>
                                    <Fab aria-label="edit" size="small"
                                        className={classes.companyHeaderAction}
                                        classes={{
                                            root: classes.companyHeaderActionRoot,
                                        }}
                                        onClick={(e) => editCompanyHandler(e)}>
                                        <EditOutlined />
                                    </Fab>

                                    <Fab aria-label="trash" size="small"
                                        className={classes.companyHeaderAction}
                                        classes={{
                                            root: classes.companyHeaderActionRoot,
                                        }}
                                        onClick={(e) => editCompanyHandler(e)}>
                                        <DeleteOutline />
                                    </Fab>
                                </>
                        }
                    </div>
                </div>

            </>
        )
    }

    const Company = ({ company }) => {
        const [showCompanyLogoDlg, setShowCompanyLogoDlg] = useState(false);

        const [expanded, setExpanded] = useState(true);
        const toggleExpanded = () => {
            setExpanded(!expanded);
        }

        const uploadCompanyLogoHandler = (files) => {
            const fileReader = new FileReader();

            fileReader.readAsDataURL(files);
            fileReader.onload = (e) => {
                var image = new Image();
                image.src = e.target.result;

                image.onload = function () {
                    updateCompany(company, e.target.result);
                }
            }
        };

        return (<>
            <Accordion square
                key={company.name}
                expanded={expanded}
                onChange={() => toggleExpanded()}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
                    classes={{
                        root: classes.companyAccordionSummaryRoot
                    }}>

                    <CompanyHeader
                        company={company}
                        showLogoDialogHandler={setShowCompanyLogoDlg} />

                </AccordionSummary>
                <AccordionDetails>
                    <CompanyDetailPanel company={company} />
                </AccordionDetails>
            </Accordion>

            <DropzoneDialog
                acceptedFiles={['image/jpeg', 'image/png']}
                cancelButtonText={"cancel"}
                maxFileSize={1100000}
                open={showCompanyLogoDlg}
                onClose={() => setShowCompanyLogoDlg(false)}
                onDrop={(files) => {
                    uploadCompanyLogoHandler(files);
                    setShowCompanyLogoDlg(false);
                }}
                filesLimit={1}
                showPreviews={false}
                showPreviewsInDropzone={false}
                fullWidth={false}
                maxWidth="lg"
                submitButtonText=""
                dialogTitle={"Enviar logo para la empresa " + company.name}
            />
        </>
        );
    }

    useEffect(() => {
        loadCompanies();
    }, []);


    const hasCompanies = () => (companies && companies.length > 0)

    const breadcrumbData = [{
        "title": "Brands",
        "url": "/app/brands"
    }];

    return (
        <ContentWrapper>
            <MyBreadCrumb data={breadcrumbData} history={props.history} />

            <PageTitle title="My companies" />

            {companies !== null && hasCompanies() ? <>
                {companies.map((company, idx) => (

                    <Company key={company.name} company={company} />
                ))
                }
                <DropzoneDialog
                    acceptedFiles={['image/jpeg', 'image/png']}
                    cancelButtonText={"cancel"}
                    maxFileSize={1100000}
                    open={showBrandLogoDlg}
                    onClose={() => setShowBrandLogoDlg(false)}
                    onDrop={(files) => {
                        uploadBrandLogoHandler(files);
                        setShowBrandLogoDlg(false);
                    }}
                    filesLimit={1}
                    showPreviews={false}
                    showPreviewsInDropzone={false}
                    fullWidth={false}
                    maxWidth="lg"
                    submitButtonText=""
                    dialogTitle={"Enviar logo para la marca " + (currentBrand ? currentBrand.name : "")}
                />
            </> :
                <>
                    {companies !== null && <>
                        <div className={classes.noCompany}>
                            <Typography className={classes.text} size="md" weight="medium">
                                Todavía no ha creado ninguna empresa.
                    </Typography>
                            <Button color="primary" variant="contained"
                                onClick={() => {
                                    setShowCreateCompanyDlg(true);
                                }}>Crear empresa</Button>
                        </div>

                        {showCreateCompanyDlg
                            && <CreateCompanyDlg
                                open={showCreateCompanyDlg}
                                onClose={createCompanyDlgCloseHandler}
                                onSubmit={createCompanyDlgSubmitHandler} />
                        }
                    </>}
                </>}
        </ContentWrapper>
    );
}
