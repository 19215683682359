import {
    makeStyles
} from '@material-ui/styles';

export default makeStyles(theme => ({
    container: {
        "& a": {
            color: theme.palette.primary.main
        },
        "& a:link": {
            color: theme.palette.primary.main
        },
        "& a:visited": {
            color: theme.palette.primary.main
        }
    },
    separator: {
        height: 1,
        width: theme.spacing(1)
    },
    importExcelHelpIcon: {
        width: 30,
        position: 'relative',
        top: -5,
        paddingLeft: 3,
    },
    downloadTemplateLink: {
        marginLeft: 20
    },
    popoverPaper: {
        padding: theme.spacing(3),
        backgroundColor: "white !important",
        zIndex: 9999999999999999
    },
    popover: {
        pointerEvents: 'none',
    },
    propertiesList: {
        listStyleType: "disc",
        marginLeft: theme.spacing(2)
    },
    propertyTitle: {
        fontWeight: 'bold',
        color: theme.palette.primary.main
    },
    mandatoryProperty: {
        fontWeight: 'bold',
    },
    popoverSeparator: {
        height: 1,
        width: '90%',
        margin: '0 auto',
        backgroundColor: theme.disabledElement.color,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    otherLanguages: {
        fontWeight: 'bold'
    },
    langCode: {
        fontWeight: 'bold'
    },
}));