import OpenSeaDragon from "openseadragon";

function OpenSeaDragonOverlay() {
}

  OpenSeaDragonOverlay.prototype.doOverlay = function(viewer, imgWidth, imgHeight) {
    var $ = window.OpenSeadragon;

    if (!$) {
        $ = require('openseadragon');
        if (!$) {
            throw new Error('OpenSeadragon is missing.');
        }
    }

    var svgNS = 'http://www.w3.org/2000/svg';

    // ----------
    viewer.svgOverlay = function(imgWidth, imgHeight) {
        if (this._svgOverlayInfo) {
            return this._svgOverlayInfo;
        }

        this._svgOverlayInfo = new Overlay(viewer, imgWidth, imgHeight);

        return this._svgOverlayInfo;
    };

    // By Alex
    viewer.clearSvgOverlay = function() {
      var self = this;

      this.canvas.removeChild(this.canvas.childNodes[this.canvas.childNodes.length -1]);
      this._svgOverlayInfo = null;
    }

    // ---------- imgWidth: added by Alex.
    var Overlay = function(viewer, imgWidth, imgHeight) {
        var self = this;

        this.imgWidth = imgWidth;
        this.imgHeight = imgHeight;

        this._viewer = viewer;
        this._containerWidth = 0;
        this._containerHeight = 0;

        this._svg = document.createElementNS(svgNS, 'svg');
        this._svg.id = "osdOverlaySvg"
        this._svg.style.position = 'absolute';
        this._svg.style.left = 0;
        this._svg.style.top = 0;
        this._svg.style.width = '100%';
        this._svg.style.height = '100%';
        this._viewer.canvas.appendChild(this._svg);

        this._node = document.createElementNS(svgNS, 'g');
        this._svg.appendChild(this._node);

        this._viewer.addHandler('animation', function() {
            self.resize();
        });

        this._viewer.addHandler('open', function() {
            self.resize();
        });

        this._viewer.addHandler('rotate', function(evt) {
            self.resize();
        });

        this._viewer.addHandler('resize', function() {
            self.resize();
        });

        this.resize();
    };

    // ----------
    Overlay.prototype = {
        // ----------
        node: function() {
            return this._node;
        },

        // ----------
        resize: function() {
          if (this._viewer.container != null) { // Added by Alex.

            if (this._containerWidth !== this._viewer.container.clientWidth) {
                this._containerWidth = this._viewer.container.clientWidth;
                this._svg.setAttribute('width', this._containerWidth);
            }

            if (this._containerHeight !== this._viewer.container.clientHeight) {
                this._containerHeight = this._viewer.container.clientHeight;
                this._svg.setAttribute('height', this._containerHeight);
            }

            this._svg.setAttribute('width', this.imgWidth);
            this._svg.setAttribute('height', this.imgHeight);

            this.zoom = this._viewer.viewport.getZoom(true);
            this.scale = (this._viewer.viewport._containerInnerSize.x/this.imgWidth) * this.zoom;

            this.rotation = this._viewer.viewport.getRotation();
            
            if (this._viewer.world.getItemCount()) {
                this.p = this._viewer.world.getItemAt(0).lastDrawn[0].position;
                
                // TODO: Expose an accessor for _containerInnerSize in the OSD API so we don't have to use the private variable.
                // Modified by Alex: divide by imgWidth
                

            if (this._viewer.world.getItemAt(0).lastDrawn && this._viewer.world.getItemAt(0).lastDrawn.length >0) {
                this._svg.style.width = this._viewer.world.getItemAt(0).lastDrawn[0].size.x +'px';
                this._svg.style.height = this._viewer.world.getItemAt(0).lastDrawn[0].size.y + 'px';
            }

                this._svg.setAttribute('transform', 'translate(' + (this.p.x) + ',' + (this.p.y) + ') rotate(' + this.rotation + ')');

                this._node.setAttribute('transform', 'scale(' + this.scale + ')');
            }
          }
        },

        // ----------
        onClick: function(node, handler) {
            // TODO: Fast click for mobile browsers

            new $.MouseTracker({
                element: node,
                clickHandler: handler
            }).setTracking(true);
        },

    };
}

export default OpenSeaDragonOverlay;
