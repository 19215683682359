import Area from './Area.js';

  /**
   * The constructor of helpers points
   * Helper is small svg-rectangle with some actions
   *
   * @constructor
   * @param node {DOMElement} - a node for inserting helper
   * @param x {number} - x-coordinate of helper
   * @param y {number} - y-coordinate of helper
   * @param action {string} - an action by click of this helper (e.g. 'move')
   */
  function Helper(node, x, y, action, visibility) {
    this._el = document.createElementNS(Area.SVG_NS, 'rect');

    this._el.classList.add(Helper.CLASS_NAME);
    this._el.setAttribute('height', Helper.SIZE);
    this._el.setAttribute('width', Helper.SIZE);
    this._el.setAttribute('x', x + Helper.OFFSET);
    this._el.setAttribute('y', y + Helper.OFFSET);
    this._el.setAttribute('visibility',  (visibility !== undefined?visibility:'hidden'));

    node.appendChild(this._el);

    this._el.action = action; // TODO: move 'action' from dom el to data-attr
    this._el.classList.add(Helper.ACTIONS_TO_CURSORS[action]);
  }

  Helper.SIZE = 6;
  Helper.OFFSET = -Math.ceil(Helper.SIZE / 2);
  Helper.CLASS_NAME = 'helper';
  Helper.ACTIONS_TO_CURSORS = {
    'move': 'move',
    'editLeft': 'e-resize',
    'editRight': 'w-resize',
    'editTop': 'n-resize',
    'editBottom': 's-resize',
    'editTopLeft': 'nw-resize',
    'editTopRight': 'ne-resize',
    'editBottomLeft': 'sw-resize',
    'editBottomRight': 'se-resize',
    'movePoint': 'pointer'
  };

  Helper.prototype.show = function() {
    this._el.setAttribute('visibility', 'visible');
  }

  Helper.prototype.hide = function() {
    this._el.setAttribute('visibility', 'hidden');
  }

  /**
   * Set coordinates for this helper
   *
   * @param x {number} - x-coordinate
   * @param y {number} - y-coordinate
   * @returns {Helper}
   */
  Helper.prototype.setCoords = function(x, y) {
    this._el.setAttribute('x', x + Helper.OFFSET);
    this._el.setAttribute('y', y + Helper.OFFSET);

    return this;
  };

  /**
   * Set id of this helper in list of parent's helpers
   *
   * @param id {number}
   * @returns {Helper}
   */
  Helper.prototype.setId = function(id) {
    // TODO: move n-field from DOM-element to data-attribute
    this._el.n = id;

    return this;
  };

export default Helper;
