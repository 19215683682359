import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({

    MuiDropzoneAreaRoot: {
        width: '98%',
        marginTop: 10,
        height: 300
    },
    MuiDropzoneAreaText: {
        marginTop: '5%'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '98%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    uploadSpareBox: {
    },
    uploadSpareBoxContent: {
        backgroundColor: theme.palette.background.default,
        padding: 20,
    },
}));