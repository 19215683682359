import React, { useState, useEffect, useRef } from 'react';

import { DropzoneArea } from 'material-ui-dropzone';
import useStyles from './styles'

import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useSnackbar } from 'notistack';

import {
    useSpareDispatch,
    updateSpareContext
} from 'context/SpareContext';

import Config from 'config';
import { errorHandler, showNetworkErrorMsg } from 'utils/fetchutils';

export default function SpareUpload({ onOk, onCancel }) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const spareDispatcher = useSpareDispatch();

    const createSpare = (spare) => {

        spare.company_id = selectedCompany.id;
        spare.brand_id = selectedBrand.id;

        fetch(Config.SPARESAPI_URL, {
          method: "POST",
          credentials: "include",
          headers: {
            'Content-type': 'application/json'
          },
          body: JSON.stringify(spare)
        }).then(response => {
          if (response.ok) {
            response.json().then(data => {
                updateSpareContext(spareDispatcher, {
                currentSpare: data,
                isModified: {
                  refs: false,
                  areas: false
                }
              });
    
              enqueueSnackbar('Despiece guardado correctamente!', {
                variant: 'success',
                autoHideDuration: 2000,
              });

              onOk(data);
            })
          } else {
            errorHandler(response.status, 'No se ha podido guardar el despiece!', enqueueSnackbar);
          }
        }).catch((e) => {
          showNetworkErrorMsg(enqueueSnackbar);
        })
      }

    const generateCurrentSpare = () => {
        return {
            "owner": "alex@agilemindsoft.com",
            "language": "es",
            "description": "",
            "classifications": [],
            "areas": [],
            "refs": [],
            "company": {},
            "brand": {},
        };
    }

    const spare = useRef(generateCurrentSpare());

    const handleCapturePicture = (files) => {
        const fileReader = new FileReader();

        fileReader.readAsDataURL(files);

        fileReader.onload = (e) => {
            var image = new Image();
            image.src = e.target.result;

            image.onload = function () {
                // access image size here 
                spare.current.picture = {
                    meta: {
                        width: this.width,
                        height: this.height
                    },
                    data: e.target.result
                }
            }
        }
    };

    const [companies, setCompanies] = useState();
    const [selectedCompany, setSelectedCompany] = useState("");
    const [selectedBrand, setSelectedBrand] = useState("");
    const [spareName, setSpareName] = useState("");

    const handleCompanySelection = (event) => {
        setSelectedCompany(event.target.value);
        setSelectedBrand("")
    }

    const handleBrandSelection = (event) => {
        setSelectedBrand(event.target.value)
    }

    const handleOkClick = () => {
        if (selectedCompany === '') {
            enqueueSnackbar('Por favor, seleccione una empresa', {
                variant: 'error',
                autoHideDuration: 2000,
            });
            return;
        }

        if (selectedBrand === '') {
            enqueueSnackbar('Por favor, seleccione una marca', {
                variant: 'error',
                autoHideDuration: 2000,
            });
            return;
        }

        if (spareName === '') {
            enqueueSnackbar('Por favor, introduzca un nombre para el despiece', {
                variant: 'error',
                autoHideDuration: 2000,
            });
            return;
        }

        if (typeof spare.current.picture === 'undefined') {
            enqueueSnackbar('Por favor, añada una imagen para el despiece', {
                variant: 'error',
                autoHideDuration: 2000,
            });
            return;
        }

        spare.current.company.id = selectedCompany.id;
        spare.current.brand.id = selectedBrand.id;
        spare.current.classifications = [spareName];

        createSpare(spare.current);
    }

    const loadUserWritableCompanies = () => {
        const url = Config.BRANDSAPI_USERACLS_URL + "?idf=name&role=wr";

        fetch(url, {
            credentials: "include"
        }).then((response) => {
            if (response.ok) {
                response.json().then(data => setCompanies(data));
            } else {
                enqueueSnackbar('No se ha podido obtener el listado de empresas', {
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            }
        }).catch((e) => {
            enqueueSnackbar('Se ha producido un error. Por favor, vuelva a intentarlo más tarde', {
                variant: 'error',
                autoHideDuration: 2000,
            });
        });
    }

    useEffect(() => {
        loadUserWritableCompanies();

        return () => {

        }
    }, [])

    return (
        <Dialog open={true} aria-labelledby="form-dialog-title"
            className={classes.uploadSpareBox}
            fullWidth={true}
            maxWidth="sm"
            onClose={onCancel}>
            <DialogTitle id="form-dialog-title">Nuevo despiece</DialogTitle>
            <DialogContent>
                <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-helper-label">Empresa</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={selectedCompany}
                        onChange={handleCompanySelection}>

                        {companies && Object.keys(companies).map((companyName) => (
                            <MenuItem key={companies[companyName].id} value={companies[companyName]}>{companyName}</MenuItem>
                        )
                        )}
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-helper-label">Marca</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={selectedBrand}
                        onChange={handleBrandSelection}>

                        {selectedCompany && Object.keys(selectedCompany.brands).map((brandName) => (
                            <MenuItem key={selectedCompany.brands[brandName].id}
                                value={selectedCompany.brands[brandName]}>{brandName}</MenuItem>
                        ))
                        }

                    </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <TextField id="standard-basic" label="Nombre del despiece" 
                        value={spareName}
                        onChange={(event)=>{
                            setSpareName(event.target.value);
                        }}/>
                </FormControl>
                <DropzoneArea
                    onDrop={handleCapturePicture}
                    dropzoneClass={classes.MuiDropzoneAreaRoot}
                    dropzoneParagraphClass={classes.MuiDropzoneAreaText}
                    filesLimit={1}
                    showPreviews={false}
                    showPreviewsInDropzone={true}
                    acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                />

            </DialogContent>
            <DialogActions>
                <Button variant="contained"
                    onClick={onCancel}>Cancelar</Button>
                <Button variant="contained" color="primary"
                    onClick={handleOkClick}>Aceptar</Button>
            </DialogActions>
        </Dialog>
    )
}