import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";

const useAmsApi = (url = null, extra = {}, msgs = {}, callbacks = {}) => {

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [fetchedData, setFetchedData] = useState();
    const { enqueueSnackbar } = useSnackbar();

    const errorHandler = (status, msg) => {

        switch (status) {
            case 401:
                enqueueSnackbar('Operación no autorizada!', {
                    variant: 'error',
                    anchorOrigin: { horizontal: 'left', vertical: 'top' },
                    autoHideDuration: 2000,
                });
                break;
            case 403:
                window.location.replace('/#/app/noauth');
                break;
            default:
                enqueueSnackbar(msg, {
                    variant: 'error',
                    anchorOrigin: { horizontal: 'left', vertical: 'top' },
                    autoHideDuration: 2000,
                });
                ;
        }
    }

    useEffect(() => {
        let unmounted = false;

        const handleFetchResponse = response => {
            if (response.ok) {
                let msg = (msgs.ok ? msgs.ok : "Operación realizada con éxito!");
                enqueueSnackbar(msg, {
                    variant: 'success',
                    anchorOrigin: { horizontal: 'left', vertical: 'top' },
                    autoHideDuration: 2000,
                });

                if (callbacks.onOk) {
                    if (response.json) {
                        response.json().then((data) => {
                            callbacks.onOk(data);
                        });
                    } else {
                        callbacks.onOk();
                    }
                }

            } else {
                setHasError(true);
                errorHandler(response.status, msgs.error ? msgs.error : "No se ha podido completar la operación");
            }
            setIsLoading(false);
            return response.ok && response.json ? response.json() : null;
        };

        const fetchData = () => {
            setIsLoading(true);

            return fetch(url, {
                credentials: 'include',
                ...extra
            })
                .then(handleFetchResponse)
                .catch(handleFetchResponse);
        };

        if (url && !unmounted)
            fetchData().then(data => !unmounted && setFetchedData(data));

        return () => {
            unmounted = true;
        };
    }, [url, extra, msgs, callbacks]);

    return { isLoading, hasError, data: fetchedData };
};

const apiFetch = (url = null, extra = {}, msgs = {}, callbacks = {}, enqueueSnackbar) => {

    const handleFetchResponse = response => {
        if (response.ok) {
            if (msgs.ok != null) {
                let msg = (msgs.ok.length ? msgs.ok : "Operación realizada con éxito!");
                enqueueSnackbar(msg, {
                    variant: 'success',
                    anchorOrigin: { horizontal: 'left', vertical: 'top' },
                    autoHideDuration: 2000,
                });
            }
            return response.json().then((data) => {
                if (callbacks.onOk) {
                    callbacks.onOk(data);
                }
                return data;
            });

        } else {
            errorHandler(response.status,
                msgs.error ? msgs.error : "No se ha podido completar la operación",
                enqueueSnackbar
            );
        }
        return response.ok && response.json ? response.json() : null;
    };

    const handleNetworkError = (e) => {
        showNetworkErrorMsg(enqueueSnackbar);
    }

    const fetchData = () => {

        return fetch(url, {
            credentials: 'include',
            ...extra
        })
            .then(handleFetchResponse)
            .catch(handleNetworkError);
    };

    if (url) return fetchData();
};

const errorHandler = (status, msg, enqueueSnackbar) => {

    if (status) {
        switch (status) {
            case 401:
                enqueueSnackbar('Operación no autorizada!', {
                    variant: 'error',
                    anchorOrigin: { horizontal: 'left', vertical: 'top' },
                    autoHideDuration: 2000,
                });
                break;
            case 403:
                window.location.replace('/#/app/noauth');
                break;
            default:
                if (msg) {
                    enqueueSnackbar(msg, {
                        variant: 'error',
                        anchorOrigin: { horizontal: 'left', vertical: 'top' },
                        autoHideDuration: 2000,
                    });
                }
                ;
        }
    } else {
        enqueueSnackbar(msg ? msg : "Se ha producido un error", {
            variant: 'error',
            anchorOrigin: { horizontal: 'left', vertical: 'top' },
            autoHideDuration: 2000,
        });
    }
}

const showNetworkErrorMsg = (enqueueSnackbar) => {
    enqueueSnackbar("Se ha producido un error. Por favor, vuelva a intentarlo más tarde", {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
        autoHideDuration: 2000,
    });
}

export { useAmsApi, errorHandler, showNetworkErrorMsg, apiFetch };