import React, { useState } from "react";
import {
    Box,
    ClickAwayListener,
    IconButton,
    Paper,
    Popper,
} from "@material-ui/core";

import {
    AccountTree as AccountTreeIcon,
} from "@material-ui/icons";

import useStyles from "./styles";
import {
    useSpareDispatch,
    setCurrentSpare,
    toggleLoadingSpare,
} from '../../../context/SpareContext';

import SpareTree from "components/spclick/SpareTree/storybookjs/SpareTree"

const SpareMenu = ({}) => {
    const classes = useStyles();
    const sparesDispatcher = useSpareDispatch();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open,setOpen] = useState(false);
    const id = open ? 'simple-popper' : undefined;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(!open);
    };

    const onSelectSpare = () => {
        setOpen(false);
    }

    const handleClickAway = () => {
        setOpen(false);
      };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Box className={classes.wrapper}>
            <IconButton
                color="primary"
                classes={{ root: classes.spareMenuIcon }}
                aria-owns="widget-menu"
                aria-haspopup="true"
                onClick={handleClick}>
            <AccountTreeIcon/>
            </IconButton>
            <Popper id={id} open={open} anchorEl={anchorEl} className={classes.popper}>
                <Paper className={classes.paper} elevation={3}>
                    <SpareTree onSelectSpare={onSelectSpare} />
                </Paper>
            </Popper>       
        </Box>
        </ClickAwayListener>
    );
};

SpareMenu.propTypes = {

};

export default SpareMenu;
