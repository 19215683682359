import React, { useState, useEffect, useRef } from "react";

import {
  Box, Popover, Typography,
} from '@material-ui/core';
import {
  PowerOff as PowerOffIcon,
} from "@material-ui/icons";

import OpenSeaDragon from "openseadragon";
import OpenSeaDragonOverlay from "plugins/openseadragon-svg-overlay.js"

import useStyles from "./styles";

import './main.css';
import utils from './utils.js';
import { useSnackbar } from 'notistack';
import SpeedDial from 'components/SpeedDial';

import Area from './classes/Area.js';
import Rectangle from './classes/Rectangle.js';
import Circle from './classes/Circle.js';
import Polygon from './classes/Polygon.js';

import ViewerToolbar from "./ViewerToolbar";

import ShapesButtonBar from './subcomp/ShapesButtonBar';

import {
  useSpareDispatch,
  useCurrentSpare,
  setAreasModified,
  updateSpareContext,
  useIsEditingSpare,
} from '../../../context/SpareContext';
import * as viewerUtils from './viewer.js';

import Render from 'components/spclick/Render';
import { checkAuth } from 'components/spclick/Can';
import { useRoles } from "context/UserContext";
import { Link } from "react-router-dom";
import classNames from "classnames";

const KEYS = {
  F1: 112,
  ESC: 27,
  TOP: 38,
  BOTTOM: 40,
  LEFT: 37,
  RIGHT: 39,
  DELETE: 46,
  I: 73,
  S: 83,
  C: 67
};

/**
 * The constructor for dom events (for simple deleting of event)
 *
 * @constructor
 * @param {DOMElement} target - DOM-element
 * @param {String} eventType - e.g. 'click' or 'mousemove'
 * @param {Function} func - handler for this event
 */
function AppEvent(target, eventType, func) {
  this.target = target;
  this.eventType = eventType;
  this.func = func;

  target.addEventListener(eventType, func, false);
}

/**
 * Remove this event listener from target
 */
AppEvent.prototype.remove = function () {
  this.target.removeEventListener(this.eventType, this.func, false);
};

////////////////////////////////////////////////////////////////////////////////
export default function SpareEditor(props) {
  const dialActions = props.dialActions;
  const areasRef = props.areasRef;
  const refsRef = props.refsRef;

  const spareDispatch = useSpareDispatch();
  const roles = useRoles();
  const isEditingSpare = useIsEditingSpare();
  const [pictureOverlayReady, setPictureOverlayReady] = useState(false);

  const divImageRef = useRef();
  const classes = useStyles();
  let viewer = useRef();
  let cursor_position_info;
  var overlay;
  let info;
  let zz;
  let tmpEvents = [];
  let isDragging = false;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const rightPaneWidth = useRef();

  const [contextMenuAnchor, setContextMenuAnchor] = useState(null);

  const addArea = area => {
    areasRef.current = areasRef.current.concat([area]);
    setAreasModified(spareDispatch, true);
  }

  const [mode, _setMode] = useState();
  const modeRef = useRef(mode);

  const setMode = mode => {
    modeRef.current = mode;
    _setMode(mode);
  }

  const newAreaRef = useRef(null);

  const [selectedArea, _setSelectedArea] = useState(null);
  const selectedAreaRef = useRef(selectedArea);

  const setSelectedArea = area => {
    selectedAreaRef.current = area;
    _setSelectedArea(selectedArea);
  }

  const appState = {
    offset: {
      x: 0,
      y: 0
    },
    appMode: null, // drawing || editing || browsing
    editType: null,
    newArea: null,
    selectedArea: null,
    events: [],
    isDraw: false,
    image: {
      src: null,
      filename: null,
      width: 0,
      height: 0
    }
  };

  const [state, _setState] = useState({
    activeShape: 'rectangle'
  });

  const stateRef = useRef(state);

  const setState = (state) => {
    stateRef.current = state;
    _setState(state);
  }

  const domElementsRef = useRef({});
  const zoomHandler = useRef();

  const spare = useCurrentSpare();

  const createOverlay = (viewer, spare) => {
    viewer.world.getItemAt(0).addHandler('fully-loaded-change',
      (e) => {
        new OpenSeaDragonOverlay().doOverlay(viewer, spare.picture.width, spare.picture.height);
        overlay = viewerUtils.createOverlay(viewer, spare);

        let osdOverlaySvg = utils.id('osdOverlaySvg');
        if (osdOverlaySvg) {
          domElementsRef.current.container = osdOverlaySvg; //document.querySelector('.openseadragon-canvas');
          domElementsRef.current.svg = osdOverlaySvg.firstChild;
          setPictureOverlayReady(true);
        }

        prepareEditor(viewer);

        if (isEditingSpare) {
          if (checkAuth(roles[0], "spares:edit", null) && modeRef.current !== 'browsing') {
            activateShapeDrawing(utils.id('rectangle'));
          }
        }

        let elements = viewerUtils.generateOverlayAreas(spare, domElementsRef.current, appState, {
          onClickListener: onAreaClickListener,
          onDrop: onAreaDropListener,
          onDragOver: onAreaDragOverListener,
        });

        elements.forEach((area) => {
          addNodeToSvg(area);
          if (area instanceof Polygon) {
            setupHelperListeners(area);
          }
        });

        areasRef.current = elements;
      });
  }

  const addFG = (area, modified) => {
    addNodeToSvg(area);
    addArea(area);

    if (modified) {
      setAreasModified(spareDispatch, modified)
    }
  }

  const spareModificationPostActions = () => {
    //setChanged(true);
  }

  const mountViewer = () => {
    domElementsRef.current = {
      wrapper: utils.id('wrapper'),
      img: utils.id('img'),
      map: null
    };
    if (spare) {
      loadImage(spare.picture.url);
    }
  };

  const launchViewer = () => {
    if (viewer.current == null) {
      // Here, spare = null only on startup
      if (spare) {
        viewer.current = OpenSeaDragon({
          id: "spareviewer",
          prefixUrl: "/img/openseadragon/",
          tileSources: {
            type: 'image',
            url: spare.picture.url,
            width: spare.picture.width,
            height: spare.picture.height,
          },
          debugMode: true,
          animationTime: 0.4,
          visibilityRatio: 1.0,
          constrainDuringPan: true,
          defaultZoomLevel: 0.75,
          minZoomLevel: 0.3,
          maxZoomLevel: 5,
          useCanvas: true,
          degrees: 0,
          showRotationControl: true,
          controlsFadeDelay: 60000,
          zoomInButton: "osd-zoom-in-96",
          zoomOutButton: "osd-zoom-out-96",
          rotateLeftButton: "osd-rleft-96",
          rotateRightButton: "osd-rright-96",
          homeButton: "osd-home-96",
          fullPageButton: "osd-fulls-96",
          navigationControlAnchor: 2,
          zoomPerClick: 1,
          zoomPerSecond: 3
        });

        viewer.current.addHandler('open', function () {
          createOverlay(viewer.current, spare);
        });
        setupGlobalListeners();
      }
    } else {
      removeGlobalListeners();

      areasRef.current = [];
      clear();
      viewer.current.clearSvgOverlay();
      viewer.current.world.removeAll();
      if (spare && spare.picture) {
        viewer.current.addTiledImage({
          tileSource: {
            type: 'image',
            url: spare.picture.url,
            width: spare.picture.width,
            height: spare.picture.height,
          },
          success: function () {
            // Clear old svg overlays and generate new ones
            //viewer.current.clearSvgOverlay();
            //viewer.current.world.removeItem(viewer.current.world.getItemAt(0));
            createOverlay(viewer.current, spare);
          }
        });
      }
    }
  }

  const setupGlobalListeners = () => {
    /* Get offset value */
    window.addEventListener('resize', recalcOffsetValues, false);
    //recalcOffsetValues();
    document.addEventListener('keydown', onDocumentKeyDown, false);
  }

  const removeGlobalListeners = () => {
    /* Get offset value */
    window.removeEventListener('resize', recalcOffsetValues, false);
    //recalcOffsetValues();
    document.removeEventListener('keydown', onDocumentKeyDown, false);
  }

  const prepareEditor = (viewerVar) => {

    new OpenSeaDragon.MouseTracker({
      element: domElementsRef.current.container,
      startDisabled: true
    });

    if (domElementsRef.current.container) {
      domElementsRef.current.container.addEventListener('click', onSvgClick, false);
    }

    viewerVar.addHandler('canvas-nonprimary-press', function (event) {
      if (event.button === 2) {
        event.originalEvent.preventDefault();
        event.preventDefaultAction = true;
        onRightClickListener(event.originalEvent);
        return false;
      }
    });

    // Disable contextmenu on body to catch it up on 'domElementsRef.current.container'
    // Without this, browser right menu keeps opening
    document.getElementsByTagName('body')[0].addEventListener('contextmenu', (e) => {
      e.preventDefault();
      return false;
    }, false);

    viewerVar.addHandler('canvas-drag', function () {
      isDragging = true;
    });

    viewerVar.addHandler('full-screen', function (a) {
      let panes = utils.findByClassName('layout-pane');
      if (!a.fullScreen) {
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        panes[1].style.width = (vw / 2) + 'px';
        viewerVar.viewport.zoomTo(1);
      }
    });

    //    domElementsRef.current.container.addEventListener('dblclick', onAreaDblClick, false);


    /* Disable image dragging */
    domElementsRef.current.img.addEventListener('dragstart', function (e) {
      e.preventDefault();
      e.stopPropagation()
    }, false);

    // Eventos para mostrar las coordenadas en pantalla
    domElementsRef.current.container.addEventListener('mousemove', (e) => {
      cursor_position_info.set(utils.getRightCoords(appState, e.pageX, e.pageY));
    }, false);

    domElementsRef.current.container.addEventListener('mouseleave', function () {
      cursor_position_info.empty();
    }, false);

    // Eventos para los botones de selección de figura geométrica
    if (checkAuth(roles[0], "spares:edit", null)) {
      addShapeButtonsListeners();
    }
  }

  useEffect(() => {
    mountViewer();

    cursor_position_info = (function () {
      var coords_info = utils.id('coords');

      return {
        set: function (coords) {
          coords_info.innerHTML = `x: ${coords.x}, y: ${coords.y}`;
        },
        empty: function () {
          coords_info.innerHTML = '';
        }
      };
    })();

    info = (function () {
      var form = utils.id('edit_details'),
        header = null, //form.querySelector('h5'),
        href_attr = utils.id('href_attr'),
        alt_attr = utils.id('alt_attr'),
        title_attr = utils.id('title_attr'),
        save_button = utils.id('save_details'),
        close_button = null, //form.querySelector('.close_button'),
        sections = null, //form.querySelectorAll('p'),
        obj,
        x,
        y,
        temp_x,
        temp_y;

      function save(e) {
        obj.setInfoAttributes({
          href: href_attr.value,
          alt: alt_attr.value,
          title: title_attr.value,
        });

        obj[obj.href ? 'setStyleOfElementWithHref' : 'unsetStyleOfElementWithHref']();

        //changedReset();
        // unload();

        e.preventDefault();
      }

      function setCoords(x, y) {
        form.style.left = (x + 5) + 'px';
        form.style.top = (y + 5) + 'px';
      }

      function moveEditBlock(e) {
        setCoords(x + e.pageX - temp_x, y + e.pageY - temp_y);
      }

      function stopMoveEditBlock(e) {
        x = x + e.pageX - temp_x;
        y = y + e.pageY - temp_y;
        setCoords(x, y);

        this.removeAllEvents();
      }

      function change() {
        form.classList.add('changed');
        this.parentNode.classList.add('changed');
      }

      //        save_button.addEventListener('click', save, false);

      /*      href_attr.addEventListener('keydown', function(e) {
              e.stopPropagation();
            }, false);
            alt_attr.addEventListener('keydown', function(e) {
              e.stopPropagation();
            }, false);
            title_attr.addEventListener('keydown', function(e) {
              e.stopPropagation();
            }, false);
    */
      /*   href_attr.addEventListener('change', change, false);
         alt_attr.addEventListener('change', change, false);
         title_attr.addEventListener('change', change, false);
 
         close_button.addEventListener('click', unload, false);*/

      /*   header.addEventListener('mousedown', function(e) {
           //                  temp_x = e.pageX,
           //                  temp_y = e.pageY;
 
           this.addEvent(document, 'mousemove', moveEditBlock);
           this.addEvent(header, 'mouseup', stopMoveEditBlock);
 
           e.preventDefault();
         }, false);*/

      return {
        load: function (object, new_x, new_y) {
          obj = object;
          href_attr.value = object.href ? object.href : '';
          alt_attr.value = object.alt ? object.alt : '';
          title_attr.value = object.title ? object.title : '';
          utils.show(form);
          if (new_x && new_y) {
            x = new_x;
            y = new_y;
            setCoords(x, y);
          }
        },
        //   unload: unload
      };
    })();

  }, [spare]);  // End useEffect

  useEffect(() => {

    deselectCurrentArea();
    setMode(isEditingSpare ? "editing" : "browsing");
    if (pictureOverlayReady) {

      if (isEditingSpare) {
        if (checkAuth(roles[0], "spares:edit", null) && modeRef.current !== 'browsing') {

          addShapeButtonsListeners();
          activateShapeDrawing(utils.id('rectangle'));
        }
      }
    }
  }, [isEditingSpare, pictureOverlayReady]);

  useEffect(() => {
  }, []);

  const addShapeButtonsListeners = () => {
    const rectangleButton = utils.id('rectangle');

    if (rectangleButton) { // currenty visible?
      rectangleButton.addEventListener('click', onRectangleButtonClick, false);
      utils.id('circle').addEventListener('click', onCircleButtonClick, false);
      utils.id('polygon').addEventListener('click', onPolygonButtonClick, false);
    }
  }

  const doRefsTableScroll = (ref) => {

    var container = document.getElementsByClassName('layout-pane')[1];
    if (container) {
      var toolbar = container.getElementsByClassName('MuiToolbar-root')[0];
      var tableHeader = container.getElementsByClassName('MuiTableHead-root')[0];

      var rows = container.getElementsByClassName('MuiTableRow-root');

      if (rows) {

        let rowsH = 0;

        let idx = 0;
        let clientHeight = 0;
        while (idx < rows.length && rows[idx].id !== ref) {
          clientHeight = rows[idx].clientHeight;
          rowsH = rowsH + clientHeight
          idx++;
        }

        // Apply a max of 3 rows offset to better display selection
        if (idx < rows.length) {
          let offsetRows = 1;
          while (idx+offsetRows < rows.length && offsetRows <= 3) {
            clientHeight = rows[offsetRows].clientHeight;
            rowsH = rowsH + clientHeight
            offsetRows++;
          }
        }

        rowsH = rowsH + clientHeight;

        let selectedRow = rows[idx];

        if (selectedRow) {

          let scroll = toolbar.clientHeight + tableHeader.clientHeight + rowsH;

          container = container.children[0];
          scroll = (scroll > container.clientHeight ? scroll - container.clientHeight : 0);

          container.scrollTo(0, scroll);
          Array.from(rows).forEach(row => {
            row.classList.remove(classes.selectedRefTableRow);
          });

          selectedRow.classList.add(classes.selectedRefTableRow);
        }
      }
    }
  }

  const recalcOffsetValues = () => {
    appState.offset = utils.getOffset(domElementsRef.current.container);
  }

  const onSvgMousedown = (clickedArea, e) => {
    let event = e;
    recalcOffsetValues();

    if (modeRef.current === 'editing') {
      if (event.target.parentNode.tagName === 'g') {
        //info.unload();
        if (clickedArea && clickedArea !== selectedAreaRef.current) {

          deselectCurrentArea();
          deselectAll();

          setSelectedArea(clickedArea);
          selectedAreaRef.current.select();
          if (modeRef.current !== 'browsing') {
            selectedAreaRef.current.showHelpers();
          }
        }

        if (event.target.tagName === 'rect' || event.target.tagName === 'circle' || event.target.tagName === 'polygon') {

          selectedAreaRef.current.info.editType = 'move';

          // AREA DRAG HANDLER
          let dragHandler = new OpenSeaDragon.MouseTracker({
            element: selectedAreaRef.current._groupEl,
            dragHandler: (e) => {
              let event = e.originalEvent;
              if (!selectedAreaRef.current.dragging) {
                selectedAreaRef.current.editingStartPoint = {
                  x: event.pageX,
                  y: event.pageY
                };
                selectedAreaRef.current.dragging = true;
                spareModificationPostActions();
              }

              selectedAreaRef.current.onProcessEditing(selectedAreaRef.current, overlay.scale, event);
            }
          }).setTracking(true);

          // AREA DRAG END HANDLER
          let dragEndHandler = new OpenSeaDragon.MouseTracker({
            element: selectedAreaRef.current._groupEl,
            dragEndHandler: (e) => {
              let event = e.originalEvent;
              selectedAreaRef.current.dragging = false;

              if (selectedAreaRef.current instanceof Polygon) {
                selectedAreaRef.current.onStopEditing(overlay.scale, event);
              } else {
                selectedAreaRef.current.onStopEditing(selectedAreaRef.current, overlay.scale, event);
              }
              setAreasModified(spareDispatch, true);

              removeTmpEvents();
            }
          }).setTracking(true);

          tmpEvents.push(dragHandler);
          tmpEvents.push(dragEndHandler);
        }
      } else {
        deselectAll();
        // info.unload();
      }
    }
  }

  const removeTmpEvents = () => {
    if (tmpEvents) {
      tmpEvents.forEach((e) => {
        e.setTracking(false);
      });
    }
  }

  /* Add click event for svg */
  const onSvgClick = (e) => {
    if (modeRef.current !== 'browsing') {

      let clickedArea = e.target.obj;

      onSvgMousedown(clickedArea, e);

      if (zz === 0 && modeRef.current === 'editing'
        && (!(clickedArea) || (clickedArea !== selectedAreaRef.current))) {

        deselectCurrentArea();
        setDrawingMode(stateRef.current.activeShape);

      } else if (!isDragging) {
        if (modeRef.current === 'drawing' && !appState.isDraw && getShape()) {
          setIsDraw(true);
          deselectCurrentArea();
          createFG(appState, e);

        } else {

          if (newAreaRef.current instanceof Polygon) {
            newAreaRef.current.onAddPointDrawing(e, appState, overlay);
          }

        }
      } else {
        isDragging = false;
      }
      zz = 0;
      e.preventDefault();
    }
  }

  const onAreaSelect = (clickedArea) => {
    if (!getIsDraw()) {
      if (modeRef.current !== 'browsing') {
        setEditingMode();
      }
      setCurrentSelectedGF(clickedArea);

      if (clickedArea && clickedArea.ref_link && clickedArea.ref_link.ref) {
        doRefsTableScroll(clickedArea.ref_link.ref);
      }

      zz = 1;
    }
  }

  const onAreaClickListener = (event) => {
    let clickedArea = event.target.parentNode.obj;

    if (clickedArea) {
      onAreaSelect(clickedArea);
    }

    return false;
  };

  const deselectCurrentArea = () => {
    if (selectedAreaRef.current) {
      selectedAreaRef.current.hideHelpers();
      selectedAreaRef.current.deselect();
      removeTmpEvents();
      removeAllEvents();
    }

  }

  const setCurrentSelectedGF = (fg) => {
    deselectCurrentArea();

    selectedAreaRef.current = fg;
    deselectAll();
    selectedAreaRef.current.select();
    if (modeRef.current !== 'browsing') {
      selectedAreaRef.current.showHelpers();
    }
  }

  // Dropping connector from sparetable 1st column. (ref)
  // Connecting ref with area
  const onAreaDropListener = (ev) => {
    if (!selectedAreaRef.current || !selectedAreaRef.current.dragging) {
      ev.preventDefault();

      let selectedArea = ev.target.parentNode.obj;

      const data = ev.dataTransfer.getData("text/plain");

      areasRef.current = areasRef.current.map((area) => {
        if (area !== selectedArea) {
          return area;
        } else {
          if (!area.ref_link) {
            area.ref_link = {};
          }
          //area.isLinked = true;
          area.ref_link.ref = data;
          return area;
        }
      });

      let ref = refsRef.current.filter((r) => (r.ref === data));
      ref[0].area_links++;

      setAreasModified(spareDispatch, true);
      /*updateSpareContext(spareDispatch, {
       // spare: spare,
        isModified: {
          areas: true
        }
      });*/

      setCurrentSelectedGF(selectedArea);
      enqueueSnackbar('Area conectada!', {
        variant: 'info',
        anchorOrigin: { horizontal: 'left', vertical: 'top' },
        autoHideDuration: 2000,
      });
    }
  }

  const onAreaDragOverListener = (ev) => {
    ev.preventDefault();
    ev.dataTransfer.dropEffect = "copy";
  }

  const onRightClickListener = (ev) => {
    //ev.preventDefault();
    //ev.stopPropagation();
    console.log("RIGHT CLICK !!!!!!!!!!!!!!!!!!!!!!!");

    if (ev.target.nodeName === 'rect'
      || ev.target.nodeName === 'circle'
      || ev.target.nodeName === 'polygon') {

      let clickedArea = ev.target.parentNode.obj;
      onAreaSelect(clickedArea);
      setContextMenuAnchor(ev.target);
    }
  }

  // Crea una nueva área y la añade al estado
  const createFG = (state, e) => {

    switch (getShape()) {
      case 'circle':
        newAreaRef.current = Circle.createAndStartDrawing(
          {
            x: (e.pageX - state.offset.x) / overlay.scale,
            y: (e.pageY - state.offset.y) / overlay.scale
          },
          {
            offset: state.offset,
            editType: state.editType
          }, {
          onClickListener: onAreaClickListener,
          onDrop: onAreaDropListener,
          onDragOver: onAreaDragOverListener,
        }
        );
        break;
      case 'polygon':
        newAreaRef.current = Polygon.createAndStartDrawing(
          {
            x: (e.pageX - state.offset.x) / overlay.scale,
            y: (e.pageY - state.offset.y) / overlay.scale
          },
          {
            offset: state.offset,
            editType: state.editType
          }, {
          onClickListener: onAreaClickListener,
          onDrop: onAreaDropListener,
          onDragOver: onAreaDragOverListener,
        });
        appState.polygonInConstruction = true;

        new OpenSeaDragon.MouseTracker({
          element: newAreaRef.current._helpers.points[0]._el,
          clickHandler: (e) => {
            if (appState.polygonInConstruction) {
              onStopDrawing(appState, e.originalEvent, true);
              appState.polygonInConstruction = false;
              /*e.preventDefault = true;
              e.originalEvent.stopPropagation();*/
            }
          }
        }).setTracking(true);

        break;
      case 'rectangle':
      default:

        //console.log("NEW AREA POSICION: " + (e.pageX-state.offset.x)/overlay.scale + "," + (e.pageY-state.offset.y)/overlay.scale);
        newAreaRef.current = Rectangle.createAndStartDrawing(
          {
            x: (e.pageX - state.offset.x) / overlay.scale,
            y: (e.pageY - state.offset.y) / overlay.scale
          },
          {
            id: domElementsRef.current.svg.childElementCount
          },
          {
            offset: state.offset,
            editType: state.editType
          }, {
          onClickListener: onAreaClickListener,
          onDrop: onAreaDropListener,
          onDragOver: onAreaDragOverListener,
        });
        break;
    }

    disableZoom();

    // FALTA: es necesario crear estos 2 eventos cada vez que se crea una FG?
    addEvent(domElementsRef.current.container, 'mousemove', (e) => {
      onProcessDrawing(appState, e)
    });

    addEvent(domElementsRef.current.container, 'click', (e) => {
      onStopDrawing(appState, e);
    });

    addEvent(domElementsRef.current.container, 'focus', (e) => {
      alert("FOCUS AREA");
    });

    /*addEvent(domElementsRef.current.container, 'contextmenu', (e) => {
      onRightClickListener(e);
    });*/

    addFG(newAreaRef.current);
  }

  const addNodeToSvg = (node) => {

    new OpenSeaDragon.MouseTracker({
      element: node._groupEl,
      startDisabled: true
    });

    if (node instanceof Rectangle || node instanceof Circle) {
      setupHelperListeners(node);
    }

    domElementsRef.current.svg.appendChild(node._groupEl);
  }

  const onProcessDrawing = (appState, e) => {
    var coords = { x: (e.pageX - appState.offset.x) / overlay.scale, y: (e.pageY - appState.offset.y) / overlay.scale };
    newAreaRef.current.dynamicDraw(coords.x, coords.y, e.shiftKey);
  };

  const onStopDrawing = (appState, e, closePolygon = false) => {

    if (newAreaRef.current instanceof Polygon) {
      if (Polygon.testCoords(newAreaRef.current._coords) || closePolygon) {
        newAreaRef.current.close();
        setupHelperListeners(newAreaRef.current);
        spareModificationPostActions();
      } else {
        return false;
      }
    } else {
      var coords = { x: (e.pageX - appState.offset.x) / overlay.scale, y: (e.pageY - appState.offset.y) / overlay.scale };
      newAreaRef.current.setCoords(newAreaRef.current.dynamicDraw(coords.x, coords.y, e.shiftKey)).deselect();
      spareModificationPostActions();
    }

    let newAreaJson = newAreaRef.current.toJSON();
    spare.areas.push(newAreaJson);
    // areasRef.current.push(newAreaRef.current);

    //      setCurrentSpare(spareDispatch, spare, true);
    updateSpareContext(spareDispatch, {
      spare: spare,
      isModified: {
        areas: true
      }
    });
    removeAllEvents();
    setIsDraw(false);
    resetNewArea();

    enableZoom();
  };

  const setupHelperListeners = (node) => {
    // HELPERS LISTENERS
    let helpersList = (node instanceof Polygon ? node._helpers.points : node._helpers);

    for (var prop in helpersList) {
      if (Object.prototype.hasOwnProperty.call(helpersList, prop)) {

        let helper = helpersList[prop];

        // HELPER PRESS HANDLER
        new OpenSeaDragon.MouseTracker({
          element: helper._el,
          pressHandler: (e) => {
            var targetHelper = e.originalEvent.target;
            let area = targetHelper.parentNode.obj;

            area.info.editType = targetHelper.action;

            if (targetHelper.n >= 0) { // if typeof selected_area == polygon
              area.selected_point = targetHelper.n;
            }

            area.editingStartPoint = {
              x: e.originalEvent.pageX,
              y: e.originalEvent.pageY
            };

            e.preventDefault = true;
            e.originalEvent.stopPropagation();
          }
        }).setTracking(true);

        // HELPER MOUSE MOVE
        let mouseMoveEvent = new OpenSeaDragon.MouseTracker({
          element: helper._el,
          dragHandler: (e) => {
            selectedAreaRef.current.onProcessEditing(selectedAreaRef.current, overlay.scale, e.originalEvent)
          }
        }).setTracking(true);

        // HELPER MOUSE RELEASE
        let mouseReleaseEvent = new OpenSeaDragon.MouseTracker({
          element: helper._el,
          dragEndHandler: (e) => {
            let area = e.originalEvent.target.parentNode.obj;
            if (area instanceof Polygon) {
              area.onStopEditing(overlay.scale, e.originalEvent);
            } else {
              selectedAreaRef.current.setCoords(selectedAreaRef.current.onProcessEditing(selectedAreaRef.current, overlay.scale, e.originalEvent));
              selectedAreaRef.current.info.editType = 'move';
            }
            setAreasModified(spareDispatch, true);
          }
        }).setTracking(true);
      }
    }
  }

  const removeNodeFromSvg = (node) => {
    domElementsRef.current.svg.removeChild(node);
  }

  /* Add dblclick event for svg */
  const onAreaDblClick = (e) => {
    if (modeRef.current === 'editing') {
      if (e.target.tagName === 'rect' || e.target.tagName === 'circle' || e.target.tagName === 'polygon') {
        selectedAreaRef.current = e.target.parentNode.obj;
        info.load(selectedAreaRef.current, e.pageX, e.pageY);
      }
    }
  }

  /* Add keydown event for document */
  const onDocumentKeyDown = (e) => {
    let event = e;
    var ctrlDown = event.ctrlKey || event.metaKey; // PC || Mac

    switch (event.keyCode) {

      case KEYS.ESC:
        if (appState.isDraw) {
          appState.isDraw = false;
          //          newAreaRef.current.remove();
          removeNodeFromSvg(newAreaRef.current._groupEl);
          //_setAreas(areasRef.current.slice(0, areasRef.current.length-1));
          areasRef.current = areasRef.current.slice(0, areasRef.current.length - 1);
          removeAllEvents();
          enableZoom();
        } else if (modeRef.current === 'editing') {
          selectedAreaRef.current.redraw();
          removeAllEvents();
        }

        break;

      case KEYS.TOP:
        if (modeRef.current === 'editing' && selectedAreaRef.current) {
          selectedAreaRef.current.move(0, -1);
          setAreasModified(spareDispatch, true);
          event.preventDefault();
        }

        break;

      case KEYS.BOTTOM:
        if (modeRef.current === 'editing' && selectedAreaRef.current) {
          selectedAreaRef.current.move(0, 1);
          setAreasModified(spareDispatch, true);
          event.preventDefault();
        }
        break;

      case KEYS.LEFT:
        if (modeRef.current === 'editing' && selectedAreaRef.current) {
          selectedAreaRef.current.move(-1, 0);
          setAreasModified(spareDispatch, true);
          event.preventDefault();
        }

        break;

      case KEYS.RIGHT:
        if (modeRef.current === 'editing' && selectedAreaRef.current) {
          selectedAreaRef.current.move(1, 0);
          setAreasModified(spareDispatch, true);
          event.preventDefault();
        }

        break;

      case KEYS.DELETE:
        if (modeRef.current === 'editing' && selectedAreaRef.current) {
          removeNode(selectedAreaRef.current);

          if (selectedAreaRef.current.ref_link && selectedAreaRef.current.ref_link.ref) {
            let ref = refsRef.current.filter((r) => (r.ref === selectedAreaRef.current.ref_link.ref));
            ref[0].area_links--;

            updateSpareContext(spareDispatch, {
              spare: spare,
              isModified: {
                areas: true
              }
            });

          } else {
            setAreasModified(spareDispatch, true);
          }
          selectedAreaRef.current = null;

          setMode('drawing');
          spareModificationPostActions();
        }

        break;

      default: break;
    }
  }

  const addAreas2Spare = (spare, areas) => {
    spare.areas = [];
    utils.foreachReverse(areas, function (x) {
      spare.areas.push(x.toJSONElementString());
    });

    return spare;
  }

  const disableZoom = () => {
    zoomHandler.current = viewer.current.innerTracker.scrollHandler;
    viewer.current.innerTracker.scrollHandler = false;
  }

  const enableZoom = () => {
    viewer.current.innerTracker.scrollHandler = zoomHandler.current;
  }

  // Will moved from the main module
  let areasIO = {
    toJSON: function () {
      var obj = {
        areas: [],
        img: appState.image.src
      };

      utils.foreach(areasRef.current, function (x) {
        obj.areas.push(x.toJSON());
      });

      return JSON.stringify(obj);
    },
    fromJSON: function (str) {
      var obj = JSON.parse(str);

      loadImage(obj.img);

      utils.foreach(obj.areas, function (areaParams) {
        Area.fromJSON(areaParams);
      });
    }
  };

  // Will moved from the main module
  const localStorageWrapper = (function () {
    var KEY_NAME = 'SummerHTMLImageMapCreator';

    return {
      save: function () {
        var result = areasIO.toJSON();
        window.localStorage.setItem(KEY_NAME, result);
        console.info('Editor ' + result + ' saved');
      },
      restore: function () {
        areasIO.fromJSON(window.localStorage.getItem(KEY_NAME));
      }
    };
  })();

  const loadImage = (url) => {
    domElementsRef.current.img.src = url;
    appState.image.src = url;

    launchViewer();
  }

  const clear = () => {
    //remove all areas
    areasRef.current = [];

    if (domElementsRef.current.svg) {
      domElementsRef.current.svg.removeChild(domElementsRef.current.svg.childNodes[0]);
    }
    return this;
  }

  const removeNode = (obj) => {
    areasRef.current = areasRef.current.filter((e) => (e !== obj));
    removeNodeFromSvg(obj._groupEl);
  }

  const deselectAll = () => {
    utils.foreach(areasRef.current, function (x) {
      x.deselect();
    });
    return this;
  }

  const getIsDraw = () => {
    return appState.isDraw;
  }

  const setIsDraw = (arg) => {
    appState.isDraw = arg;
  }

  const setShape = (arg) => {
    setState({ ...state, activeShape: arg });
  }

  const getShape = () => {
    return stateRef.current.activeShape;
  }

  const resetNewArea = () => {
    newAreaRef.current = null;
  }

  const setEditClass = () => {
    domElementsRef.current.container.classList.remove('draw');
    domElementsRef.current.container.classList.add('edit');
  }

  const setDrawClass = () => {
    domElementsRef.current.container.classList.remove('edit');
    domElementsRef.current.container.classList.add('draw');
  }

  const addEvent = (target, eventType, func) => {
    appState.events.push(new AppEvent(target, eventType, func));
  }

  const removeAllEvents = () => {
    utils.foreach(appState.events, function (x) {
      if (x instanceof OpenSeaDragon.MouseTracker) {
        x.destroy();
      } else {
        x.remove();
      }
    });
    appState.events.length = 0;
  }

  const activateShapeDrawing = (button) => {
    setDrawingMode(button.id);
  }

  const setEditingMode = () => {
    setMode('editing');
    setEditClass();
  }

  const setDrawingMode = (shape) => {
    setMode('drawing');
    setDrawClass();
    setShape(shape);
    deselectAll();
  }

  const onCircleButtonClick = (event) => {
    activateShapeDrawing(utils.id('circle'));
    event.preventDefault();
  }

  const onRectangleButtonClick = (event) => {
    activateShapeDrawing(utils.id('rectangle'));
    event.preventDefault();
  }

  const onPolygonButtonClick = (event) => {
    activateShapeDrawing(utils.id('polygon'));
    event.preventDefault();
  }

  const removeSelectedAreaLink = (e) => {

    if (selectedAreaRef.current.ref_link && selectedAreaRef.current.ref_link.ref) {
      let ref = refsRef.current.filter((r) => (r.ref === selectedAreaRef.current.ref_link.ref));
      ref[0].area_links--;
      delete selectedAreaRef.current.ref_link;

      updateSpareContext(spareDispatch, {
        spare: spare,
        isModified: {
          areas: true
        }
      });

      setContextMenuAnchor(null);

      return false;
    }
  }

  return (
    <Box className={classes.container}>

      <Box className={classes.commandButtonsWrapper}>
        <Box className={classes.commandButtonsWrapperLeft}>
          {isEditingSpare &&
            <Render perform="spares:edit">
              <ShapesButtonBar active={getShape()} />
            </Render>
          }
        </Box>

        <Box className={classes.commandButtonsWrapperRight}>
          <SpeedDial dialActions={dialActions} />
        </Box>
      </Box>

      <Box position="relative">
        <div id="image_wrapper">
          <div id="image" ref={divImageRef}>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.2" baseProfile="tiny" id="svg"> </svg>
            <img alt="#" id="img" style={{ display: 'hidden' }} />
          </div>
        </div>
      </Box>
      <div style={{ width: '100%', flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <div elevation={3} style={{ width: '99%', height: '100%', position: 'relative' }}>

          <div id="overlay"></div>

          <div id="spareviewer" className={classes.viewerContainer}>
            <ViewerToolbar />
            <div id="yy" />
          </div>
        </div>
      </div>

      <Popover
        id={contextMenuAnchor ? 'simple-popover' : undefined}
        open={(isEditingSpare && contextMenuAnchor !== null)}
        anchorEl={contextMenuAnchor}
        onClose={() => setContextMenuAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Link to="#"
          onClick={removeSelectedAreaLink}
          className={classes.areaContextMenuLink}
          disabled={!selectedAreaRef.current || !selectedAreaRef.current.ref_link || !selectedAreaRef.current.ref_link.ref}>
          <Box className={classNames({
            [classes.areaContextMenuLinkDisabled]: (!selectedAreaRef.current || !selectedAreaRef.current.ref_link || !selectedAreaRef.current.ref_link.ref),
          }, classes.areaContextMenuItem)}>
            <div>
              <PowerOffIcon />
            </div>
            <div>
              Eliminar enlace a referencia
            </div>
          </Box>
        </Link>

      </Popover>
    </Box>
  );

} // END App
