import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List, Tooltip } from "@material-ui/core";
import {
  Home as HomeIcon,
  NotificationsNone as NotificationsIcon,
  FormatSize as TypographyIcon,
  FilterNone as UIElementsIcon,
  BorderAll as TableIcon,
  AddToPhotosOutlined as AddIcon,
  PolymerOutlined as BrandIcon,
  QuestionAnswer as SupportIcon,
  LibraryBooks as LibraryIcon,
  HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon,
  ShoppingBasket as CartIcon,
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { useHistory, withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import Dot from "./components/Dot";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import SpareUpload from "components/spclick/SpareUpload";
import Config from "config";

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const history = useHistory();

  const handleFileUploadDialogClickAway = () => {
    setUploadDialogOpen(false);
  }

  const onNewSpareDialogCancel = () => {
    setUploadDialogOpen(false);
  }

  const onNewSpareDialogOk = (spare) => {
    setUploadDialogOpen(false);
    let navUrl;
    if (Config.SpareActions.EDIT.url !== history.location.pathname
      && Config.SpareActions.BROWSE.url !== history.location.pathname) {
      navUrl = Config.SpareActions.BROWSE.url;
    } else {
      navUrl = history.location.pathname;
    }
    history.push(navUrl + "/" + spare.id);
  }

  const structure = [
    { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },
    {
      id: 1,
      label: "Nuevo despiece",
      onClick: (e) => {
        e.stopPropagation();
        e.preventDefault();
        setUploadDialogOpen(true);

      },
      replace: true,
      link: "#",
      icon: <Tooltip title="Nuevo despiece"><AddIcon /></Tooltip>,
      item: <>{uploadDialogOpen && (
        <SpareUpload
          handleDialogClickAway={handleFileUploadDialogClickAway}
          onOk={onNewSpareDialogOk}
          onCancel={onNewSpareDialogCancel} />
      )}</>
    },     {
      id: 20,
      label: "Carritos  ",
      link: "/app/carts",
      icon: <Tooltip title="Mis carritos"><CartIcon /></Tooltip>,
    },
    {
      id: 3,
      label: "Marcas  ",
      link: "/app/brands",
      icon: <Tooltip title="Mis marcas"><BrandIcon /></Tooltip>,
    },
    { id: 7, type: "divider" },
    { id: 8, type: "title", label: "HELP" },
    { id: 9, label: "Library", link: "", icon: <LibraryIcon /> },
    { id: 10, label: "Support", link: "", icon: <SupportIcon /> },
    { id: 11, label: "FAQ", link: "", icon: <FAQIcon /> },
    { id: 12, type: "divider" },
    { id: 13, type: "title", label: "PROJECTS" },
    {
      id: 14,
      label: "My recent",
      link: "",
      icon: <Dot size="large" color="warning" />,
    },
    {
      id: 15,
      label: "Starred",
      link: "",
      icon: <Dot size="large" color="primary" />,
    },
    {
      id: 16,
      label: "Background",
      link: "",
      icon: <Dot size="large" color="secondary" />,
    },
  ];

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
