import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";

import Themes from "./themes";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
import { SpareProvider } from "./context/SpareContext";
import {
  SparePickerProvider
} from "context/SparePickerContext";
import {
  SpareTreeProvider
} from 'context/SpareTreeContext';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://0a9e9fc6082e4e94a0a1199f38ae2d47@mon.agilemindsoft.com/1",
  integrations: [
    new Integrations.BrowserTracing(),
  ],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={"An error has occured"}>
  <LayoutProvider>
    <UserProvider>

      <ThemeProvider theme={Themes.alex}>
        <SpareTreeProvider>
          <SpareProvider>
            <CssBaseline />
              <SparePickerProvider>
                  <App />
              </SparePickerProvider>
          </SpareProvider>
        </SpareTreeProvider>
      </ThemeProvider>

    </UserProvider>
  </LayoutProvider>
  </Sentry.ErrorBoundary>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
