import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    "&> *": {
      marginRight: theme.spacing(1)
    }
  },
  button: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.secondary,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    }
  }

 
}));
