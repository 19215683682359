import { makeStyles } from "@material-ui/styles";

export default makeStyles( theme => ({
    root: {
      flexGrow: 1,
      maxWidth: 400,
    },
    card: {
    },
      widgetWrapper: {
        height: '98%',
        margin: '1%'
      },
      widgetBody: {
        paddingRight: '5px',
        paddingLeft: '5px',
        paddingBottom: '5px',
      },
      hidden: {
        display: 'none',
      }
  })
)
