import React from 'react';
import useStyles from './styles';

import {
    Breadcrumbs,
    Link,
    Typography,
} from '@material-ui/core'
import {
    NavigateNext as NavigateNextIcon,
} from "@material-ui/icons";

export default function MyBreadCrumb({ data, history }) {
    const classes = useStyles();

    const handleBreadcrumbClick = (event, url) => {
        event.preventDefault();

        history.push({
            pathname: url,
        })
    }
    return (<>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            <Link color="inherit"
                href='#'
                onClick={(e) => handleBreadcrumbClick(e, "/")}
                className={classes.link}>
                <Typography color="textPrimary">Home</Typography>
            </Link>
            {(data.length > 1) &&
                data.slice(0, data.length - (data.length === 2 ? 1 : 2)).map((item, idx) => {
                    return (<Link key={"bc_item_" + idx}
                        color="inherit"
                        href='#'
                        onClick={(e) => handleBreadcrumbClick(e, item.url)}
                        className={classes.link}>
                        <Typography color="textPrimary">{item.title}</Typography>
                    </Link>)
                })
            }
            {data.length > 0 && 
                <Typography color="textPrimary">{data[data.length - 1].title}</Typography>
            }
        </Breadcrumbs>
    </>
    );
}