import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',
    overflowY: 'scroll'
  },
  datatable: {
    width: '99%',
    maxWidth: '99%',
    minHeight: 600,
  },
  muitableActionRootFloat: {
    backgroundImage: "radial-gradient(#c5c2c2 5%, #E0E0E0 12%)",
    backgroundSize: "15px 15px",
  },
  actionIcon: {
    marginRight: 5
  },
  floatingDragger: {
    backgroundColor: "#EEEEEE",
    cursor: 'grab'
  },
  tableLangBox: {
    display: 'flex',
    width: '100%',
    padding: theme.spacing(.5)
  },
  tableLangBoxLeft: {
    flex: 1,
    justifyContent: 'flex-start',
    textAlign: 'left'
  },
  tableLangBoxRight: {
    flex: 1,
    display: 'flex',
    position: 'relative',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  rowQuantityBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  }
 /* Flipped: { // Material table top-scrollbar
    transform: 'rotateX(180deg)',
    "& > div ": {
      transform: 'rotateX(180deg)'
    },
    "& > div > div ": {
      transform: 'rotateX(180deg)'
    },
    "& > div > div > div ": {
      transform: 'rotateX(180deg)'
    },
  },*/

}));