/**
 * The abstract constructor for area
 *
 * @constructor
 * @abstract
 * @param type {string} - type of area ('rectangle', 'circle' or 'polygon')
 * @param coords {Object} - coordinates of area (e.g. x, y, width, height)
 * @param attributes {Object} [attributes=undefined] - attributes for area (e.g. href, title)
 */
class Area {

  static SVG_NS = 'http://www.w3.org/2000/svg'; // TODO: move to main editor constructor
  static CLASS_NAMES = {
    SELECTED: 'selected',
    WITH_HREF: 'with_href'
  };
/*  static CONSTRUCTORS = {
    rectangle: Rectangle,
    circle: Circle,
    polygon: Polygon
  };*/
  static REGEXP = {
    AREA: /<area(?=.*? shape="(rect|circle|poly)")(?=.*? coords="([\d ,]+?)")[\s\S]*?>/gmi,
    HREF: / href="([\S\s]+?)"/,
    ALT: / alt="([\S\s]+?)"/,
    TITLE: / title="([\S\s]+?)"/,
    DELIMETER: / ?, ?/
  };
  static HTML_NAMES_TO_AREA_NAMES = {
    rect: 'rectangle',
    circle: 'circle',
    poly: 'polygon'
  };
  static ATTRIBUTES_NAMES = ['HREF', 'ALT', 'TITLE'];

  constructor (type, coords, attributes, info, events) {
  if (this.constructor === Area) {
    throw new Error('This is abstract class');
  }

  this._type = type;
  this.onClickListener = events.onClickListener;
  this.onDragOver = events.onDragOver;
  this.onDrop = events.onDrop;

  /**
   * @namespace
   * @property href {string} - href-attribute of area
   * @property alt {string} - alt-attribute of area
   * @property title {string} - title-attribute of area
   */
  this._attributes = {
    href: '',
    alt: '',
    title: '',
  };

  // Link between area and spare ref
  this.ref_link = {
  }

  if (attributes) {
    this.setInfoAttributes(attributes);
  }

  this._coords = coords;
  this.info = info;
  // the g-element, it contains this area and helpers elements
  this._groupEl = document.createElementNS(Area.SVG_NS, 'g');
  //this._groupEl.style = "z-index: 100;";

  this._groupEl.addEventListener('click', this.onClickListener, false);

  this._groupEl.addEventListener('drop', this.onDrop, false);
  
  this._groupEl.addEventListener('dragover', this.onDragOver, false);

  // TODO: remove this field from DOM-element
  // Link to parent object
  this._groupEl.obj = this;

  // svg-dom-element of area
  this._el = null;

  // Object with all helpers of area
  this._helpers = {};

  // Add this new area to list of all areas
  //this.addObject(this);
}

/**
 * This method should be implemented for child-classes
 *
 * @throws {AbstractMethodCall}
 */
/*Area.prototype.ABSTRACT_METHOD = function() {
  throw new Error('This is abstract method');
};*/

/**
 * All these methods are abstract
 *
 * @throws {AbstractMethodCall}
 */
/*  Area.prototype.setSVGCoords =
  Area.prototype.setCoords =
  Area.prototype.dynamicDraw =
  Area.prototype.onProcessDrawing =
  Area.prototype.onStopDrawing =
  Area.prototype.edit =
  Area.prototype.dynamicEdit =
  Area.prototype.onProcessEditing =
  Area.prototype.onStopEditing =
  Area.prototype.toString =
  Area.prototype.toHTMLMapElementString =
  Area.prototype.getCoordsForDisplayingInfo =
  Area.prototype.ABSTRACT_METHOD;*/

/**
 * Redraw this area with own or custom coordinates
 *
 * @param coords {Object} [coords=undefined]
 * @returns {Area} - this area
 */
redraw = (coords) => {
  this.setSVGCoords(coords ? coords : this._coords);

  return this;
};

/**
 * Remove this area from DOM-tree
 *//*
remove = () => {
  this.app.removeNodeFromSvg(this._groupEl);
};
*/
/**
 * Move this area by dx, dy
 *
 * @returns {Area} - this area
 */
move = (dx, dy) => {
  this.setCoords(this.edit('move', dx, dy)).redraw();
  return this;
};

/**
 * Add class name for selected areas to this area
 *
 * @returns {Area} - this area
 */
select = () => {
  this._el.classList.add(Area.CLASS_NAMES.SELECTED);

  return this;
};

/**
 * Remove class name for selected areas from this area
 *
 * @returns {Area} - this area
 */
deselect = () => {
  this._el.classList.remove(Area.CLASS_NAMES.SELECTED);

  return this;
};

/**
 * Set style of element with href attribute for this area
 *
 * @returns {Area} - this area
 */
setStyleOfElementWithHref = () => {
  this._el.classList.add(Area.CLASS_NAMES.WITH_HREF);

  return this;
};

/**
 * Unset style of element with href attribute for this area
 *
 * @returns {Area} - this area
 */
unsetStyleOfElementWithHref = () => {
  this._el.classList.remove(Area.CLASS_NAMES.WITH_HREF);

  return this;
};

/**
 * Set attributes (href, alt and title) for this area
 *
 * @param attributes {Object} - Object with attributes for area
 */
setInfoAttributes = (attributes) => {
  this._attributes.href = attributes.href;
  this._attributes.alt = attributes.alt;
  this._attributes.title = attributes.title;
};

/**
 * Returns json-representation of this area
 *
 * @returns {Object}
 */
toJSON = () => {
  /**
   * @namespace
   * @property type {string} - type of this area (e.g. 'rectangle', 'circle')
   * @property coords {Object} - coordinates of this area (e.g. 'x', 'width')
   * @property attributes {Object} - attributes of this area (e.g. 'href', 'title')
   */
  return {
    shape: this._type,
    coords: this._coords,
    ref_link: this.ref_link
    //attributes: this._attributes
  };
};

/**
 * Returns new area object created with params from json-object
 *
 * @static
 * @param params {Object} - params of area, incl. type, coords and attributes
 * @returns {Rectangle|Circle|Polygon}
 */
fromJSON = (params) => {
  var AreaConstructor = Area.CONSTRUCTORS[params.type];

  if (!AreaConstructor) {
    throw new Error('This area type is not valid');
  }

  if (!AreaConstructor.testCoords(params.coords)) {
    throw new Error('This coords is not valid for ' + params.type);
  }

  this.setIsDraw(true);

  var area = new AreaConstructor(params.coords, params.attributes);

  this.setIsDraw(false)
    .resetNewArea();

  return area;
};

/**
 * Creates new areas from html-string with <area /> elements
 *
 * @param htmlStr {string}
 * @returns {Array} - array with areas
 */
createAreasFromHTMLOfMap = (htmlStr) => {
  if (!htmlStr) {
    return false;
  }

  var zzFunction = function(item, i) {
    var result = Area.REGEXP[item].exec(htmlAreaFinded);

    if (result) {
      attributes['name'] = result[1];
    }
  };

  while (true) {
    var findedResult = Area.REGEXP.AREA.exec(htmlStr); // <area shape="$1" coords="$2" ... />
    if (!findedResult) {
      break;
    }

    var htmlAreaFinded = findedResult[0], // <area shape="..." coords="..." ... />
      type = findedResult[1], // $1
      coords = findedResult[2].split(Area.REGEXP.DELIMETER), // $2
      attributes = {};



    this.ATTRIBUTES_NAMES.forEach(zzFunction());

    coords = coords.map(function(item) {
      return Number(item);
    });

    type = Area.HTML_NAMES_TO_AREA_NAMES[type];

    this.fromJSON({
      type: type,
      coords: Area.CONSTRUCTORS[type].getCoordsFromHTMLArray(coords),
      attributes: attributes
    });

  }

  return Boolean(htmlAreaFinded);
};

/**
 * Returns copy of original area, selected and moved by (10,10) from it
 *
 * @param originalArea {Area}
 * @returns {Area} - a copy of original area
 */
copy = (originalArea) => {
  return this.fromJSON(originalArea.toJSON()).move(10, 10).select();
};
}

export default Area;
