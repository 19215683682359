import React from "react";

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    case "SET_LANG":
      return {...state, lang: action.lang};
    case "SET_AUTHENTICATED":
      return {...state, isAuthenticated: action.isAuthenticated };
    case "SET_USER_INFO":
      return {...state, userinfo: action.userinfo};
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("id_token"),
    lang: "es",
    isAuthenticated: false,
    userinfo: null,
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

function setIsAuthenticated(dispatch, isAuthenticated) {
  dispatch({type: "SET_AUTHENTICATED", isAuthenticated: isAuthenticated});
}

function useIsAuthenticated() {
  const state = useUserState();
  return state.isAuthenticated;
}

function setUserinfo(dispatch, userinfo) {
  dispatch({type: "SET_USER_INFO", userinfo: userinfo});
}

function useUserinfo() {
  const state = useUserState();
  return state.userinfo;
}

function useRoles() {
  const state = useUserState();
  return state.userinfo.roles;
}

export {
  UserProvider,
  useUserState,
  useUserDispatch,
  loginUser,
  signOut,
  setLang,
  useLang,
  setIsAuthenticated,
  useIsAuthenticated,
  setUserinfo,
  useUserinfo,
  useRoles
};

// ###########################################################

function loginUser(dispatch, login, password, history, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);

  if (!!login && !!password) {
    setTimeout(() => {
      localStorage.setItem("id_token", "1");
      dispatch({ type: "LOGIN_SUCCESS" });
      setError(null);
      setIsLoading(false);

      history.push("/app/dashboard");
    }, 2000);
  } else {
    dispatch({ type: "LOGIN_FAILURE" });
    setError(true);
    setIsLoading(false);
  }
}

function signOut(dispatch, history) {
  localStorage.removeItem("id_token");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}

function setLang(dispatch, lang) {
  dispatch({type: 'SET_LANG', lang: lang});
}

function useLang() {
  const {lang} = useUserState();
  return lang;
}