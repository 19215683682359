import { makeStyles } from "@material-ui/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

export default makeStyles( theme => ({
    wrapper: {
    },
    spareMenuIcon: {
        margin: theme.spacing(1),
        padding: 10,
        color: theme.palette.text.secondary,
        backgroundColor: fade(theme.palette.common.black, 0.08),
        "&:hover": {
          color: theme.palette.primary,
          backgroundColor: theme.palette.secondary.main,
        },
      },
      paper: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        width: 400,
        minHeight: 800,
        maxHeight: 800,
        height: 800,
        zIndex: 100000001,
        borderRadius: 10,
        position: 'relative',
        left: 150
      },
      popper: {
        zIndex: 100000000,
      }
}));