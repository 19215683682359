import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    display: "flex",
    maxWidth: "100vw",
    overflowX: "hidden",
    height: '100%'
  },
  content: {
    display: 'flex',
    flexDirection: "column",
    padding: theme.spacing(1),
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 90,
    marginBottom: 7,
    width: '100vw',
  },
  '@media (min-height: 900px)': {
    content: {
      display: 'flex',
      flexDirection: "column",
      padding: theme.spacing(3),
      paddingTop: 0,
      paddingBottom: 0,
      //marginTop: '5%',
      width: '100vw',
    },
  },

  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    display: 'none',
  },
}));
