import React, { useRef, useState } from 'react';
import { Box, Button, IconButton, TextField } from '@material-ui/core';
import {
    AccessAlarm as AccessAlarmIcon,
    Add,
    ShoppingCart as AddShoppingCartIcon,
    ExpandLess as MoreIcon,
    ExpandMore as MinusIcon,
    ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    Edit,
    FilterList,
    FirstPage,
    LastPage,
    Power as PowerIcon,
    PowerOff as PowerOffIcon,
    Remove,
    SaveAlt,
    Search,
    ViewColumn
} from "@material-ui/icons";

import useStyles from './styles';

const QuantitySelector = ({ initialQuantity, minVal = 0, maxVal = 9999, step = 1, addToCartHandler }) => {
    const classes = useStyles();
    const [warningIcon, setWarningIcon] = useState(false);
    const [quantity, setQuantity] = useState(initialQuantity);

    const handleQuantityChange = (quantity) => {
        const re = /^[0-9]+$/

        if (re.test(quantity)) {
            let q = parseInt(quantity);
            if (q >= minVal) {
                setQuantity(q);
                setWarningIcon(q !== initialQuantity);
            }
        }
    }

    const incQuantity = () => {
        let q = parseInt(quantity) + step;

        if (q <= maxVal) {
            setQuantity(q);
            setWarningIcon(q !== initialQuantity);
        }
    }

    const decQuantity = () => {
        let q = parseInt(quantity);
        q = q - step;

        if (q >= minVal) {
            setQuantity(q);
            setWarningIcon(q !== initialQuantity);
        }
    }

    return (
        <Box className={classes.container}>
            <TextField value={quantity} size="small"
                className={classes.textInput}
                inputProps={{
                    size: 3
                }}
                onChange={ev => handleQuantityChange(ev.target.value)}
                disabled={step!==1} />
            <Box className={classes.plusMinusContainer}>

                <Button aria-label="add-to-cart"
                    onMouseDown={incQuantity}
                    variant='outlined'
                    className={classes.plusMinusButton}>
                    <MoreIcon />
                </Button>
                <Button aria-label="add-to-cart"
                    onMouseDown={decQuantity}
                    variant='outlined'
                    className={classes.plusMinusButton}>
                    <MinusIcon />
                </Button>
            </Box>
            <IconButton aria-label="add-to-cart" size="small"
                onClick={addToCartHandler(quantity)}>
                {warningIcon ?
                    <AddShoppingCartIcon color="primary" />
                    :
                    <AddShoppingCartIcon />
                }
            </IconButton>
        </Box>

    );
}

export default QuantitySelector;