import React from "react";

import './styles.css';

export default function SpSpinner(props) {

    return (
        
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', flex: 1, color: 'black'}}>
            <div className="loader" 
            style={{width: 600, height: 600, position: 'relative'}}>
                <div className="body">
                <span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </span>
                <div className="base">
                    <span></span>
                    <div className="face"></div>
                </div>
                </div>
                <div className="longfazers">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                </div>
                <h1></h1>
            </div>
        </div>
    );
}