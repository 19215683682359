import tinycolor from "tinycolor2";
//import theme from "components/spclick/SpareTree/storybookjs/theme";

const primary = "#ff9800";
const secondary = "#ff1744";
const warning = "#FFC260";
const success = "#3CD4A0";
const info = "#9013FE";
const lines = "#EDEDED";
const grey = {
  light: "#e7e7e7",
  medium: "#9E9E9E",
}

const lightenRate = 7.5;
const darkenRate = 3;

const primary_light = tinycolor(primary).lighten(lightenRate).toHexString();
const primary_dark = tinycolor(primary).darken(darkenRate).toHexString();

const text = {
  primary: "rgba(0, 0, 0, 0.55)",
  "secondary": "rgba(255, 255, 255, 1)",
  "disabled": "rgba(0, 0, 0, 0.30)",
  "hint": "rgba(0, 0, 0, 0.38)",
  size: {
    table: '0.85em'
  }
};

const theme = {
  palette: {
    primary: {
      main: primary,
      light: primary_light,
      ultralight: tinycolor(primary)
        .lighten(lightenRate * 5)
        .toHexString(),
      dark: primary_dark,
      "contrastText": "rgba(255, 255, 255, 1)"
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(secondary)
        .darken(darkenRate)
        .toHexString(),
      "contrastText": "#fff"
    },
    warning: {
      main: warning,
      light: tinycolor(warning)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(warning)
        .darken(darkenRate)
        .toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(success)
        .darken(darkenRate)
        .toHexString(),
    },
    info: {
      main: info,
      light: tinycolor(info)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(info)
        .darken(darkenRate)
        .toHexString(),
    },
    error: {
      "light": "#e57373",
      "main": "#f44336",
      "dark": "#d32f2f",
      "contrastText": "#fff"
    },
    text: text,
    common: {
      "black": "rgba(0, 0, 0, 1)",
      "white": "#fff"
    },
    background: {
      "paper": "rgba(255, 255, 255, 1)",
      "solid": "#FAFAFA",
      "default": "#fafafa"
    },
  },

  customShadows: {
    widget:
      "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetDark:
      "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetWide:
      "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
  },
  disabledElement: {
    color: grey.medium
  },
  mainPageContainer: {
    flex: 1
  },
  aRight: {
    textAlign: 'right'
  },
  height100: {
    height: '100%'
  },
  Wid50: {
    width: '50%'
  },
  Wid75: {
    width: '75%'
  },
  MarAuto: {
    margin: '0 auto'
  },
  separator: {
    borderBottom: '1px solid',
    borderColor: 'red',
  },
  sparePathBar: 60,
  text: {
    regular: {
      fontSize: '1.1rem'
    }
  },
};

const themeOverrides = (theme) => ({
  MuiButton: {

  },
  MuiBackdrop: {
    root: {
      backgroundColor: "#4A4A4A1A",
    },
  },
  MuiIconButton: {
    root: {
    }
  },
  MuiMenu: {
    paper: {
      boxShadow:
        "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    },
  },
  MuiSelect: {
    icon: {
      color: "#B9B9B9",
    },
  },
  MuiListItem: {
    root: {
      "&$selected": {
        backgroundColor: "#F3F5FF !important",
        "&:focus": {
          backgroundColor: "#F3F5FF",
        },
      },
    },
    button: {
      "&:hover, &:focus": {
        backgroundColor: "#F3F5FF",
      },
    },
  },
  MuiTouchRipple: {
    child: {
      backgroundColor: "white",
    },
  },
  MuiTableRow: {
    root: {
      height: 48,
    },
  },
  MuiTableCell: {
    root: {
      borderBottom: "5px solid rgba(224, 224, 224, .5)",
      padding: 4,
      fontSize: "0.90em !important",
    },
    head: {
      fontSize: "0.95rem",
    },
    body: {
      fontSize: "0.95rem",
    },
  },

  MuiAppBar: {
    root: {
      height: '8% !important',
      minHeight: '8% !important',
      display: 'block',
    }
  },
  MuiToolbar: {
    root: {
      "& button": {
        marginLeft: 3,
        backgroundColor: theme.palette.primary.main,
        padding: 5,
        "&:hover": {
          backgroundColor: theme.palette.primary.dark,
        }
      },
      "& > .MuiFormControl-root": {
        marginRight: 10,
        zIndex: 100
      },
    },
    "& .spacer": {
      display: 'none !important'
    },
    regular: {
      minHeight: '100% !important'
    },
  },
  MuiFab: {
    small: {
      width: '26px !important',
      height: '26px',
      minHeight: '26px',
    }
  },
  MinimalSelect: {
    select: {
      color: theme.palette.text.primary,
      background: 'transparent',
      boxShadow: 'none',
      "&:focus": {
        background: 'transparent'
      },
      "&:hover": {
        background: 'transparent'
      }
    },
    list: {
      "& li:hover": {
        background: theme.palette.primary.light
      },
      "& li.Mui-selected": {
        background: theme.palette.primary.dark + ' !important'
      },
      "& li.Mui-selected:hover": {
        background: theme.palette.primary.dark + ' !important'
      }
    }
  },
  MuiDialog: {
    root: {
      zIndex: "2001 !important"
    }
  },
  MuiRadio: {
    root: {
      color: lines
    },
    colorSecondary: {
      "&.Mui-checked": {
        color: primary
      }
    },
    checked: {
      color: primary,
      borderLeftColor: primary,
    }
  },
  MuiFormLabel: {
    root: {
      color: primary,
      "&.Mui-focused": {
        color: primary
      }
    }
  },
  MuiTypography: {
    colorTextSecondary: {
      color: theme.palette.text.primary
    }
  },
  MuiDialogTitle: {
    root: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.secondary,
    }
  },
  MuiPopover: {
    root: {
      zIndex: "99999 !important"
    }
  },
  MuiCheckbox: {
    root: {
      color: theme.palette.text.primary,
    }
  },

  MuiTableSortLabel: {
    icon: {
      color: theme.palette.primary.main + ' !important'
    },
    iconDirectionAsc: {
      color: theme.palette.primary.main + ' !important'
    },
    iconDirectionDesc: {
      color: theme.palette.primary.main + ' !important'
    }
  },
  MuiTablePagination: {
    select: {
      color: theme.palette.primary.main + " !important",
    },
    toolbar: {
      "& .MuiTypography-root": {
        color: theme.palette.primary.main + " !important",
      },
      "& button": {
        backgroundColor: 'transparent !important'
      }
    }
  },
  MuiLink: {
    root: {
      color: theme.palette.primary.main + " !important",
    }
  },
  MuiDialogActions: {
    root: {
      "& a": {
        color: theme.palette.primary.main
      },
      "& a:link": {
        color: theme.palette.primary.main
      },
      "& a:visited": {
        color: theme.palette.primary.main
      }
    }
  },

});

export {
  theme,
  themeOverrides
}
