import React from "react";
import { 
  Box,
  Fab
  
} from "@material-ui/core";

import Brightness1Icon from '@material-ui/icons/Brightness1Outlined';
import CropSquareIcon from '@material-ui/icons/CropSquareOutlined';
import TimeLineIcon from '@material-ui/icons/TimelineOutlined';

import useStyles from "./styles";
import clsx from 'clsx';

export default function ShapesButtonBar({active}) {
    const classes = useStyles();

    return (
    <Box className={classes.container}>
        <Fab id = "rectangle" size="small" 
            className={clsx(classes.button, {[classes.selected]: (active==='rectangle')})} 
            selected aria-label="add">
        <CropSquareIcon/>
        </Fab>
        <Fab id = "circle" size="small" 
            className={clsx(classes.button, {[classes.selected]: (active==='circle')})} 
            aria-label="add">
            <Brightness1Icon/>
        </Fab>
        <Fab id = "polygon" size="small" 
            className={clsx(classes.button, {[classes.selected]: (active==='polygon')})}
            aria-label="add">
            <TimeLineIcon/>
        </Fab>
    </Box>
    )
}