import React from 'react';

import useStyles from './styles';

export const ExternalLink = React.forwardRef((props, ref) => {
    const {linkTitle, url, className, ...other}  = props;
    const ownClasses = useStyles();

    return (
        <a
            {...other}
            ref={ref}
            className={[ownClasses.root, className].join(' ')}
            rel="noopener noreferrer"
            _target="_blank"
            href={url}>{linkTitle}{props.children}</a>
    )
})

export default ExternalLink;
