import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Fab,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  MailOutline as MailIcon,
  NotificationsNone as NotificationsIcon,
  Person as AccountIcon,
  Search as SearchIcon,
  Send as SendIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";

import classNames from "classnames";

// styles
import useStyles from "./styles";

import Config from 'config';

// components
import { Badge, Typography } from "../Wrappers/Wrappers";
import UserAvatar from "../UserAvatar/UserAvatar";

import { useSnackbar } from 'notistack';

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "context/LayoutContext";

import { 
  useUserDispatch, 
  signOut,
  useLang,
  setLang,
  useUserinfo,
} from "context/UserContext";

import {
  useSpareDispatch,
  setCurrentSpare,
  toggleLoadingSpare,
} from 'context/SpareContext';

import AutocompleteWrapper from './AutocompleteWrapper';
import SpareMenu from '../spclick/SpareMenu';
import SpareSearch from "apiclient/model/SpareSearch";
import MinimalSelect from "components/MinimalSelect";
import { errorHandler, showNetworkErrorMsg } from "utils/fetchutils";
import { useHistory, useLocation } from "react-router-dom";

const messages = [
  {
    id: 0,
    variant: "warning",
    name: "Jane Hew",
    message: "Hey! How is it going?",
    time: "9:32",
  },
  {
    id: 1,
    variant: "success",
    name: "Lloyd Brown",
    message: "Check out my new Dashboard",
    time: "9:18",
  },
  {
    id: 2,
    variant: "primary",
    name: "Mark Winstein",
    message: "I want rearrange the appointment",
    time: "9:15",
  },
  {
    id: 3,
    variant: "secondary",
    name: "Liana Dutti",
    message: "Good news from sale department",
    time: "9:09",
  },
];

const notifications = [
  { id: 0, color: "warning", message: "Check out this awesome ticket" },
  {
    id: 1,
    color: "success",
    type: "info",
    message: "What is the best way to get ...",
  },
  {
    id: 2,
    color: "secondary",
    type: "notification",
    message: "This is just a simple notification",
  },
  {
    id: 3,
    color: "primary",
    type: "e-commerce",
    message: "12 new orders has arrived today",
  },
];

export default function Header(props) {
  const history = useHistory();
  var classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const sparesDispatcher = useSpareDispatch();

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatcher = useUserDispatch();

  // local
  var [mailMenu, setMailMenu] = useState(null);
  var [isMailsUnread, setIsMailsUnread] = useState(true);
  var [notificationsMenu, setNotificationsMenu] = useState(null);
  var [isNotificationsUnread, setIsNotificationsUnread] = useState(true);
  var [profileMenu, setProfileMenu] = useState(null);
  var [isSearchOpen] = useState(true);

  const [searchFilter, setSearchFilter] = useState("");
  const [searchItems, setSearchItems] = useState([]);

  const currentLang = useLang();

  const userinfo = useUserinfo();

  const handleSearchInput = (event) => {
    let searchTxt = event.target.value;
    setSearchFilter(searchTxt);

    if (searchTxt.length>=3) {
      fetch(Config.SPARESAPI_SEARCH_SPARES_URL + "?f=" + searchTxt, {
        credentials: "include"
      }).then(response => {
        if (response.ok) {
          response.json().then(data => {
            let items = [];
            data.forEach((item)=>{
              items.push(SpareSearch.constructFromObject(item));
            });
  
            setSearchItems(items); 
        })
      } else {
        console.log("ERROR searching spares: " + searchTxt);
        enqueueSnackbar('No se ha podido realizar la operación', { 
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
      })
    }
  }

  const handleSearchSelection = async (event, val) => {

    toggleLoadingSpare(sparesDispatcher);
    fetch(Config.SPARESAPI_URL + "/" + val.id, {
      credentials: 'include', // fetch won't send cookies unless you set credentials
    }).then((response)=>{
      if (response.ok) {
        response.json().then(data=>{
          setCurrentSpare(sparesDispatcher, data, false);

          if (Config.SpareActions.EDIT.url !== history.location.pathname
              && Config.SpareActions.BROWSE.url !== history.location.pathname) {
              history.push(Config.SpareActions.BROWSE.url);
          }

        })
      } else {
        errorHandler(response.status, 'No se ha podido obtener el despiece', enqueueSnackbar);
      }
    }).catch((e)=>{
      showNetworkErrorMsg(enqueueSnackbar);
    });

    toggleLoadingSpare(sparesDispatcher);
  }

  const langs = [{
    label: 'Español',
    code: "es"
  },
  {
    label: 'English',
    code: "en"
  }];

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>

        <IconButton
          color="inherit"
          onClick={() => {toggleSidebar(layoutDispatch); } }
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>
        <Typography variant="h3" weight="medium" className={classes.logotype}>
          SpareClick
        </Typography>
        <div className={classes.grow}>
          <SpareMenu/>

            <div className={classes.searchWrapper}>
              <div
                className={classNames(classes.search, {
                  [classes.searchFocused]: isSearchOpen,
                })}>
                <div
                  className={classNames(classes.searchIcon, {
                    [classes.searchIconOpened]: isSearchOpen,
                  })}
                  onClick={() => {}//setSearchOpen(!isSearchOpen)
                  }
                >
                  <SearchIcon classes={{ root: classes.headerIcon }} />
                </div>
                
                <AutocompleteWrapper 
                  searchItems={searchItems}
                  handleSearchInput={handleSearchInput}
                  handleSearchSelection={handleSearchSelection}
                  initialValue='' />
            </div>
    
          </div>
        </div>
        
        <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-controls="mail-menu"
          onClick={e => {
            setNotificationsMenu(e.currentTarget);
            setIsNotificationsUnread(false);
          }}
          className={classes.headerMenuButton}
        >
          <Badge
            badgeContent={isNotificationsUnread ? notifications.length : null}
            color="warning"
          >
            <NotificationsIcon classes={{ root: classes.headerIcon }} />
          </Badge>
        </IconButton>
        <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-controls="mail-menu"
          onClick={e => {
            setMailMenu(e.currentTarget);
            setIsMailsUnread(false);
          }}
          className={classes.headerMenuButton}
        >
          <Badge
            badgeContent={isMailsUnread ? messages.length : null}
            color="secondary"
          >
            <MailIcon classes={{ root: classes.headerIcon }} />
          </Badge>
        </IconButton>
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        <Menu
          id="mail-menu"
          open={Boolean(mailMenu)}
          anchorEl={mailMenu}
          onClose={() => setMailMenu(null)}
          MenuListProps={{ className: classes.headerMenuList }}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              New Messages
            </Typography>
            <Typography
              className={classes.profileMenuLink}
              component="a"
              color="secondary"
            >
              {messages.length} New Messages
            </Typography>
          </div>
          {messages.map(message => (
            <MenuItem key={message.id} className={classes.messageNotification}>
              <div className={classes.messageNotificationSide}>
                <UserAvatar color={message.variant} name={message.name} />
                <Typography size="sm" color="text" colorBrightness="secondary">
                  {message.time}
                </Typography>
              </div>
              <div
                className={classNames(
                  classes.messageNotificationSide,
                  classes.messageNotificationBodySide,
                )}
              >
                <Typography weight="medium" gutterBottom>
                  {message.name}
                </Typography>
                <Typography color="text" colorBrightness="secondary">
                  {message.message}
                </Typography>
              </div>
            </MenuItem>
          ))}
          <Fab
            variant="extended"
            color="primary"
            aria-label="Add"
            className={classes.sendMessageButton}
          >
            Send New Message
            <SendIcon className={classes.sendButtonIcon} />
          </Fab>
        </Menu>
        <Menu
          id="notifications-menu"
          open={Boolean(notificationsMenu)}
          anchorEl={notificationsMenu}
          onClose={() => setNotificationsMenu(null)}
          className={classes.headerMenu}
          disableAutoFocusItem
        >
          {notifications.map(notification => (
            <MenuItem
              key={notification.id}
              onClick={() => setNotificationsMenu(null)}
              className={classes.headerMenuItem}
            >
            </MenuItem>
          ))}
        </Menu>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
          <Typography variant="h6" weight="medium">
              Welcome, 
            </Typography>
            <Typography variant="h5" weight="medium">
              {userinfo?userinfo.username:"-"}
            </Typography>
          </div>
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
            <AccountIcon className={classes.profileMenuIcon} /> Profile
          </MenuItem>
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
            <AccountIcon className={classes.profileMenuIcon} /> Tasks
          </MenuItem>
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
            <AccountIcon className={classes.profileMenuIcon} />
              <MinimalSelect initialSelect={currentLang} onChange={(event)=>{
                setLang(userDispatcher, event.target.value);
              }}>
              {
                langs.map(l=>(<MenuItem value={l.code} key={l.code}>{l.label}</MenuItem>))
              }
              </MinimalSelect>
          </MenuItem>
          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={() => signOut(userDispatcher, props.history)}
            >
              Sign Out
            </Typography>
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
