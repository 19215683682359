import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    mainPageContainer: theme.mainPageContainer,
    aRight: theme.aRight,
    height100: theme.height100,
    MarAuto: theme.MarAuto,
    Wid50: theme.Wid50,
    Wid75: theme.Wid75,
    container: {
      height: '100%',
    },
    sparePickerWrapper :{
      height: '100%',
      minHeight: '100%',
      float: 'left'
    },
    sparePickerWrapperMin: {
      width: '75%',
    },
    sparePickerWrapperMax: {
      width: `calc(100% - ${theme.spacing(6) + 10}px)`,
    },
  }

  )
);
