const free_rules = {
  static: [
    "spares:list",
    "spares:edit",
    "brands:create",
    "brands:edit",
    "brands:delete",
    "companies:create",
    "companies:edit",
    "companies:delete",
    "companies:editusers",
  ],
  dynamic: {
    "posts:edit": ({userId, postOwnerId}) => {
      if (!userId || !postOwnerId) return false;
      return userId === postOwnerId;
    }
  }
};

const pro_rules = {
  static: [
    ...free_rules.static,
    "brands:edit",
  ],
  dynamic: {
    ...free_rules.dynamic,
  }
};

const rules = {
    guest: {
      static: ["posts:list", "home-page:visit"]
    },
    FREE: free_rules,
    PRO: pro_rules,
    ENTERPRISE: {
        static: [
            ...pro_rules.static,
            ],
        dynamic: {
            ...pro_rules.dynamic,
        }
      }
  };
  
  export default rules;
  