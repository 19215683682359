import {makeStyles} from '@material-ui/styles'

export default makeStyles(theme => ({
    classification: {
        textAlign: 'center',
        color: theme.palette.primary.main
    },
    muitableActionRoot: {
        "& button": {
          marginLeft: 3,
          backgroundColor: theme.palette.primary.main,
          padding: 6,
          "&:hover": {
            backgroundColor: theme.palette.primary.dark,
          }
        },
        "& > .MuiFormControl-root": {
          marginRight: 10
        },
        "& .spacer": {
          display: 'none'
        }
    },
    datatable: {
        width: '99%',
        maxWidth: '99%',
    },
    titleTxt: {
      fontSize: "1.1em",
      fontWeight: 'bold'
    },
    tableBox: {
      height: 425,
      maxHeight: 425,
      overflowY: 'scroll'
    },
}
))