class Config {

//static HOST_URL = 'https://app.spareclick.com';
//  static HOST_URL = 'http://192.168.10.101';
  static HOST_URL = process.env.REACT_APP_SPCLICK_HOST_URL;

  static SpareRefColsLeng = 3;
  static CATALOGS_API_BASE_URL = '/catalogsservice';
  static SPARES_API_BASE_URL = '/sparesservice';

  static AUTHSERVICE_URL = Config.HOST_URL + '/authservice';
  static AUTHSERVICE_AUTHCODE_URL = Config.AUTHSERVICE_URL + '/oauth2/authorize'
  static AUTHSERVICE_GETTOKEN_URL = Config.AUTHSERVICE_URL + '/oauth2/token'

  static AUTHBRIDGE_URL = Config.HOST_URL + "/authbridge";

  static SPARESAPI_URL = Config.AUTHBRIDGE_URL + '/sparesservice' + "/spares";
  static SPARESAPI_SEARCH_SPARES_URL = Config.SPARESAPI_URL + "/search";


  static CLASSIFICATIONSAPI_URL = Config.AUTHBRIDGE_URL + '/classificationsservice' + "/spares/classifications";
  static PUT_CLASSIFICATIONSAPI_URL = Config.AUTHBRIDGE_URL + '/classificationsservice' + "/spares/$$spareId/classifications";

  static BRANDSAPI_URL = Config.AUTHBRIDGE_URL + '/brandsservice' + "/companies";
  static BRANDSAPI_USERACLS_URL = Config.BRANDSAPI_URL + "/useracls";

  static CARTSAPI_URL = Config.AUTHBRIDGE_URL + '/cartsservice/1.0.0';
  static CARTSAPI_GET_CARTS_URL = Config.CARTSAPI_URL + '/carts';

  static CATALOGSAPI_URL = Config.AUTHBRIDGE_URL + Config.CATALOGS_API_BASE_URL + "/catalogs/$cid$"
  static CATALOGSAPI_POST_REFS_URL = Config.CATALOGSAPI_URL;
  static CATALOGSAPI_LOAD_SPECIFIC_REFS_URL = Config.AUTHBRIDGE_URL + Config.CATALOGS_API_BASE_URL + "/catalogs/$cid$/refs"
  static CATALOGSAPI_GET_REFS_URL = Config.CATALOGSAPI_LOAD_SPECIFIC_REFS_URL;

  static PATHS_URL = Config.BRANDSAPI_URL + "/$$company/brands/$$brand/paths";

  static SPARESAPI_UPDATE_SPARE_REFS_URL = Config.AUTHBRIDGE_URL + Config.SPARES_API_BASE_URL + "/spares/$spid$/refs";


  static SPAREREFS_EXCEL_TEMPLATE = Config.HOST_URL + "/resources/downloads/spclick_refs.xlsx";

  static SpareActions = {
    EDIT: {
      outcome: "edit",
      url: "/app/spares/edit"
    },
    BROWSE: {
      outcome: "browse",
      url: "/app/spares/browse"
    }
  }

};

export default Config;
