class NumberParser {
    constructor(locale) {
        const parts = new Intl.NumberFormat(locale).formatToParts(12345.6);
        const numerals = [...new Intl.NumberFormat(locale, { useGrouping: false }).format(9876543210)].reverse();
        const index = new Map(numerals.map((d, i) => [d, i]));
        this._group = new RegExp(`[${parts.find(d => d.type === "group").value}]`, "g");
        this._decimal = new RegExp(`[${parts.find(d => d.type === "decimal").value}]`);
        this._numeral = new RegExp(`[${numerals.join("")}]`, "g");
        this._index = d => index.get(d);
    }
    parse(string) {
        return (string = string.trim()
            .replace(this._group, "")
            .replace(this._decimal, ".")
            .replace(this._numeral, this._index)) ? +string : NaN;
    }
}

const verifyColumn = (title) => {
    const regex = /^(\w+( \*)?)(-\w\w)?$/ig;
    const validTitles = ["ref", "price", "description", "comments", "extra"];

    const found = regex.exec(title);

    return ((found && found.length > 1) ? validTitles.includes(found[1].toLowerCase()) : false);
}

const parseExcel2GenerateTable = (file, defaultLang) => {
    const numberParser = new NumberParser(defaultLang);
    const XLSX = window.XLSX;


    return new Promise((resolve, reject) => {
        var reader = new FileReader();

        reader.onload = function (e) {
            var data = e.target.result;
            var workbook = XLSX.read(data, {
                type: 'binary'
            });
            let lang;

            // For each sheet
            workbook.SheetNames.forEach(function (sheetName) {
                // Here is your object
                var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
                var json_object = JSON.stringify(XL_row_object);
                var jsonData = JSON.parse(json_object)

                if (jsonData && jsonData.length > 0) {

                    // Check column titles
                    const notValidCols = [];

                    Object.keys(jsonData[0]).forEach(title => {
                        if (!verifyColumn(title)) {
                            notValidCols.push("'" + title + "'");
                        }
                    });

                    if (notValidCols.length > 0) {
                        reject("La columna/s no son válidas: " + notValidCols.join(', ').replace(/,\s$/, ""));
                    } else {

                        // Cols are ok. Process each sheet row
                        var newRefs = jsonData.map((row) => {
                            // For each column
                            let ref;
                            Object.keys(row).forEach(title => {
                                let titleLow = title.toLowerCase();

                                if (titleLow === 'ref') {
                                    ref = {
                                        ref: row[title],
                                        translations: {
                                        }
                                    }
                                } else if (titleLow === 'price') {
                                    ref.price = numberParser.parse(row[titleLow]);
                                } else {
                                    var matches = (/^(.*)-(\w\w)$/g).exec(title);

                                    if (matches && matches.length > 0) {
                                        lang = matches[2];
                                    } else {
                                        lang = defaultLang;
                                    }

                                    if (!ref.translations[lang]) {
                                        ref.translations[lang] = [];
                                    }

                                    ref.translations[lang].push(row[title]);
                                }
                            });

                            return ref;
                        });
                        resolve(newRefs);
                    }
                }
            });

        };

        reader.onerror = function (ex) {
            reject('Se ha producido un error. No se ha podido guardar las referencias');
        };

        reader.readAsBinaryString(file);
    })
}

export {
    parseExcel2GenerateTable,
    NumberParser
}