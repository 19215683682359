import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  TextField,
  Typography,
  Zoom
} from "@material-ui/core";

import {
  Add as AddIcon,
  Check as CheckIcon,
} from '@material-ui/icons'

import useStyles from './styles';
import Config from 'config';

import { useSnackbar } from 'notistack';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { useIsEditingSpare } from "context/SpareContext";
import { Link } from "react-router-dom";
import classNames from "classnames";

const CompanyBrandSelector = ({ initialCompanyId, initialBrandId, onSaveHandler, addHandler }) => {
  const [selectedBrand, setSelectedBrand] = useState();
  const classes = useStyles();
  const isEditingSpare = useIsEditingSpare();

  const [brands, setBrands] = useState();
  const [searchItems, setSearchItems] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const handleSearchInput = async (event) => {
    let searchTxt = event.target.value;

    setSearchItems(brands.filter(b => ((b.companyName + b.name.toLowerCase()).includes(searchTxt.toLowerCase()))));
  }

  const handleSearchSelection = (event, val) => {
    setSelectedBrand(val);
  }

  const loadUserBrands = () => {
    const brands = [];

    fetch(Config.BRANDSAPI_URL, {
      credentials: 'include',
    }).then((response) => {
      if (response.ok) {
        response.json().then((items) => {

          items.companies.forEach((company) => {

            company.brands.forEach((brand) => {
              brand.companyName = company.name
              brand.companyId = company.id

              brands.push(brand);
              if (company.id === initialCompanyId && brand.id === initialBrandId) {
                setSelectedBrand(brand);
              }
            });
          });

          setBrands(brands.sort((b1, b2) => {
            if (b1.name !== b2.name) {
              return b1.name.localeCompare(b2.name);
            } else {
              return b1.companyName.localeCompare(b2.companyName);
            }
          }));

        })
      }
    })
  }

  const [initialBrandData, setInitialBrandData] = useState();

  const setupInitialBrandData = (brands) => {
    const items = brands.filter((brand) => brand.id === initialBrandId);

    if (items) {
      return items[0];
    }
  }

  useEffect(() => {
    loadUserBrands();

    return () => {

    }
  }, [])
  return (
    <div className={classNames(classes.levelContainer, { [classes.levelContainerExpanded]: isEditingSpare })}>
      {isEditingSpare && selectedBrand && <Autocomplete
        freeSolo
        value={selectedBrand}
        id="free-solo-2-demo"
        disableClearable
        classes={{
          root: classes.autocompleteRoot,
          input: classes.autocompleteInputRoot,
          option: classes.option,
          listbox: classes.listbox,
        }}
        options={searchItems.map((option) => option)}
        getOptionLabel={(option) => option.name}
        onChange={handleSearchSelection}
        renderInput={(params) => (
          <TextField
            {...params}
            classes={{
              root: classes.inputRoot,
            }}
            style={{ paddingTop: 8 }}
            variant="filled"
            margin="none"
            size="small"
            fullWidth
            InputProps={{
              ...params.InputProps, type: 'search',
              classes: {
                underline: classes.underline,
              }
            }}
            onChange={handleSearchInput}
          />
        )}
        renderOption={(option) => (
          <React.Fragment>
            <div style={{ marginRight: 20 }}>
              <img src={option._links.logo} width={100} alt="logo"></img>
            </div>
            <div>
              {option.name}
            </div>
            <div style={{ marginLeft: 10, fontStyle: 'italic', fontSize: 10 }}>
              {option.companyName}
            </div>
          </React.Fragment>
        )} />
      }

      {isEditingSpare &&
        <Box className={classes.levelIconsContainer}>

          {(selectedBrand && selectedBrand.id !== initialBrandId) &&

            <Zoom in={selectedBrand.id !== initialBrandId} timeout={1000}>
              <IconButton aria-label="save" size="small"
                className={classes.miniButtonSave}
                onClick={(e) => onSaveHandler(selectedBrand.companyId, selectedBrand.id)}>
                <CheckIcon fontSize="small"
                />
              </IconButton>
            </Zoom>
          }
          <IconButton aria-label="add" size="small"
            onClick={(e) => {
              addHandler();
            }}>
            <AddIcon fontSize="small" />
          </IconButton>
        </Box>
      }

      {!isEditingSpare && selectedBrand &&
        <Typography variant="body1">
          <Link to="">
            {selectedBrand._links.logo ?
              <img src={selectedBrand._links.logo} width={100} alt={selectedBrand.name} className={classes.logoText}></img>
              :
              selectedBrand.name
            }
          </Link>
        </Typography>
      }
    </div>
  );
}

export default CompanyBrandSelector;