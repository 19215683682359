import {
    makeStyles
  } from "@material-ui/styles";

  export default makeStyles(theme =>({
    link: {
        display: 'flex',
        "&:hover": {
          textDecoration: 'none'
        }
      }
  }));