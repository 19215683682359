import Area from './Area.js';
import utils from '../utils.js';
import Helper from './Helper.js';

/**
 * The constructor for polygons
 *
 *        {x0, y0}
 *           /\
 *          /  \
 *         /    \
 * {x1, y1} ----- {x2, y2}
 *
 * @constructor
 * @param coords {Object} - object with parameters of new area ('points' is list of points)
 *                          if 'points' is empty, it will set to [(0,0)]
 * @param coords.isOpened {boolean} - if polygon is opened (polyline instead polygon)
 * @param attributes {Object} [attributes=undefined] - attributes for area (e.g. href, title)
 */
class Polygon extends Area {

  constructor(coords, attributes, info, onClickListener) {
      super('polygon', coords, attributes, info, onClickListener);

      /**
       * @namespace
       * @property {Array} points - Array with coordinates of polygon points
       */
      this._coords = {
        points: coords.points || [{
          x: 0,
          y: 0
        }],
        isOpened: coords.isOpened || false
      };

      this._el = document.createElementNS(
        Area.SVG_NS,
        this._coords.isOpened ? 'polyline' : 'polygon'
      );
      this._groupEl.appendChild(this._el);

      this._helpers = {
        points: []
      };

      for (var i = 0, c = this._coords.points.length; i < c; i++) {
        this._helpers.points.push(
          (new Helper(
            this._groupEl,
            this._coords.points[i].x,
            this._coords.points[i].y,
            'movePoint', c === 1 && i === 0 ?'visible':'hidden')).setId(i)
        );
      }

      this._selectedPoint = -1;

      this.redraw();
}

showHelpers = () => {
  for (var i = 0, c = this._helpers.points.length; i < c; i++) {
    this._helpers.points[i].show();
  }
}

hideHelpers = () =>{
  for (var i = 0, c = this._helpers.points.length; i < c; i++) {
    this._helpers.points[i].hide();
  }

}

/**
 * Closes path of the polygon (replaces polyline with polygon)
 */
close = () => {
  var polyline = this._el;
  this._el = document.createElementNS(Area.SVG_NS, 'polygon');
  this._groupEl.replaceChild(this._el, polyline);

  this._coords.isOpened = false;
  this.redraw().deselect();
};

/**
 * Set attributes for svg-elements of area by new parameters
 *
 * @param coords {Object} - Object with coords of this area, with field 'points'
 * @returns {Polygon} - this area
 */
setSVGCoords = (coords) => {
  var polygonPointsAttrValue = coords.points.reduce(function(previousValue, currentItem) {
    return previousValue + currentItem.x + ' ' + currentItem.y + ' ';
  }, '');

  this._el.setAttribute('points', polygonPointsAttrValue);
  utils.foreach(this._helpers.points, function(helper, i) {
    helper.setCoords(coords.points[i].x, coords.points[i].y);
  });

  return this;
};

/**
 * Set coords for this area
 *
 * @param coords {coords}
 * @returns {Polygon} - this area
 */
setCoords = (coords) => {
  this._coords.points = coords.points;

  return this;
};

/**
 * Adds new point to polygon (and new helper too)
 *
 * @param x {number} - x-coordinate of new point
 * @param y {number} - y-coordinate of new point
 * @returns {Polygon} - this area
 */
addPoint = (x, y) => {
  if (!this._coords.isOpened) {
    throw new Error('This polygon is closed!');
  }

  var helper = new Helper(this._groupEl, x, y, 'movePoint');
  helper.setId(this._helpers.points.length);

  this._helpers.points.push(helper);
  this._coords.points.push({
    x: x,
    y: y
  });
  this.redraw();

  return this;
};

/**
 * Calculates new coordinates in process of drawing
 *
 * @param x {number}
 * @param y {number}
 * @param isRightAngle {boolean}
 * @returns {Object} - calculated coordinates
 */
dynamicDraw = (x, y, isRightAngle) => {
  var temp_coords = {
    points: [].concat(this._coords.points)
  };

  if (isRightAngle) {
    var rightPointCoords = Polygon.getRightAngleLineLastPointCoords(
      this._coords, {
        x: x,
        y: y
      }
    );
    x = rightPointCoords.x;
    y = rightPointCoords.y;
  }

  temp_coords.points.push({
    x: x,
    y: y
  });

  this.redraw(temp_coords);

  return temp_coords;
};

/**
 * Handler for drawing process (by mousemove)
 * It includes only redrawing area by new coords
 * (this coordinates doesn't save as own area coords)
 *
 * @params e {MouseEvent} - mousemove event
 */
onProcessDrawing = (e) => {
  var coords = utils.getRightCoords(this.app.appState, e.pageX, e.pageY);

  this.dynamicDraw(coords.x, coords.y, e.shiftKey);
};

/**
 * Handler for polygon pointer adding (by click on drawing canvas)
 * It includes redrawing area with this new point
 * and saving this point to list of polygon points
 *
 * @params e {MouseEvent} - click event
 */
onAddPointDrawing = (e) => {
  var newPointCoords = utils.getRightCoords(this.app.appState, e.pageX, e.pageY);

  if (e.shiftKey) {
    newPointCoords = Polygon.getRightAngleLineLastPointCoords(this._coords, newPointCoords);
  }

  this.addPoint(newPointCoords.x, newPointCoords.y);
};

onAddPointDrawing = (e, state, overlay) => {
  var newPointCoords = {
    x: (e.pageX-state.offset.x)/overlay.scale,
    y: (e.pageY-state.offset.y)/overlay.scale
  };

  if (e.shiftKey) {
    newPointCoords = Polygon.getRightAngleLineLastPointCoords(this._coords, newPointCoords);
  }

  this.addPoint(newPointCoords.x, newPointCoords.y);
};

/**
 * Handler for drawing stoping (by click on first helper or press ENTER key)
 * It includes redrawing area by new coords, closing this polygon
 * and saving this coords as own area coords
 *
 * @params e {KeyboardEvent|MouseEvent} - click or keydown event
 */
onStopDrawing = (e) => {
  if (e.type === 'click' || (e.type === 'keydown' && e.keyCode === 13)) {
    if (Polygon.testCoords(this._coords)) {
      this.close();

      this.app.removeAllEvents()
        .setIsDraw(false)
        .resetNewArea();
    }
  }
  e.stopPropagation();
};

/**
 * Changes area parameters by editing type and offsets
 *
 * @param {string} editingType - A type of editing
 * @returns {Object} - Object with changed parameters of area
 */
edit = (editingType, dx, dy) => {
  var tempParams = Object.create(this._coords);

  switch (editingType) {
    case 'move':
      for (var i = 0, c = tempParams.points.length; i < c; i++) {
        tempParams.points[i].x += dx;
        tempParams.points[i].y += dy;
      }
      break;

    case 'movePoint':
      tempParams.points[this.selected_point].x += dx;
      tempParams.points[this.selected_point].y += dy;
      break;

      default: break; // fake default case for lint complian
  }

  return tempParams;
};

/**
 * Handler for editing process (by mousemove)
 * It includes only redrawing area by new coords
 * (this coords doesn't save as area coords)
 *
 * @params e {MouseEvent} - mousemove event   Polygon
 */
/*onProcessEditing = (e) => {
  var editType = this.app.getEditType();

  this.redraw(
    this.edit(
      editType,
      e.pageX - this.editingStartPoint.x,
      e.pageY - this.editingStartPoint.y
    )
  );

  this.editingStartPoint.x = e.pageX;
  this.editingStartPoint.y = e.pageY;
};*/
dynamicEdit = (tempCoords) => {
  if (tempCoords.radius < 0) {
    tempCoords.radius = Math.abs(tempCoords.radius);
  }

  this.setSVGCoords(tempCoords);

  return tempCoords;
};

onProcessEditing = (area, scale, e) => {
  let event = e;

  this.redraw(
    this.edit(this.info.editType,
      (event.pageX - this.editingStartPoint.x)/scale,
      (event.pageY - this.editingStartPoint.y)/scale
    )
  );

  this.editingStartPoint.x = e.pageX;
  this.editingStartPoint.y = e.pageY;
};

/**
 * Handler for editing stoping (by mouseup on drawing canvas)
 * It includes redrawing area by new coords and saving this new coords
 * as own area coords
 *
 * @params e {MouseEvent} - click or keydown event
 */
/*onStopEditing = (e) => {
  var editType = this.app.getEditType();

  this.setCoords(
    this.edit(
      editType,
      e.pageX - this.editingStartPoint.x,
      e.pageY - this.editingStartPoint.y
    )
  ).redraw();

  this.app.removeAllEvents();
};*/

onStopEditing = (scale, e) => {
  this.setCoords(
    this.edit(
      this.info.editType,
        (e.pageX - this.editingStartPoint.x)/scale,
        (e.pageY - this.editingStartPoint.y)/scale
    )
  ).redraw();
};

/**
 * Returns string-representation of polygon
 *
 * @returns {string}
 */
toString = () => {
  return 'Polygon {points: [' +
    this._coords.points.map(function(item) {
      return '[' + item.x + ', ' + item.y + ']'
    }).join(', ') + ']}';
}

/**
 * Returns html-string of area html element with params of this polygon
 *
 * @returns {string}
 */
toHTMLMapElementString = () => {
  var str = this._coords.points.map(function(item) {
    return item.x + ', ' + item.y;
  }).join(', ');

  return '<area shape="poly" coords="' +
    str +
    '"' +
    (this._attributes.href ? ' href="' + this._attributes.href + '"' : '') +
    (this._attributes.alt ? ' alt="' + this._attributes.alt + '"' : '') +
    (this._attributes.title ? ' title="' + this._attributes.title + '"' : '') +
    ' />';
};

toJSONElementString = () => {
  var str = this._coords.points.map(function(item) {
    return item.x + ', ' + item.y;
  }).join(', ');

  return {
    shape: "poly",
    coords: str,
    user_data: {
    }
  }
};

/**
 * Returns coords for area attributes form
 *
 * @returns {Object} - coordinates of point
 */
getCoordsForDisplayingInfo = () => {
  return {
    x: this._coords.points[0].x,
    y: this._coords.points[0].y
  };
};

/**
 * Returns true if coords is valid for polygons and false otherwise
 *
 * @static
 * @param coords {Object} - object with coords for new polygon
 * @returns {boolean}
 */
static testCoords = (coords) => {
  return coords.points.length >= 10;
};

/**
 * Returns true if html coords array is valid for polygons and false otherwise
 *
 * @static
 * @param coords {Array} - coords for new polygon as array [x1, y1, x2, y2, x3, y3, ...]
 * @returns {boolean}
 */
testHTMLCoords = (coords) => {
  return coords.length >= 6 && coords.length % 2 === 0;
};

/**
 * Returns polygon coords object from html array
 *
 * @param htmlCoordsArray {Array}
 * @returns {Object} - object with calculated points
 */
getCoordsFromHTMLArray = (htmlCoordsArray) => {
  if (!Polygon.testHTMLCoords(htmlCoordsArray)) {
    throw new Error('This html-coordinates is not valid for polygon');
  }

  var points = [];
  for (var i = 0, c = htmlCoordsArray.length / 2; i < c; i++) {
    points.push({
      x: htmlCoordsArray[2 * i],
      y: htmlCoordsArray[2 * i + 1]
    });
  }

  return {
    points: points
  };
};

/**
 * Returns coords of new point with right angle (or 45 deg) for last line
 * This method recalculates coordinates of new point for
 * last line with (0 | 45 | 90 | 135 | 180 | 225 | 270 | 315) deg
 * For example,
 * for (0 < deg < 23) -> 0 deg
 * for (23 < deg < 67) -> 45 deg
 * for (67 < deg < 90) -> 90 deg etc.
 *
 *         0
 *    315\ | /45
 *        \|/
 * 270 --------- 90
 *        /|\
 *    225/ | \135
 *        180
 *
 * @static
 * @param originalCoords {Object} - Coordinates of this area (without new point)
 * @param newPointCoords {Object} - Coordinates of new point
 * @returns {Object} - Right coordinates for last point
 */
getRightAngleLineLastPointCoords = (originalCoords, newPointCoords) => {
  var TANGENS = {
      DEG_22: 0.414,
      DEG_67: 2.414
    },
    lastPointIndex = originalCoords.points.length - 1,
    lastPoint = originalCoords.points[lastPointIndex],
    dx = newPointCoords.x - lastPoint.x,
    dy = -(newPointCoords.y - lastPoint.y),
    tan = dy / dx,
    x = newPointCoords.x,
    y = newPointCoords.y;

  if (dx > 0 && dy > 0) {
    if (tan > TANGENS.DEG_67) {
      x = lastPoint.x;
    } else if (tan < TANGENS.DEG_22) {
      y = lastPoint.y;
    } else {
      Math.abs(dx) > Math.abs(dy) ?
        (x = lastPoint.x + dy) : (y = lastPoint.y - dx);
    }
  } else if (dx < 0 && dy > 0) {
    if (tan < -TANGENS.DEG_67) {
      x = lastPoint.x;
    } else if (tan > -TANGENS.DEG_22) {
      y = lastPoint.y;
    } else {
      Math.abs(dx) > Math.abs(dy) ?
        (x = lastPoint.x - dy) : (y = lastPoint.y + dx);
    }
  } else if (dx < 0 && dy < 0) {
    if (tan > TANGENS.DEG_67) {
      x = lastPoint.x;
    } else if (tan < TANGENS.DEG_22) {
      y = lastPoint.y;
    } else {
      Math.abs(dx) > Math.abs(dy) ?
        (x = lastPoint.x + dy) : (y = lastPoint.y - dx);
    }
  } else if (dx > 0 && dy < 0) {
    if (tan < -TANGENS.DEG_67) {
      x = lastPoint.x;
    } else if (tan > -TANGENS.DEG_22) {
      y = lastPoint.y;
    } else {
      Math.abs(dx) > Math.abs(dy) ?
        (x = lastPoint.x - dy) : (y = lastPoint.y + dx);
    }
  }

  return {
    x: x,
    y: y
  };
};

/**
 * Creates new polygon and add drawing handlers for DOM-elements
 *
 * @static
 * @param firstPointCoords {Object} - coords for first point of polyline
 * @returns {Polygon} - created polygon
 */
static createAndStartDrawing = (firstPointCoords, info, onClickListener) => {
  var newArea = new Polygon({
    points: [firstPointCoords],
    isOpened: true
  }, undefined, info, onClickListener);


/*  app.addEvent(app.domElements.container, 'mousemove', newArea.onProcessDrawing)
    .addEvent(app.domElements.container, 'click', newArea.onAddPointDrawing)
    .addEvent(document, 'keydown', newArea.onStopDrawing)
    .addEvent(newArea._helpers.points[0]._el, 'click', newArea.onStopDrawing);
*/
  return newArea;
}
}

export default Polygon;
