import Area from './Area.js';
import utils from '../utils.js';
import Helper from './Helper.js';
/**
 * The constructor for circles
 *
 *     ------
 *  /         \
 * |  (x, y)<->| radius
 *  \         /
 *    ------
 *
 * @constructor
 * @param coords {Object} - object with parameters of new area (cx, cy, radius)
 *                          if some parameter is undefined, it will set 0
 * @param attributes {Object} [attributes=undefined] - attributes for area (e.g. href, title)
 */
class Circle extends Area {

  constructor(coords, attributes, info, events) {
    super('circle', coords, attributes, info, events);

    /**
     * @namespace
     * @property {number} cx - Distance from the left edge of the image to the center of the circle
     * @property {number} cy - Distance from the top edge of the image to the center of the circle
     * @property {number} radius - Radius of the circle
     */
    this._coords = {
      cx: coords.cx || 0,
      cy: coords.cy || 0,
      radius: coords.radius || 0
    };

    this._el = document.createElementNS(Area.SVG_NS, 'circle');
    this._groupEl.appendChild(this._el);

    this._helpers = {
      center: new Helper(this._groupEl, coords.cx, coords.cy, 'move'),
      top: new Helper(this._groupEl, coords.cx, coords.cy, 'editTop'),
      bottom: new Helper(this._groupEl, coords.cx, coords.cy, 'editBottom'),
      left: new Helper(this._groupEl, coords.cx, coords.cy, 'editLeft'),
      right: new Helper(this._groupEl, coords.cx, coords.cy, 'editRight')
    };

    this.redraw();
  }

/**
 * Set attributes for svg-elements of area by new parameters
 *
 * @param coords {Object} - Object with coords of this area (cx, cy, radius)
 * @returns {Circle} - this area
 */
setSVGCoords = (coords) => {
  this._el.setAttribute('cx', coords.cx);
  this._el.setAttribute('cy', coords.cy);
  this._el.setAttribute('r', coords.radius);

  this._helpers.center.setCoords(coords.cx, coords.cy);
  this._helpers.top.setCoords(coords.cx, coords.cy - coords.radius);
  this._helpers.right.setCoords(coords.cx + coords.radius, coords.cy);
  this._helpers.bottom.setCoords(coords.cx, coords.cy + coords.radius);
  this._helpers.left.setCoords(coords.cx - coords.radius, coords.cy);

  return this;
};

showHelpers = () => {
  this._helpers.center.show();
  this._helpers.top.show();
  this._helpers.right.show();
  this._helpers.bottom.show();
  this._helpers.left.show();
}

hideHelpers = () => {
  this._helpers.center.hide();
  this._helpers.top.hide();
  this._helpers.right.hide();
  this._helpers.bottom.hide();
  this._helpers.left.hide();
}

/**
 * Set coords for this area
 *
 * @param coords {Object} - coordinates for thia area
 * @returns {Circle} - this area
 */
setCoords = (coords) => {
  this._coords.cx = coords.cx;
  this._coords.cy = coords.cy;
  this._coords.radius = coords.radius;

  return this;
};

/**
 * Calculates new coordinates in process of drawing
 * (for circle normalizeCoords() don't needed because
 * radius are always positive)
 *
 * @param x {number} - x-coordinate
 * @param y {number} - y-coordinate
 * @returns {Object} - calculated coordinates
 */
dynamicDraw = (x, y) => {
  var radius = Math.round(
      Math.sqrt(
        Math.pow(this._coords.cx - x, 2) +
        Math.pow(this._coords.cy - y, 2)
      )
    ),
    newCoords = {
      cx: this._coords.cx,
      cy: this._coords.cy,
      radius: radius
    };

  this.redraw(newCoords);

  return newCoords;
};

/**
 * Handler for drawing process (by mousemove)
 * It includes only redrawing area by new coords
 * (this coords doesn't save as own area coords)
 *
 * @params e {MouseEvent} - mousemove event
 */
onProcessDrawing = (e) => {
  var coords = utils.getRightCoords(this.app.appState, e.pageX, e.pageY);

  this.dynamicDraw(coords.x, coords.y);
};

/**
 * Handler for drawing stoping (by second click)
 * It includes redrawing area by new coords
 * and saving this coords as own area coords
 *
 * @params e {MouseEvent} - click event
 */
onStopDrawing = (e) => {
  var coords = utils.getRightCoords(this.app.appState, e.pageX, e.pageY);

  this.setCoords(this.dynamicDraw(coords.x, coords.y)).deselect();

  this.app.removeAllEvents()
    .setIsDraw(false)
    .resetNewArea();
};

/**
 * Changes area parameters by editing type and offsets
 *
 * @param {string} editingType - A type of editing
 * @returns {Object} - Object with changed parameters of area
 */
edit = (editingType, dx, dy) => {
  var tempParams = Object.create(this._coords);

  switch (editingType) {
    case 'move':
      tempParams.cx += dx;
      tempParams.cy += dy;
      break;

    case 'editTop':
      tempParams.radius -= dy;
      break;

    case 'editBottom':
      tempParams.radius += dy;
      break;

    case 'editLeft':
      tempParams.radius -= dx;
      break;

    case 'editRight':
      tempParams.radius += dx;
      break;

      default: break; // fake default case for lint complian
  }

  return tempParams;
};

/**
 * Calculates new coordinates in process of editing
 *
 * @param tempCoords {Object} - area coords
 * @returns {Object} - calculated coordinates
 */
dynamicEdit = (tempCoords) => {
  if (tempCoords.radius < 0) {
    tempCoords.radius = Math.abs(tempCoords.radius);
  }

  this.setSVGCoords(tempCoords);

  return tempCoords;
};

/**
 * Handler for editing process (by mousemove)
 * It includes only redrawing area by new coords
 * (this coords doesn't save as own area coords)
 *
 * @params e {MouseEvent} - mousemove event Cicle
 */
onProcessEditing = (area, scale, e) => {
  let event = e;

  return this.dynamicEdit(
    this.edit(this.info.editType,
      (event.pageX - this.editingStartPoint.x)/scale,
      (event.pageY - this.editingStartPoint.y)/scale
    )
  );
};
/*
onProcessEditing = (area, scale, e) => {
  let event = e;
  let target = event.taget;

  return area.dynamicEdit(
    area.edit(area.info.editType,
      (event.pageX - area.editingStartPoint.x) /scale,
      (event.pageY - this.editingStartPoint.y) /scale
  );
};*/

/**
 * Handler for editing stoping (by mouseup)
 * It includes redrawing area by new coords
 * and saving this coords as own area coords
 *
 * @params e {MouseEvent} - mouseup event Circle
 */
onStopEditing = (area, scale, e) => {
  let coords = this.onProcessEditing(this, scale, e);
  console.log('ON STOP EDITING: ' + JSON.stringify(coords));
  this.setCoords(coords);
};

/**
 * Returns string-representation of circle
 *
 * @returns {string}
 */
toString = () => {
  return 'Circle {cx: ' + this._coords.cx +
    ', cy: ' + this._coords.cy +
    ', radius: ' + this._coords.radius + '}';
}

/**
 * Returns html-string of area html element with params of this circle
 *
 * @returns {string}
 */
toHTMLMapElementString = () => {
  return '<area shape="circle" coords="' +
    this._coords.cx + ', ' +
    this._coords.cy + ', ' +
    this._coords.radius +
    '"' +
    (this._attributes.href ? ' href="' + this._attributes.href + '"' : '') +
    (this._attributes.alt ? ' alt="' + this._attributes.alt + '"' : '') +
    (this._attributes.title ? ' title="' + this._attributes.title + '"' : '') +
    ' />';
};

toJSONElementString = () => {
  return {
    shape: "circle",
    coords: this._coords.cx + ', ' + this._coords.cy + ', ' + this._coords.radius,
    user_data: {
        ref: null,
        price: 0,
        title: "",
        comments: ""
    }
  }
};

/**
 * Returns coords for area attributes form
 *
 * @returns {Object} - coordinates of point
 */
getCoordsForDisplayingInfo = () => {
  return {
    x: this._coords.cx,
    y: this._coords.cy
  };
};

/**
 * Returns true if coords is valid for circles and false otherwise
 *
 * @static
 * @param coords {Object} - object width coords for new circle
 * @return {boolean}
 */
testCoords = (coords) => {
  return coords.cx && coords.cy && coords.radius;
};

/**
 * Returns true if html coords array is valid for circles and false otherwise
 *
 * @static
 * @param coords {Array} - coords for new circle as array
 * @return {boolean}
 */
testHTMLCoords = (coords) => {
  return coords.length === 3;
};

/**
 * Returns circle coords object from html array
 *
 * @param htmlCoordsArray {Array}
 * @returns {Object}
 */
getCoordsFromHTMLArray = (htmlCoordsArray) => {
  if (!Circle.testHTMLCoords(htmlCoordsArray)) {
    throw new Error('This html-coordinates is not valid for circle');
  }

  return {
    cx: htmlCoordsArray[0],
    cy: htmlCoordsArray[1],
    radius: htmlCoordsArray[2]
  };
};

/**
 * Creates new circle and adds drawing handlers for DOM-elements
 *
 * @static
 * @param firstPointCoords {Object}
 * @returns {Circle}
 */
static createAndStartDrawing = (firstPointCoords, info, events) => {
  var newArea = new Circle({
    cx: firstPointCoords.x,
    cy: firstPointCoords.y,
    radius: 0
  }, undefined, info, events);

  return newArea;
};
}

export default Circle;
