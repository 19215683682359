import React, { useState, useRef } from "react";
import {
  Box,
  IconButton,
  TextField,
  Typography,
  Zoom
} from "@material-ui/core";

import {
  ArrowForwardIos as ArrowForwardIosIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Check as CheckIcon,
} from '@material-ui/icons'

import useStyles from './styles';
import Config from 'config';

import { useSnackbar } from 'notistack';

import Autocomplete from '@material-ui/lab/Autocomplete';
import CompanyBrandSelector from "components/spclick/CompanyBrandSelector/CompanyBrandSelector";
import Classification from 'apiclient/model/Classification'
import { errorHandler } from "utils/fetchutils";
import { useIsEditingSpare } from "context/SpareContext";
import classNames from "classnames";

const Level = (props) => {
  const company = "";

  const [level, setLevel] = useState(props.level);
  const classes = useStyles();
  const isEditingSpare = useIsEditingSpare();

  const itemsListRef = useRef([]);
  const [searchItems, setSearchItems] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const handleSearchInput = async (event) => {
    let searchTxt = event.target.value;
    if (itemsListRef.current.length === 0) {
      const resp = await fetch(Config.CLASSIFICATIONSAPI_URL 
        + "?path=" + company + (level.path && level.path.length > 0 ? "," + level.path : "")
        + (level.idx === 0 ? "&wo=1" : ""), {
        credentials: 'include', // fetch won't send cookies unless you set credentials
      });

      if (resp.ok) {
        const data = await resp.json();
        itemsListRef.current = data;
        setSearchItems(data.filter(l => (l.name.toLowerCase().startsWith(searchTxt.toLowerCase()))).map((l) => (l.name)));
      } else if (resp.status === 401) {
        enqueueSnackbar('Operación no autorizada!', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      } else {
        enqueueSnackbar('No se ha podido realizar la operación!', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    } else {
      setSearchItems(itemsListRef.current.filter(l => (l.name.toLowerCase().startsWith(searchTxt.toLowerCase()))).map((l) => (l.name)));
    }
    if (!level.onlySelect) {
      setLevel({
        ...level,
        name: searchTxt,
        saveButton: true,
      });
    }
  }

  const handleSearchSelection = (event, val) => {
    setLevel({
      ...level,
      name: val,
      saveButton: true,
    });
  }

  const onSaveHandler = (e) => {
    props.saveHandler(level);
  }

  return (
    <div className={classNames(classes.levelContainer, {[classes.levelContainerExpanded]: isEditingSpare})}>
      {isEditingSpare &&
        <div className={classes.levelContainerExpanded}>
          <Autocomplete
            freeSolo
            value={level.name}
            id="free-solo-2-demo"
            disableClearable
            classes={{
              root: classes.autocompleteRoot,
              input: classes.autocompleteInputRoot,
              option: classes.option,
              listbox: classes.listbox,
            }}
            options={searchItems.map((option) => option)}
            getOptionLabel={(option) => option}
            onChange={handleSearchSelection}
            renderInput={(params) => (
              <TextField
                {...params}
                classes={{
                  root: classes.inputRoot,
                }}
                style={{ paddingTop: 8 }}
                variant="filled"
                margin="none"
                size="small"
                fullWidth
                InputProps={{
                  ...params.InputProps, type: 'search',
                  classes: {
                    underline: classes.underline,
                  },
                }}
                onChange={handleSearchInput}
              />
            )} />

          <Box className={classes.levelIconsContainer}>

            <IconButton aria-label="delete" size="small"
              onClick={(e) => props.removeHandler(level.idx)}>
              <DeleteIcon fontSize="small" />
            </IconButton>

            {(level.saveButton) &&

              <Zoom in={level.saveButton} timeout={1000}>
                <IconButton aria-label="save" size="small"
                  className={classes.miniButtonSave}
                  onClick={(e) => onSaveHandler(e)}>
                  <CheckIcon fontSize="small"
                  />
                </IconButton>
              </Zoom>
            }
            {(!level.saveButton) &&
              <IconButton aria-label="add" size="small"
                onClick={(e) => props.addHandler(level.idx)}>
                <AddIcon fontSize="small" />
              </IconButton>
            }
          </Box>
        </div>
      }
      {!isEditingSpare &&
        <div className={classes.textOnlySelector}>
          <ArrowForwardIosIcon className={classes.levelSelectorSeparator}/>
          <Typography>{level.name}</Typography>
        </div>
      }
    </div>
  );
}

export default function LevelSelector({ spareId, companyId, brandId, levelsArr, onChangeCallBack }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [levels, setLevels] = useState(levelsArr && levelsArr.map((val, idx) => {
    return ({
      idx: idx,
      name: val,
      saveButton: false,
      path: levelsArr.slice(0, idx),
      onlySelect: (idx === 0),
    })
  }));


  /*
    Remove one category from spare clasification
  */
  const onRemoveHandler = (idx) => {
    let lvs = levels.filter((l) => (l.idx !== idx)).map((l, idx) => {
      l.idx = idx;
      return l;
    });
    setLevels(lvs);
    onChangeCallBack(lvs.map((l) => (l.name)));
  }

  const onAddHandler = (idx) => {
    let lvs
    if (idx === 0) {
      lvs = [{
        idx: 0,
        name: '',
        saveButton: true,
        path: ''
      }, ...levels];
    } else if (idx < (levels.length - 1)) {
      lvs = [...levels.slice(0, idx + 1), {
        idx: idx + 1,
        name: '',
        saveButton: true,
        path: levels[idx].path.concat([levels[idx].name])
      },
      ...levels.slice(idx + 1).map(
        (level) => ({ ...level, idx: level.idx + 1 })
      )];
    } else {
      lvs = [...levels, {
        idx: levels.length,
        name: '',
        saveButton: true,
        path: levels[idx].path.concat([levels[idx].name])
      }];
    }

    setLevels(lvs);
  }

  const onSaveHandler = async (level) => {
    let lvs;

    levels[level.idx] = level;

    const success = await onChangeCallBack(levels.filter((l) => (l.name !== '')).map((l) => (l.name)));

    if (success) {
      level.saveButton = false;

      lvs = levels.map((l, idx) => {
        l.saveButton = false;
        return l;
      });
      setLevels(lvs);
    }
  }

  const updateSpareBrand = (spareId, companyId, brandId) => {
    fetch(Config.PUT_CLASSIFICATIONSAPI_URL.replace("$$spareId", spareId), {
      method: 'PUT',
      credentials: 'include',
      headers: {
        "Content-type": "application/json"
      },
      body: JSON.stringify(Classification.constructFromObject({
        company_id: companyId,
        brand_id: brandId,
      }))
    }).then((response) => {
      if (response.ok) {
        enqueueSnackbar('Cambios guardados correctamente!', {
          variant: 'success',
          autoHideDuration: 2000,
        });
      } else {
        errorHandler(response.status, "No se ha podido actualizar el despiece", enqueueSnackbar);
      }
    })
  }

  return (
    <form noValidate autoComplete="off">
      <Box className={classes.container}>

        { // Brand selector

          spareId &&
          <CompanyBrandSelector
            initialCompanyId={companyId}
            initialBrandId={brandId}
            onSaveHandler={(companyId, brandId) => {
              updateSpareBrand(spareId, companyId, brandId)
            }}
            addHandler={() => onAddHandler(0)} />
        }
        { // Path selectors

          levels && levels.map((level, idx) => (

            <Level key={idx + "_" + (new Date().getTime())}
              level={level}
              removeHandler={onRemoveHandler}
              addHandler={onAddHandler}
              saveHandler={onSaveHandler}
            />
          )
          )
        }
      </Box>
    </form>
  );
}
