import React, { forwardRef, useState, useRef } from 'react';
import useStyles from "./styles";
import clsx from 'clsx';

import {
    Box,
    TextField,
} from "@material-ui/core";
import {
    Add,
    ArrowDownward,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    Delete,
    DeleteOutline,
    Edit,
    FiberManualRecord as FiberManualRecordIcon,
    FilterList,
    FirstPage,
    Inbox as InboxIcon,
    LastPage,
    Remove,
    SaveAlt,
    Search,
    ViewColumn
} from "@material-ui/icons";
import MaterialTable, {
    MTableBodyRow,
    MTableToolbar,
} from "material-table";
import {
    checkAuth,
} from "components/spclick/Can";
import {
    useLang,
    useRoles,
} from "context/UserContext";

import Config from 'config';
import {
    errorHandler,
    showNetworkErrorMsg
} from 'utils/fetchutils';
import {
    parseExcel2GenerateTable
} from 'utils/excelUtil';
import { useSnackbar } from 'notistack';
import ContentWrapper from "components/spclick/ContentWrapper";
import MyBreadCrumb from 'components/spclick/MyBreadCrumb';
import PageTitle from 'components/PageTitle/PageTitle';
import AreYouSureDlg from 'components/spclick/AreYouSureDlg';
import { NumberParser } from 'utils/excelUtil';
import { useTheme } from '@material-ui/styles';
import LanguageSelect from 'components/spclick/LanguageSelect';
import ExcelImport from 'components/spclick/ExcelImport';

const Catalog = (props) => {
    const { match: { params } } = props;
    const cid = params.cid;
    const cname = params.cname;

    const DEFAULT_PAGE_SIZE = 10;

    const classes = useStyles();
    const theme = useTheme();
    const lang = useLang();
    const roles = useRoles();
    const { enqueueSnackbar } = useSnackbar();

    const [tableLang, setTableLang] = useState(lang);
    const numberParser = new NumberParser(tableLang || lang);
    const numberFormatter = new Intl.NumberFormat(tableLang || lang);

    const [refs, setRefs] = useState([]);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [filterquery, setFilterquery] = useState();
    const [sortBy, setSortBy] = useState();

    const [rows2Delete, setRows2Delete] = useState(null);
    const [isEditable, setIsEditable] = useState(true);

    const tableIcons = {
        Add: forwardRef((props, ref) => <Add {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    const catalogFieldsPositionsArr = ["ref", "description", "comments", "extra", "price"];

    const onPriceChange = (event, muiOnChange) => {
        const regex = /^[0-9]*(\.|,)?[0-9]*$/i;
        if (event.target.value === '' || regex.test(event.target.value)) {
            muiOnChange(event.target.value);
        } else {
            console.log("DONT MACH");
        }
    }

    const check = (val) => {
        if (typeof val == 'number') {
            val = numberFormatter.format(val);
        }

        return val ? val : "";
    }

    const columns = [{
        field: "ref",
        title: "Ref.",
        defaultSort: "asc"
    }, {
        field: "cols[0]",
        title: "Descripción",
        defaultSort: "asc"
    }, {
        field: "cols[1]",
        title: "Comentarios",
        defaultSort: "asc"
    }, {
        field: "cols[2]",
        title: "Extra",
        defaultSort: "asc"
    }, {
        field: "price",
        title: "Precio",
        defaultSort: "asc",
        editComponent: props => <TextField value={check(props.value)} onChange={(event) => onPriceChange(event, props.onChange)} />,
        render: rowData => rowData.price ? numberFormatter.format(rowData.price) : ""
    }];

    const options = {
        filterType: 'checkbox',
        responsive: "simple",
        actionsColumnIndex: -1,
        paging: true,
        addRowPosition: 'first',
        doubleHorizontalScroll: true,
        pageSize: pageSize,
        pageSizeOptions: [5, 10, 25, 50],
        selection: true,
    };

    const loadCatalogRefs = (cid, page, pageSize, filterquery, sortBy, lang) => {

        const url = encodeURI(Config.CATALOGSAPI_GET_REFS_URL.replace("$cid$", cid) + "?"
            + (page ? "page=" + page : "page=0")
            + (pageSize ? "&page_size=" + pageSize : "")
            + (filterquery ? "&filterquery=" + filterquery : "")
            + (sortBy ? "&sort_by=" + sortBy : "")
            + (lang ? "&l=" + lang : ""));

        return fetch(url, {
            credentials: "include"
        })
    }

    const handleLangSelectionChange = (event) => {
        const selectedLang = event.target.value;
        setTableLang(selectedLang);
        tableRef.current.onQueryChange();
    }

    const deleteRefs = (rows2Delete) => {
        let url = Config.CATALOGSAPI_URL.replace("$cid$", cid) + "?refs=";

        url = url + rows2Delete.map(row => row.ref);

        return new Promise((resolve, reject) => {
            fetch(encodeURI(url), {
                method: "DELETE",
                credentials: "include"
            }).then(response => {
                if (response.ok) {
                    setRows2Delete(null);
                    enqueueSnackbar('Referencias eliminadas correctamente!', {
                        variant: 'success',
                        autoHideDuration: 2000,
                    });
                    tableRef.current.onQueryChange();
                    resolve();
                } else {
                    errorHandler(response.status, "Error eliminando referencias", enqueueSnackbar);
                    reject();
                }
            }).catch(error => {
                showNetworkErrorMsg(error);
                reject();
            })
        })
    }
    const generateTranslation = (row) => {
        const ref = {
            "ref": row.ref,
            "translations": {
            }
        }

        if (row.price) {
            ref.price = row.price;
        }

        if (row.cols) {
            if (Array.isArray(row.cols)) {
                ref.translations[tableLang] = [];
                for (let i = 0; i < Config.SpareRefColsLeng; i++) {
                    ref.translations[tableLang].push(row.cols[i] == null ? "" : row.cols[i]);
                }
            } else {  // Is a dict.
                ref.translations[tableLang] = [];
                for (let i = 0; i < Config.SpareRefColsLeng; i++) {
                    ref.translations[tableLang].push(row.cols.hasOwnProperty(i.toString()) ? row.cols[i.toString()] : "");
                }
            }
        } else {
            ref.translations[tableLang] = ["", "", ""];
        }
        return ref;
    }

    const updateRef = (row) => {
        return saveRefs([generateTranslation(row)]);
    }

    const saveRefs = (refs) => {
        let url = Config.CATALOGSAPI_POST_REFS_URL.replace("$cid$", cid);

        return new Promise((resolve, reject) => {
            fetch(encodeURI(url), {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-type": "application/json",
                },
                body: JSON.stringify(refs)
            }).then(response => {
                if (response.ok) {
                    setRows2Delete(null);
                    enqueueSnackbar('Referencia/s actualizadas correctamente!', {
                        variant: 'success',
                        autoHideDuration: 2000,
                    });
                    tableRef.current.onQueryChange();
                    resolve();
                } else {
                    errorHandler(response.status, "Error actualizando la/s referencias", enqueueSnackbar);
                    reject();
                }
            }).catch(error => {
                showNetworkErrorMsg(error);
                reject();
            })
        })
    }

    const deleteMultipleRefs = () => {
        deleteRefs(rows2Delete)
            .then(() => setIsEditable(true))
            .catch(() => setIsEditable(true));
    }

    const closeDeleteConfirmDialog = () => {
        setRows2Delete(null);
        setIsEditable(true);
    }

    const excelFileImportHandler = (file) => {
        parseExcel2GenerateTable(file, tableLang).then(refs => {
            saveRefs(refs).then(() => {
                tableRef.current.onQueryChange();
            })
        }, error => {
            enqueueSnackbar(error, {
                variant: 'error',
                autoHideDuration: 2000,
            });
        }).catch((error) => {
            enqueueSnackbar("Se ha producido un error. Por favor, inténtelo de nuevo más tarde", {
                variant: 'error',
                autoHideDuration: 2000,
            });
        })
    }

    const breadcrumbData = [{
        "title": "Brands",
        "url": "/app/brands"
    }, {
        "title": cname
    }];

    const tableRef = useRef();

    const MainDeleteOutline = (props) => {
        return (
            <Delete />
        )
    }

    return (
        <ContentWrapper>
            <MyBreadCrumb data={breadcrumbData} history={props.history} />
            <PageTitle title="Catálogo" />

            <Box className={classes.tableLangBox}>
                <Box className={classes.tableLangBoxLeft}>
                    <LanguageSelect
                        handleLangSelectionChange={handleLangSelectionChange}
                        initialValue={tableLang} />
                </Box>

                <Box className={classes.tableLangBoxRight}>
                    <ExcelImport
                        excelFileImportHandler={excelFileImportHandler}/>
                </Box>
            </Box>

            <MaterialTable
                className={classes.datatable}
                icons={tableIcons}
                responsive="simple"
                title={""}
                tableRef={tableRef}
                data={(query) =>
                    new Promise((resolve, reject) => {

                        loadCatalogRefs(cid,
                            query.page,
                            query.pageSize,
                            query.search,
                            query.orderBy ? catalogFieldsPositionsArr[query.orderBy.tableData.columnOrder] + (query.orderDirection !== 'asc' ? "-" : "") : "",
                            tableLang)
                            .then((response) => {
                                if (response.ok) {
                                    response.json().then(data => {
                                        setRefs(data.refs)
                                        resolve({
                                            data: data.refs,
                                            page: query.page,
                                            totalCount: data.count
                                        });
                                    });
                                } else {
                                    errorHandler(response.status, "Error leyendo el catálogo", enqueueSnackbar);
                                    reject();
                                }
                            }).catch(error => {
                                showNetworkErrorMsg(error);
                                reject();
                            })
                    })
                }
                columns={columns}
                options={options}
                color="primary"
                components={{
                    Row: props => (
                        <MTableBodyRow
                            {...props}
                            id={props.data.ref}
                            key={props.data.ref}
                            onDoubleClick={e => {
                                //props.actions[2]().onClick(e, props.data);
                            }}
                            onMouseOver={(e) => {
                            }}
                            onMouseLeave={(ev) => {
                            }}
                        />
                    ),
                    Toolbar: props => (
                        <div id="floatingDragger" className={classes.floatingDragger}>
                            <MTableToolbar {...props}
                                classes={{
                                    root: clsx(classes.muitableActionRoot)
                                }}
                            >
                            </MTableToolbar>
                        </div>
                    )
                }}
                editable={checkAuth(roles[0], "spares:edit", null) && isEditable ? {
                    isEditable: rowData => (isEditable),
                    onRowAdd: newData => {
                        if (newData.price) {
                            newData.price = numberParser.parse(newData.price);
                        }
                        return updateRef(newData);
                    },
                    onRowUpdate: (newData, oldData) => {
                        if (newData.price && typeof newData.price == 'string') {
                            newData.price = numberParser.parse(newData.price);
                        }
                        return updateRef(newData)
                    },
                    onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            deleteRefs([oldData]);
                            resolve();
                        })
                } : {}}
                actions={[{
                    tooltip: 'Eliminar las referencias seleccionadas',
                    icon: MainDeleteOutline,
                    onClick: (evt, data) => {
                        setRows2Delete(data);
                    }
                }]}
                onRowClick={(ev, rowData) => {
                    console.log('CLICK');
                }}
                onChangeRowsPerPage={(newPageSize) => {
                    setPageSize(newPageSize);
                }}
                onSelectionChange={(data) => {
                    setIsEditable(data.length === 0);
                }}
            />

            {rows2Delete &&
                <AreYouSureDlg
                    title="Eliminar referencias"
                    detail2={`Se van a eliminar ${rows2Delete.length} refs.`}
                    ok={deleteMultipleRefs}
                    cancel={closeDeleteConfirmDialog} />
            }

        </ContentWrapper>
    )
}

export default Catalog;