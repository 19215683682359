import React, {
    forwardRef, useState, useEffect, useRef
} from "react"

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Fab,
    Link,
    TextField
} from "@material-ui/core";

import {
    Typography
} from "../../components/Wrappers/Wrappers";
import { DropzoneDialog } from 'material-ui-dropzone';

import {
    Add as AddIcon,
    ArrowDownward,
    MenuBook as BookIcon,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    Delete,
    DeleteOutline,
    Edit,
    EditOutlined,
    ExpandMore as ExpandMoreIcon,
    FilterList,
    FirstPage,
    LastPage,
    People as PeopleIcon,
    Remove,
    SaveAlt,
    Search,
    Security,
    ViewColumn
} from "@material-ui/icons";

import MaterialTable, {
    MTableBodyRow,
    MTableToolbar,
} from "material-table";

import {
    checkAuth,
} from "components/spclick/Can";

import Render from 'components/spclick/Render';
import { useSnackbar } from 'notistack';

import useStyles from "./styles";
import PageTitle from "../../components/PageTitle/PageTitle";

import Config from 'config';
import { useLang, useRoles } from "context/UserContext";
import CreateCompanyDlg from 'components/spclick/CreateCompanyDlg';
import BrandAclsDialog from "components/spclick/BrandAclsDialog";
import ContentWrapper from "components/spclick/ContentWrapper";
import { useTheme } from "@material-ui/styles";
import MyBreadCrumb from 'components/spclick/MyBreadCrumb';
import { NumberParser } from 'utils/excelUtil';
import MaterialTableFix from "components/spclick/MaterialTableFix";
import CompanyItem from "components/spclick/CompanyItem";


export default function Carts(props) {
    const classes = useStyles();
    const theme = useTheme();
    const lang = useLang();

    const { enqueueSnackbar } = useSnackbar();
    const numberParser = new NumberParser(lang);

    const roles = useRoles();
    const [currentBrand, setCurrentBrand] = useState({
        name: ''
    });
    const [carts, setCarts] = useState(null);
    const [companies, setCompanies] = useState(null);
    const [showBrandLogoDlg, setShowBrandLogoDlg] = useState(false);
    const [showCreateCompanyDlg, setShowCreateCompanyDlg] = useState(false);

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddIcon {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    const mainTableOptions = {
        filterType: 'checkbox',
        responsive: "simple",
        actionsColumnIndex: -1,
        paging: false,
        addRowPosition: 'first',
        search: false,
        header: false,
        rowStyle: {
            ...theme.text.regular,
        }
    };

    const detailTableOptions = {
        filterType: 'checkbox',
        responsive: "simple",
        actionsColumnIndex: -1,
        paging: false,
        addRowPosition: 'first',
        search: false,
        header: false,
        rowStyle: {
            ...theme.text.regular,
        }
    };

    const mainTableColumns = [{
        field: 'header.out_only.company.name',
        title: 'Marca',
        searchable: true,
        render: rowData => (
            <div>
                {rowData.header.out_only && <CompanyItem company={rowData.header.out_only.company} />}
            </div>
        )
    }, {
        field: 'header.amount',
        title: 'Importe',
        editable: 'never',
        searchable: false,
    }, {
        field: 'header.last_modification_date',
        title: 'Última modificación',
        editable: 'never',
        searchable: false,
    }];

    /* const loadCompanies = () => {
         fetch(Config.BRANDSAPI_URL, {
             credentials: 'include',
             headers: {
                 "Content-type": "application/json",
             }
         }).then((response) => {
             if (response.ok) {
                 response.json().then((items) => {
                     setCompanies(items.companies);
                 })
             } else {
                 enqueueSnackbar('No se ha podido obtener el listado de marcas. Por favor, vuelva a intentarlo más tarde', {
                     variant: 'error',
                     autoHideDuration: 2000,
                 });
             }
         }).catch((e) => {
             enqueueSnackbar('Se ha producido un error. Por favor, vuelva a intentarlo más tarde', {
                 variant: 'error',
                 autoHideDuration: 2000,
             });
         });
     }*/



    const updateCompany = (company, logo) => {

        const request_body = {
            name: company.name,
            logo: logo
        }

        fetch(company._links.navigation.self, {
            method: "PUT",
            credentials: 'include',
            body: JSON.stringify(request_body),
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
            if (response.ok) {
                enqueueSnackbar('Empresa actualizado correctamente!', {
                    variant: 'success',
                    autoHideDuration: 2000,
                });
                response.json().then((newCompany) => {
                })

            } else if (response.status === 401) {
                enqueueSnackbar('Operación no autorizada!', {
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            } else if (response.status === 409) {
                enqueueSnackbar('Ese nombre de empresa ya está siendo utilizado!', {
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            } else {
                enqueueSnackbar('No se ha podido realizar la operación!', {
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            }
        }).catch((e) => {
            enqueueSnackbar('No se ha podido realizar la operación!', {
                variant: 'error',
                autoHideDuration: 2000,
            });
        });
    }

    const updateBrand = (brand, brand_name, logo) => {

        const request_body = {
            name: brand_name,
            logo: logo
        }

        fetch(brand._links.navigation.self, {
            method: "PUT",
            credentials: 'include',
            body: JSON.stringify(request_body),
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
            if (response.ok) {
                enqueueSnackbar('Marca actualizada correctamente!', {
                    variant: 'success',
                    autoHideDuration: 2000,
                });
                response.json().then((newBrand) => {
                    // Refresh current company's brands

                    newBrand.companyIndex = brand.companyIndex;

                    // Refresh current companies list
                })

            } else if (response.status === 401) {
                enqueueSnackbar('Operación no autorizada!', {
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            } else {
                enqueueSnackbar('No se ha podido realizar la operación!', {
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            }
        }).catch((e) => {
            enqueueSnackbar('No se ha podido realizar la operación!', {
                variant: 'error',
                autoHideDuration: 2000,
            });
        });
    }

    const deleteBrand = (company_name, brand) => {
        fetch(brand._links.navigation.self, {
            method: "DELETE",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
            if (response.ok) {

                // Refresh current companies list
                setCarts([...carts]);

                enqueueSnackbar('Marca eliminada correctamente!', {
                    variant: 'success',
                    autoHideDuration: 2000,
                });

            } else if (response.status === 401) {
                enqueueSnackbar('Operación no autorizada!', {
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            } else {
                enqueueSnackbar('No se ha podido realizar la operación!', {
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            }
        }).catch((e) => {
            enqueueSnackbar('No se ha podido realizar la operación!', {
                variant: 'error',
                autoHideDuration: 2000,
            });
        });
    }

    const loadCarts = () => {
        fetch(Config.CARTSAPI_GET_CARTS_URL + "?exfields=rows", {
            credentials: 'include',
            headers: {
                "Content-type": "application/json",
            }
        }).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    setCarts(data.carts);
                    setData(data.carts);
                })
            } else {
                enqueueSnackbar('No se ha podido obtener el listado de pedidos. Por favor, vuelva a intentarlo más tarde', {
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            }
        }).catch((e) => {
            enqueueSnackbar('Se ha producido un error. Por favor, vuelva a intentarlo más tarde', {
                variant: 'error',
                autoHideDuration: 2000,
            });
        });
    }

    useEffect(() => {
        loadCarts();
    }, []);

    const breadcrumbData = [{
        "title": "Brands",
        "url": "/app/brands"
    }];
    const [data, setData] = useState();

    return (
        <ContentWrapper>
            <MyBreadCrumb data={breadcrumbData} history={props.history} />

            <PageTitle title="Mis pedidos" />

            <MaterialTableFix
                className={classes.datatable}
                icons={tableIcons}
                responsive="simple"
                title={"Orders"}
                data={data}
                columns={mainTableColumns}
                options={mainTableOptions}
                color="primary"
                components={{
                    Row: props => (
                        <MTableBodyRow
                            {...props}
                            id={props.data.ref}
                            key={props.data.ref}
                            onDoubleClick={e => {

                            }}

                        />
                    ),
                }}
                actions={[]}
                editable={{
                    isEditable: rowData => true,
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            if (newData.price) {
                                newData.price = numberParser.parse(newData.price);
                            }

                            resolve();
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            const dataUpdate = [...data];
                            const index = oldData.tableData.id;
                            dataUpdate[index] = newData;

                            resolve();
                        }),
                    onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            const dataDelete = [...data];
                            const index = oldData.tableData.id;
                            dataDelete.splice(index, 1);

                            resolve();
                        })
                }}
                detailPanel={rowData => {
                    return (
                        <iframe
                            width="100%"
                            height="315"
                            src="https://www.youtube.com/embed/C0DPdy98e4c"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        />
                    )
                }}
            />
        </ContentWrapper>
    );
}
