import defaultThemeStyle from "./default";
import {
  theme,
  themeOverrides
} from "./alex";

import { createMuiTheme } from "@material-ui/core";

const typoOverrides = {
  typography: {
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.64rem",
    },
    h4: {
      fontSize: "1.5rem",
    },
    h5: {
      fontSize: "1.285rem",
    },
    h6: {
      fontSize: "1.142rem",
    },
   }
}

const defaultTheme = createMuiTheme({ ...defaultThemeStyle, ...typoOverrides});
const alexTheme = createMuiTheme({ ...theme, ...typoOverrides});

alexTheme.overrides = themeOverrides(alexTheme);

export default {
  default: defaultTheme,
  alex: alexTheme
};
