import {
    makeStyles
} from '@material-ui/styles';

export default makeStyles(theme => ({
    tableLangBox: {
        display: 'flex',
        width: '100%',
        padding: theme.spacing(.5)
    },
    tableLangBoxLeft: {
        flex: 1,
        justifyContent: 'flex-start'
    },
    tableLangBoxRight: {
        flex: 1,
        display: 'flex',
        position: 'relative',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    datatable: {
        "& .MuiCheckBox": {
            "root": {
                "color": theme.palette.text.primary + "! important"
            }
        }
    },
}));