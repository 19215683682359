import React, { forwardRef, useEffect, useState } from 'react';
import MaterialTable, {
    MTableToolbar,
} from "material-table";

import useStyles from './styles';
import clsx from 'clsx';

import {
    Add as AddIcon,
    ArrowDownward,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    Delete,
    Edit,
    FilterList,
    FirstPage,
    Home as HomeIcon,
    LastPage,
    NavigateNext as NavigateNextIcon,
    Remove,
    SaveAlt,
    Search,
    ViewColumn
} from "@material-ui/icons";
import Render from 'components/spclick/Render';
import { useSnackbar } from 'notistack';

import Config from 'config';
import MyBreadCrumb from 'components/spclick/MyBreadCrumb';
import ContentWrapper from 'components/spclick/ContentWrapper';
import theme from 'components/spclick/SpareTree/storybookjs/theme';
import { useTheme } from '@material-ui/styles';
import PageTitle from 'components/PageTitle/PageTitle';

export default function EditCompanyUsers(props) {
    const { match: { params } } = props;
    const companyName = params.companyName;

    const classes = useStyles();
    const theme = useTheme();

    const { enqueueSnackbar } = useSnackbar();

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddIcon {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    const tableOptions = {
        filterType: 'checkbox',
        responsive: "simple",
        actionsColumnIndex: -1,
        paging: false,
        addRowPosition: 'first',
        rowStyle: {
            ...theme.text.regular,
          }
    };

    const tableColumns = [{
        field: 'username',
        title: 'Nombre de usuario',
        searchable: true,
    }];

    const breadcrumbData = [{
        "title": "Brands",
        "url": "/app/brands"
    }, {
        "title": companyName
    }];

    const [users, setUsers] = useState();

    const addUser = (username) => {
        return new Promise((resolve, reject) => {
        const url = Config.BRANDSAPI_URL + "/" + companyName + "/users"
        fetch(url, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify({
                username: username,
                roles: []
            })
        }).then((response) => {
            if (response.ok) {
                enqueueSnackbar('Usuario creado correctamente', {
                    variant: 'success',
                    autoHideDuration: 2000,
                });
                response.json().then(user => {
                    setUsers([user, ...users]);
                    resolve();
                })

            } else if (response.status === 400) {
                enqueueSnackbar('El email no es válido', {
                    variant: 'error',
                    autoHideDuration: 2000,
                });
                reject();
            } else {
                enqueueSnackbar('No se ha podido realizar la operación', {
                    variant: 'error',
                    autoHideDuration: 2000,
                });
                reject();
            }
        }).catch((e) => {
            enqueueSnackbar('Se ha producido un error. Por favor, vuelva a intentarlo más tarde', {
                variant: 'error',
                autoHideDuration: 2000,
            });
            reject();
        });
        });
    }

    const updateUser = (oldusername, newusername) => {
        return new Promise((resolve, reject) => {
            const url = Config.BRANDSAPI_URL + "/" + companyName + "/users/" + oldusername
            fetch(url, {
                method: "PUT",
                credentials: "include",
                headers: {
                    "Content-type": "application/json"
                },
                body: JSON.stringify({
                    username: newusername,
                    roles: []
                })
            }).then((response) => {
                if (response.ok) {
                    enqueueSnackbar('Usuario actualizado correctamente', {
                        variant: 'success',
                        autoHideDuration: 2000,
                    });
                    resolve();
                } else {
                    enqueueSnackbar('No se ha podido realizar la operación', {
                        variant: 'error',
                        autoHideDuration: 2000,
                    });
                    reject();
                }
            }).catch((e) => {
                enqueueSnackbar('Se ha producido un error. Por favor, vuelva a intentarlo más tarde', {
                    variant: 'error',
                    autoHideDuration: 2000,
                });
                reject();
            });
        });
    }

    const deleteUser = (username) => {
        return new Promise((resolve, reject) => {
            const url = Config.BRANDSAPI_URL + "/" + companyName + "/users/" + username
            fetch(url, {
                method: "DELETE",
                credentials: "include",
            }).then((response) => {
                if (response.ok) {
                    enqueueSnackbar('Usuario eliminado correctamente', {
                        variant: 'success',
                        autoHideDuration: 2000,
                    });
                    resolve();
                } else {
                    enqueueSnackbar('No se ha podido realizar la operación', {
                        variant: 'error',
                        autoHideDuration: 2000,
                    });
                    reject();
                }
            }).catch((e) => {
                enqueueSnackbar('Se ha producido un error. Por favor, vuelva a intentarlo más tarde', {
                    variant: 'error',
                    autoHideDuration: 2000,
                });
                reject();
            });
        });
    }

    const loadUsers = (companyName) => {

        const url = Config.BRANDSAPI_URL + "/" + companyName + "/users"

        fetch(url, {
            credentials: 'include',
            headers: {
                "Content-type": "application/json",
            }
        }).then((response) => {

            if (response.ok) {
                response.json().then((json) => {
                    setUsers(json.users);
                })
            } else {
                enqueueSnackbar('No se ha podido obtener el listado de marcas. Por favor, vuelva a intentarlo más tarde', {
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            }
        });
    }

    const handleBreadcrumbClick = (event, url) => {
        event.preventDefault();

        props.history.push({
            pathname: url,
        })
    }

    useEffect(() => {

        // Load users
        loadUsers(companyName);

        return () => { }
    }, [])

    return (
        <ContentWrapper>
            <MyBreadCrumb data={breadcrumbData} history={props.history} />

            <PageTitle title="Usuarios" />
            <Render perform="companies:editusers">
                <MaterialTable
                    className={classes.datatable}
                    icons={tableIcons}
                    responsive="simple"
                    title={""}
                    data={users}
                    columns={tableColumns}
                    options={tableOptions}
                    color="primary"
                    style={{ 'width': '100%' }}
                    editable={{
                        isEditable: rowData => true,
                        onRowAdd: newData => addUser(newData.username),

                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                const dataUpdate = [...users];
                                const index = oldData.tableData.id;

                                updateUser(oldData.username, newData.username).then(() => {
                                    dataUpdate[index] = newData;
                                    setUsers(dataUpdate);
                                });
                                resolve();
                            }),
                        onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    const dataDelete = [...users];
                                    const index = oldData.tableData.id;

                                    deleteUser(oldData.username).then(() => {
                                        dataDelete.splice(index, 1);
                                        setUsers(dataDelete);
                                    })

                                    resolve();
                                }, 1);
                            })
                    }}
                    components={{
                        Toolbar: props => (
                            <MTableToolbar {...props}
                                classes={{
                                    root: clsx(classes.muitableActionRoot)
                                }}
                            >
                            </MTableToolbar>
                        )
                    }}>
                </MaterialTable>
            </Render>
        </ContentWrapper>
    )
}