import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme =>({
    container: {
        display: 'flex',
        minWidth: 100,
        maxWidth: 100,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    textInput: {
        "& .MuiInputBase-input": {
            textAlign: 'right',
            maxWidth: 32,
            fontSize: theme.palette.text.size.table,
            paddingTop: 1,
            paddingBottom: 1,
        },
    },
    plusMinusContainer: {
        display: 'flex',
        marginLeft: 4,
        flexDirection: 'column',
    },
    plusMinusButton: {
        flex: 1, 
        padding: 0,
        minWidth: 30,
        maxWidth: 40,
        maxHeight: 15
    }
})
)