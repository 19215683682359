import React, { useState, forwardRef, useCallback, useEffect, useRef } from 'react';
import useStyles from "./styles";
import {
  Box, IconButton, TableContainer, TextField, Tooltip,
} from "@material-ui/core";
import clsx from 'clsx';

import {
  AccessAlarm as AccessAlarmIcon,
  Add,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Power as PowerIcon,
  PowerOff as PowerOffIcon,
  Remove,
  SaveAlt,
  Search,
  ViewColumn
} from "@material-ui/icons";

import MaterialTable, {
  MTableBodyRow,
  MTableToolbar,
} from "material-table";

import {
  useCurrentSpare,
  useSpareDispatch,
  setRefsModified,
  useIsEditingSpare,
  updateSpareContext,
} from "context/SpareContext";

import {
  useLang,
  useRoles,
} from "context/UserContext";

import {
  checkAuth,
} from "components/spclick/Can";

import Render from 'components/spclick/Render';
import { NumberParser } from 'utils/excelUtil';

import Config from 'config';
import { errorHandler, showNetworkErrorMsg } from 'utils/fetchutils';
import { useSnackbar } from 'notistack';
import { parseExcel2GenerateTable } from 'utils/excelUtil';
import LanguageSelect from '../LanguageSelect';
import ExcelImport from '../ExcelImport';
import QuantitySelector from '../QuantitySelector/QuantitySelector';
import MaterialTableFix from '../MaterialTableFix';

export default function SpareTable(props) {
  const classes = useStyles();
  const lang = useLang();
  const { enqueueSnackbar } = useSnackbar();
  const [tableLang, setTableLang] = useState(lang);
  const refsMap = useRef();
  const [refsQuantityMap, setRefsQuantityMap] = useState();

  const numberParser = new NumberParser(tableLang || lang);

  const isEditingSpare = useIsEditingSpare();

  const areasRef = props.areasRef;
  const floating = props.floating;

  const spareDispatcher = useSpareDispatch();
  const spare = useCurrentSpare();

  // refsData is required to launch async useEffect wen calling direct /edit url 
  // If isEditingSpare change while not finished data loading from API, table 1st col. remains hidden
  const [refsData, setRefsData] = useState();
  const [data, _setData] = useState(props.refsRef.current);
  //  const [rowQuantities]

  const setData = (newData) => {
    props.refsRef.current = newData;
    _setData(newData);
    setRefsData(newData);
  }

  const tableIcons = {
    Add: forwardRef((props, ref) => <Add {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

  const numberFormatter = new Intl.NumberFormat(lang);



  const calculateColumns = (data, editing) => {
    if (data === []) {
      return [];
    }
    let cols = [{
      field: 'uuid',
      title: '',
      editable: 'never',
      hidden: !editing,
      render: rowData => (
        <Render perform="spares:edit">
          {(!rowData.area_links > 0) ? <div draggable="true"
            onDragStart={(ev) => {
              console.log('ON DRAG START');
              ev.dataTransfer.setData("text/plain", rowData.ref);
              ev.dataTransfer.dropEffect = "copy";
            }}
            onDragEnd={(ev) => {
              console.log("DRAG END!!!");
            }}
          >
            <Tooltip title="Arrastar encima de area para enlazar">
              <PowerOffIcon />
            </Tooltip>
          </div>
            :
            <div draggable="true"
              onDragStart={(ev) => {
                console.log('ON DRAG START');
                ev.dataTransfer.setData("text/plain", rowData.ref);
                ev.dataTransfer.dropEffect = "copy";
              }}
              onDragEnd={(ev) => {
                console.log("DRAG END!!!");
              }}
            >
              <PowerIcon color="primary" />
            </div>
          }
        </Render>
      )
    }, {
      field: "ref",
      title: "Part. ref",
    }, {
      field: "cols[0]",
      title: "Descripción",
      cellStyle: {
        minWidth: "25em"
      },
    }, {
      title: "Precio",
      field: "price",
      render: rowData => rowData.price && numberFormatter.format(rowData.price)
    }, {
      title: "Quantity",
      field: "quantity",
      hidden: editing,
      render: rowData => (
        <QuantitySelector
          initialQuantity={0}
          step={1}
          addToCartHandler={() => { }}
          minVal={0}
          maxVal={9999}
        />
      ),
      cellStyle: {
        whiteSpace: 'nowrap',
        minWidth: 110,
        maxWidth: 110,
      },
    }, {
      title: "Comments",
      field: "cols[1]"
    }, {
      title: "Extra",
      field: "cols[2]"
    }];

    return cols;
  }

  const [columns, setColumns] = useState([]);

  const options = {
    filterType: 'checkbox',
    responsive: "simple",
    actionsColumnIndex: -1,
    paging: false,
    addRowPosition: 'first',
    doubleHorizontalScroll: false,
  };

  const roles = useRoles();

  const addRefs2Spare = (spid, refsArr) => {
    return new Promise((resolve, reject) => {
      fetch(Config.SPARESAPI_UPDATE_SPARE_REFS_URL.replace("$spid$", spid), {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(refsArr)
      }).then(response => {
        resolve(response)
      }).catch(error => reject(error))
    })
  };

  const loadRefsMap = (refs) => {
    refsMap.current = refs.reduce((dict, ref) => {
      ref.area_links = 0;
      dict[ref.ref] = ref;
      return dict;
    }, {})
  }

  const updateLinkedCounters = () => {
    spare.areas.forEach(area => {
      if (area.ref_link && area.ref_link.ref) {
        if (refsMap.current[area.ref_link.ref]) {
          refsMap.current[area.ref_link.ref].area_links++;
        } else {
          // Area linked to an ref which already isn't in this spare. We ignore it
        }
      }
    })
  }

  const markAlreadyLinkedRefs = (refs) => {
    loadRefsMap(refs);
    updateLinkedCounters();
    return refs;
  }

  const addQuantityValues = (refs) => {
    setRefsQuantityMap(refs.reduce((a, ref) => ({ ...a, [ref.ref]: 0 }), {}));
  }

  const loadSpareRefsFromCatalog = (refsArr, lang) => {
    if (refsArr && refsArr.length > 0) {
      return new Promise((resolve, reject) => {
        const url = Config.CATALOGSAPI_LOAD_SPECIFIC_REFS_URL.replace("$cid$", spare.company_id)
          + (lang ? "?lang=" + lang : "");

        fetch(url, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-type": "application/json"
          },
          body: JSON.stringify(refsArr)
        }).then(response => {
          if (response.ok) {
            response.json().then((refsData) => {

              if (!isEditingSpare) {
                addQuantityValues(refsData.refs);
              }

              setRefsData(markAlreadyLinkedRefs(refsData.refs));
            })
          } else {
            errorHandler(response.status, "No se pudo leer las referencias", enqueueSnackbar);
          }
        }).catch(error => {
          errorHandler(null, "Error al guardar las referencias", enqueueSnackbar);
          // FALTA: sentry msg
        });
      })
    } else {
      setRefsData([]);
    }
  }

  const updateCatalog = (spareRefLocales) => {
    let url = Config.CATALOGSAPI_POST_REFS_URL.replace("$cid$", spare.company_id);

    return new Promise((resolve, reject) => {
      fetch(encodeURI(url), {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-type": "application/json",
          "Accept-Language": tableLang,
        },
        body: JSON.stringify(spareRefLocales)
      }).then(response => {
        if (response.ok) {
          resolve(response);
        } else {
          errorHandler(response.status, "Error actualizando la/s referencias", enqueueSnackbar);
          reject();
        }
      }).catch(error => {
        showNetworkErrorMsg(error);
        reject();
      })
    })
  }

  const refs2Spare = (refs) => {
    return refs.map(ref => ref.ref)
  }

  useEffect(() => {
    // On load spare, load spare refs
    if (spare) {
      loadSpareRefsFromCatalog(spare.refs, tableLang);
    }
    // FALTA: sentry msg
  }, [spare]);


  useEffect(() => {
    if (spare && data) {
      setColumns(calculateColumns(data, isEditingSpare));
      setData([...data]);
    }
  }, [isEditingSpare]);

  useEffect(() => {
    setData(refsData);
    setColumns(calculateColumns(refsData, isEditingSpare));
  }, [refsData])


  const excelFileImportHandler = (file) => {
    parseExcel2GenerateTable(file, tableLang).then(spareRefLocales => {
      updateCatalog(spareRefLocales, true).then((response) => {
        response.json().then(newData => {
          setData(markAlreadyLinkedRefs(newData));
          setRefsModified(spareDispatcher, true);
        })
      })
    }, error => {
      enqueueSnackbar(error, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }).catch((error) => {
      enqueueSnackbar("Se ha producido un error. Por favor, inténtelo de nuevo más tarde", {
        variant: 'error',
        autoHideDuration: 2000,
      });
    })
  }

  const handleLangSelectionChange = (event) => {
    const selectedLang = event.target.value;
    setTableLang(selectedLang);
    loadSpareRefsFromCatalog(spare.refs, selectedLang);
  }

  const spareRef2SpareRefLocales = (spareRef) => {
    let spareRefLocales = {
      ref: spareRef.ref,
      price: spareRef.price,
      translations: {}
    }

    if (Array.isArray(spareRef.cols)) {
      spareRefLocales.translations[tableLang] = spareRef.cols;
    } else {
      spareRefLocales.translations[tableLang] = [
        spareRef.cols.hasOwnProperty("0") ? spareRef.cols["0"] : "",
        spareRef.cols.hasOwnProperty("1") ? spareRef.cols["1"] : "",
        spareRef.cols.hasOwnProperty("2") ? spareRef.cols["2"] : ""
      ];
    }

    return spareRefLocales;
  }

  return (
    <div className={classes.container} id="refs-table">
      <Box className={classes.tableLangBox}>
        <Box className={classes.tableLangBoxLeft}>
          <LanguageSelect
            handleLangSelectionChange={handleLangSelectionChange}
            initialValue={tableLang} />
        </Box>

        <Box className={classes.tableLangBoxRight}>
          {isEditingSpare && checkAuth(roles[0], "spares:edit", null) &&
            <ExcelImport
              excelFileImportHandler={excelFileImportHandler} />
          }
        </Box>
      </Box>
      <TableContainer>
        <MaterialTableFix
          className={classes.datatable}
          icons={tableIcons}
          responsive="simple"
          title={""}
          data={data}
          columns={columns}
          options={options}
          color="primary"
          floating={floating}
          components={{
            Row: props => (
              <MTableBodyRow
                {...props}
                id={props.data.ref}
                key={props.data.ref}
                onDoubleClick={e => {
                  if (isEditingSpare) {
                    props.actions[2]().onClick(e, props.data);
                  }
                }}
                onMouseOver={(e) => {
                  areasRef.current.forEach((fg) => {
                    if (fg.ref_link
                      && fg.ref_link.ref === props.data.ref) {
                      fg.select();
                    }
                  });
                }}
                onMouseLeave={(ev) => {
                  areasRef.current.forEach((fg) => {
                    if (fg.ref_link
                      && fg.ref_link.ref === props.data.ref) {
                      fg.deselect();
                    }
                  });
                }}
              />
            ),
          }}
          actions={[]}
          editable={isEditingSpare && checkAuth(roles[0], "spares:edit", null) ? {
            isEditable: rowData => isEditingSpare,
            onRowAdd: newData =>
              /*
                Table rows are SpareRef instances
    
                When saving refs we send SpareRefLocales instance
    
              */
              new Promise((resolve, reject) => {
                if (newData.price) {
                  newData.price = numberParser.parse(newData.price);
                }
                updateCatalog([spareRef2SpareRefLocales(newData)]);

                setData(markAlreadyLinkedRefs([newData, ...data]));
                setRefsModified(spareDispatcher, true);

                resolve();
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                const dataUpdate = [...data];
                const index = oldData.tableData.id;
                dataUpdate[index] = newData;

                updateCatalog([spareRef2SpareRefLocales(newData)]);

                // Remove area link
                areasRef.current.forEach(area => {
                  if (area.ref_link.ref === oldData.ref) {
                    area.ref_link.ref = newData.ref;
                  }
                })
                setData(markAlreadyLinkedRefs(dataUpdate));
                setRefsModified(spareDispatcher, true);

                resolve();
              }),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                const dataDelete = [...data];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);

                // Remove area link
                areasRef.current.forEach(area => {
                  if (area.ref_link.ref === oldData.ref) {
                    delete area.ref_link.ref;
                  }
                })

                setData(markAlreadyLinkedRefs(dataDelete));
                updateSpareContext(spareDispatcher, {
                  //         spare: spare,
                  isModified: {
                    areas: true,
                    refs: true,
                  }
                });

                resolve();
              })
          } : {}}
        />
      </TableContainer>
    </div>
  )

}

