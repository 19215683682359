import React, { useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, SvgIcon, Tooltip, Typography } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import Config from 'config';
import { Link } from 'react-router-dom';
import ExternalLink from '../ExternalLink';
import useStyles from "./styles";
import { DropzoneDialog } from 'material-ui-dropzone';

const ExcelImport = (props) => {
    const classes = useStyles();
    const excelFileImportHandler = props.excelFileImportHandler;

    const IMPORT_TEMPLATE_NAME = Config.SPAREREFS_EXCEL_TEMPLATE;

    const [showHelpDialog, setShowHelpDialog] = useState(false);
    const [openImportDialog, setOpenImportDialog] = useState(false);

    const handleShowHelpClick = (event) => {
        event.preventDefault();
        setShowHelpDialog(!showHelpDialog);
    }


    const openUploadExcelHandler = (event) => {
        event.preventDefault();
        setOpenImportDialog(true);
    }

    return (
        <Box className={classes.container}>
            <div className={classes.separator}></div>
            <Tooltip title="Importar referencias desde un fichero excel">
                <Link to="#"
                    onClick={openUploadExcelHandler}>Importar excel</Link>
            </Tooltip>
            <Tooltip title="Ayuda">
                <Link to="#"
                    onClick={handleShowHelpClick}>
                    <HelpOutline className={classes.importExcelHelpIcon}
                    />
                </Link>
            </Tooltip>
            <Tooltip title="Descargar plantilla">
                <ExternalLink url={IMPORT_TEMPLATE_NAME} className={classes.downloadTemplateLink}>
                    <SvgIcon><path d="M21.17 3.25Q21.5 3.25 21.76 3.5 22 3.74 22 4.08V19.92Q22 20.26 21.76 20.5 21.5 20.75 21.17 20.75H7.83Q7.5 20.75 7.24 20.5 7 20.26 7 19.92V17H2.83Q2.5 17 2.24 16.76 2 16.5 2 16.17V7.83Q2 7.5 2.24 7.24 2.5 7 2.83 7H7V4.08Q7 3.74 7.24 3.5 7.5 3.25 7.83 3.25M7 13.06L8.18 15.28H9.97L8 12.06L9.93 8.89H8.22L7.13 10.9L7.09 10.96L7.06 11.03Q6.8 10.5 6.5 9.96 6.25 9.43 5.97 8.89H4.16L6.05 12.08L4 15.28H5.78M13.88 19.5V17H8.25V19.5M13.88 15.75V12.63H12V15.75M13.88 11.38V8.25H12V11.38M13.88 7V4.5H8.25V7M20.75 19.5V17H15.13V19.5M20.75 15.75V12.63H15.13V15.75M20.75 11.38V8.25H15.13V11.38M20.75 7V4.5H15.13V7Z" /></SvgIcon>
                </ExternalLink>
            </Tooltip>

            <Dialog
                open={showHelpDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Importar referencias desde excel"}</DialogTitle>
                <DialogContent className={classes.content}>

                    <div className={classes.popoverPaper}>
                        <DialogContentText>
                            Los nombres de las columnas deben ser:
                        </DialogContentText>
                        <ul className={classes.propertiesList}>
                            <li>
                                <Typography><span className={classes.propertyTitle}>ref</span><span className={classes.mandatoryProperty}> (obligatorio)</span>: la referencia del componente</Typography>
                            </li>
                            <li>
                                <Typography><span className={classes.propertyTitle}>price</span> (opcional): el precio del componente</Typography>
                            </li>
                            <li>
                                <Typography><span className={classes.propertyTitle}>description</span> (opcional): descripción corta</Typography>
                            </li>
                            <li>
                                <Typography><span className={classes.propertyTitle}>comments</span> (opcional): descripción larga</Typography>
                            </li>
                            <li>
                                <Typography><span className={classes.propertyTitle}>extra</span> (opcional): valor libre</Typography>
                            </li>
                        </ul>
                        <div className={classes.popoverSeparator}></div>
                        <DialogContentText>
                            Puede definir valores para <span className={classes.propertyTitle}>description</span>,
                            <span className={classes.propertyTitle}>comments</span> y <span className={classes.propertyTitle}>extra</span> <span className={classes.otherLanguages}>en otros idiomas </span>
                            añadiendo una nueva columna con: el respectivo nombre de la columna, un guión y el código de idioma.
                        </DialogContentText>
                        <DialogContentText>
                            Los idiomas disponibles actualmente son: English (<span className={classes.langCode}>en</span>), Español (<span className={classes.langCode}>es</span>), Français (<span className={classes.langCode}>fr</span>), Italiano (<span className={classes.langCode}>it</span>) y Portugués (<span className={classes.langCode}>pt</span>).
                        </DialogContentText>
                        <DialogContentText>Por ejemplo: <span className={classes.propertyTitle}>description-es</span> ó <span className={classes.propertyTitle}>comments-pt</span>
                        </DialogContentText>
                        <DialogContentText>Puede descargar una plantilla desde&nbsp;
                            <ExternalLink linkTitle="aqu&iacute;" url={IMPORT_TEMPLATE_NAME} />
                        </DialogContentText>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Link to="#"
                        onClick={handleShowHelpClick}>Cerrar</Link>
                </DialogActions>
            </Dialog>

            <DropzoneDialog
                acceptedFiles={['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
                dropzoneText={"Arrastre aquí un archivo excel o haga click"}
                cancelButtonText={"Cancelar"}
                submitButtonText={"Enviar"}
                maxFileSize={5000000}
                open={openImportDialog}
                onClose={() => setOpenImportDialog(false)}
                onDrop={(files) => {
                    excelFileImportHandler(files);
                    setOpenImportDialog(false);
                }}
                filesLimit={1}
                showPreviews={false}
                showPreviewsInDropzone={false}
            />
        </Box>
    )
}

export default ExcelImport;