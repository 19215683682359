import {
  makeStyles
} from "@material-ui/styles";

export default makeStyles(theme => ({

  mainPageContainer: theme.mainPageContainer,
  aRight: theme.aRight,
  height100: theme.height100,
  MarAuto: theme.MarAuto,
  Wid50: theme.Wid50,
  Wid75: theme.Wid75,
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  brandName: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  companyName: {
    color: theme.palette.text.hint,
    ...theme.text.regular,
  },
  logoArea: {
    padding: theme.spacing(1),
    height: '100%',
  },
  dense: {
    marginTop: 19,
  },
  dropZoneArea: {
  },
  dropZone: {
    minHeight: '100px !important'
  },
  noCompany: {
    display: "flex",
    flexDirection: "row",
    alignItems: 'center',
    '& p': {
      display: "inline-block"
    },
    "& button": {
      marginLeft: 10
    }
  },
  brandsTableBox: {
    width: '100%'
  },
  muitableActionRoot: {
    minHeight: '0% !important',
    paddingTop: 2,
    paddingBottom: 2,
    backgroundColor: theme.palette.background.solid,
    marginBottom: 5,
    "& button": {
      right: 10,
      backgroundColor: theme.palette.primary.main,
      padding: 5,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      }
    },
  },
  companyAccordionSummaryRoot: {
    backgroundColor: theme.palette.background.solid,
  },
  companyHeaderContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 0,
    zIndex: 1000
  },
  companyHeaderName: {
    flex: 4
  },
  companyHeaderLogo: {
    flex: 4,
    zIndex: 100000
  },
  companyHeaderActions: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',

  },
  companyHeaderAction: {
  },
  companyHeaderActionRoot: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: theme.palette.text.primary
  }
}));
