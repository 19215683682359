import Config from "config";
import React from "react";

var SpareStateContext = React.createContext();
var SpareDispatchContext = React.createContext();

const initial_state = {
  mode: Config.SpareActions.BROWSE.outcome,
  isSpareTreeOpened: true,
  currentSpare: null,
  isModified: {
    areas: false,
    refs: false
  },
  loadingSpare: false,
  linked_refs: {}
}

const SpareReducer = (state, action) => {

  switch (action.type) {
    case "TOGGLE_SPARETREE":
      return { ...state, isSpareTreeOpened: !state.isSpareTreeOpened };

    case "SET_CURRENT_SPARE":
      return {
        ...state,
        currentSpare: findLinkedRefs(action.spare),
        isModified: {
          areas: action.isModified,
          refs: action.isModified
        },
      };

    case "RESET":
      return { ...initial_state };

    case 'TOGGLE_LOADING_SPARE':
      return { ...state, loadingSpare: !state.loadingSpare };

    case 'TOGGLE_MODIFIED_SPARE':
      return { ...state, isModified: { areas: false, refs: false } };

    case 'SET_MODIFIED_AREAS':
      return { ...state, isModified: { ...state.isModified, areas: action.modified } };

    case 'SET_MODIFIED_REFS':
      return { ...state, isModified: { ...state.isModified, refs: action.modified } };
    case 'UPDATE':
      return { ...state, ...action.newState };

    case "SET_MODE":
      return {...state, mode: action.mode}

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const SpareProvider = ({ children }) => {
  var [state, dispatch] = React.useReducer(SpareReducer, initial_state);

  return (
    <SpareStateContext.Provider value={state}>
      <SpareDispatchContext.Provider value={dispatch}>
        {children}
      </SpareDispatchContext.Provider>
    </SpareStateContext.Provider>
  );
}

function useSpareState() {
  var context = React.useContext(SpareStateContext);
  if (context === undefined) {
    throw new Error("useSpareState must be used within a SpareContextProvider");
  }
  return context;
}

function useSpareDispatch() {
  var context = React.useContext(SpareDispatchContext);
  if (context === undefined) {
    throw new Error("useSpareDispatch must be used within a SpareProvider");
  }
  return context;
}

function useCurrentSpare() {
  const { currentSpare } = useSpareState();
  return currentSpare;
}

function useLoadingSpare() {
  const { loadingSpare } = useSpareState();
  return loadingSpare;
}

function updateSpareContext(dispatch, newState) {
  dispatch({ type: 'UPDATE', newState: newState });
}

function useSpareMode() {
  const { mode } = useSpareState();
  return mode;
}

function setSpareMode(dispatch, mode) {
  dispatch({type: "SET_MODE", mode: mode});
}

function setEditingMode(dispatch) {
  setSpareMode(dispatch, Config.SpareActions.EDIT.outcome);
}

function setBrowsingMode(dispatch) {
  setSpareMode(dispatch, Config.SpareActions.BROWSE.outcome);
}

function findLinkedRefs(spare) {
  let refsMap = {};

  if (spare && spare.refs && spare.refs.length > 0) {

    spare.refs.forEach(element => {
      refsMap[element.ref] = element;
    });

    if (spare.areas && spare.areas.length > 0) {
      spare.areas.forEach(area => {
        if (area.ref_link && area.ref_link.ref) {
          if (refsMap[area.ref_link.ref]) {
            if (refsMap[area.ref_link.ref].isLinked) {
              refsMap[area.ref_link.ref].isLinked++;
            } else {
              refsMap[area.ref_link.ref].isLinked = 1;
            }
          } else {
            // FALTA: missing ref in refsMap ¿?
          }
        }
      });
    }
  }

  return spare;
}

function useIsEditingSpare() {
  const mode = useSpareMode();
  return (mode === Config.SpareActions.EDIT.outcome)
}

export {
  SpareProvider,
  useSpareState,
  useSpareDispatch,
  useCurrentSpare,
  setCurrentSpare,
  toggleSpareTree,
  useLoadingSpare,
  toggleLoadingSpare,
  toggleSpareIsModified,
  useIsSpareModified,
  setAreasModified,
  setRefsModified,
  resetCurrentSpare,
  updateSpareContext,
  useSpareMode,
  setSpareMode,
  useIsEditingSpare,
  setEditingMode,
  setBrowsingMode,
  useIsSpareRefsModified,
  useIsSpareAreasModified,

};

// #################################################################
function toggleSpareTree(dispatch) {
  dispatch({
    type: 'TOGGLE_SPARETREE',
  });
}

function setCurrentSpare(dispatch, spare, isModified = true) {
  dispatch({
    type: 'SET_CURRENT_SPARE',
    spare: spare,
    isModified: isModified
  });
}

function toggleLoadingSpare(dispatch) {
  dispatch({ type: "TOGGLE_LOADING_SPARE" });
}

function toggleSpareIsModified(dispatch) {
  dispatch({ type: "TOGGLE_MODIFIED_SPARE" });
}

function setAreasModified(dispatch, modified) {
  dispatch({ type: "SET_MODIFIED_AREAS", modified: modified });
}

function setRefsModified(dispatch, modified) {
  dispatch({ type: "SET_MODIFIED_REFS", modified: modified });
}

function useIsSpareModified() {
  const { isModified } = useSpareState();

  return isModified.refs || isModified.areas;
}

function useIsSpareAreasModified() {
  const { isModified } = useSpareState();

  return isModified.areas;
}

function useIsSpareRefsModified() {
  const { isModified } = useSpareState();

  return isModified.refs;
}

function resetCurrentSpare(dispatch) {
  dispatch({ type: "RESET" });
}
