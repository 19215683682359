import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({

  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '99%',
    height: '100%'
  },
  commandButtonsWrapper: {
    display: 'flex',
    paddingLeft: 15,
    paddingRight: 15,
    justifyContent: 'space-between',
    alignItems: 'top',
    position: 'relative'
  },
  commandButtonsWrapperLeft: {
  },
  commandButtonsWrapperCenter: {

  },
  commandButtonsWrapperRight: {
    position: 'relative',
    top: 0,
    right: 55,
    minHeight: 50,
  },
  viewerContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
    backgroundColor: theme.palette.background.solid
  },
  speedDialAction: {
    backgroundColor: 'green',
    color: theme.palette.primary,
    "& > ..MuiSpeedDialAction-fab": {
      backgroundColor: 'red'
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.primary
    }
  },
  selectedRefTableRow: {
    backgroundColor: theme.palette.primary.ultralight,
  },
  areaContextMenuItem: {
    color: theme.palette.text.primary,
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.secondary,
    },
    "& > div": {
      marginRight: theme.spacing(1)
    }
  },
  areaContextMenuLinkDisabled: {
    "&:hover": {
      backgroundColor: `${theme.palette.background.solid} !important`,
      color: `${theme.palette.text.disabled} !important`,
      cursor: 'default'
    }
  },
  areaContextMenuLink: {

  }
}));
