import React from 'react';
import { Can } from 'components/spclick/Can';
import { useRoles } from 'context/UserContext';

const Render = (props) => {

    const roles = useRoles();

    return roles && roles.length >0?<Can
            role={roles[0]}
            perform={props.perform}
            yes={()=> {
                return props.children
            }}
            no={()=>{ 
                return null;}
            }
            />
            :null;
}

export default Render;