import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  pageTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center',
    width: '100%',
  },
  '@media (min-height: 900px)': {
    pageTitleContainer: {
      display: "flex",
      justifyContent: "space-between",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    }
  },
  typo: {
    flex: 9,
    color: theme.palette.text.hint,
  },
  buttons: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
}));
