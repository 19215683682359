import React from "react";

// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";

export default function PageTitle(props) {
  var classes = useStyles();

  const title = props.title;
  const titleAdornement = props.titleAdornement;

  return (
    <div className={classes.pageTitleContainer}>
      <Typography className={classes.typo} variant="h2" size="sm">
        {title}
        {titleAdornement}
      </Typography>

      <div className={classes.buttons}>
        {props.children}
      </div>
    </div>
  );
}
