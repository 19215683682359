import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "pages/dashboard";
import Typography from "pages/typography";
import Maps from "pages/maps";
import Tables from "pages/tables";
import Icons from "pages/icons";
import Charts from "pages/charts";
import EditSpare from "pages/spares/editspare";
import EditBrand from "pages/brands/EditBrand";
import Catalog from 'pages/catalog';

// context
import { useLayoutState } from "../../context/LayoutContext";
import EditCompanyUsers from "pages/brands/users/EditCompanyUsers";
import Carts from "pages/carts";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
              <Route path="/app/dashboard" component={Dashboard} />
              <Route path="/app/newspare" component={Typography} />
              <Route path="/app/tables" component={Tables} />

              <Route path="/app/editspare" component={EditSpare} />
              <Route path="/app/carts" component={Carts} />
              <Route path="/app/brands" component={EditBrand} />
              <Route path="/app/uploads" component={EditSpare} />
              <Route path="/app/spares/:mode/:spareId?" component={EditSpare} />
              <Route path="/app/companyusers/:companyName" component={EditCompanyUsers}/>
              <Route path="/app/catalog/:cid/:cname" component={Catalog}/>

              <Route
                exact
                path="/app/ui"
                render={() => <Redirect to="/app/ui/icons" />}
              />
              <Route path="/app/ui/maps" component={Maps} />
              <Route path="/app/ui/icons" component={Icons} />
              <Route path="/app/ui/charts" component={Charts} />
            </Switch>
          </div>
        </>
    </div>
  );
}

export default withRouter(Layout);
