import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    '@media (min-width: 1280px)': {
        contentBox: {
          maxWidth: 1280,
          width: 1280,
          padding: theme.spacing(3),
          paddingTop: 0,
          paddingBottom: 0,
          margin: '0 auto',
        }
      },
}))