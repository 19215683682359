import React, { useState, forwardRef } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormGroup,
  FormControlLabel,
  Grid,
} from "@material-ui/core";

import MaterialTable, {
  MTableBodyRow,
  MTableToolbar,
} from "material-table";

import {
  Add,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn
} from "@material-ui/icons";
import { useSnackbar } from 'notistack';

import useStyles from './styles';

const tableIcons = {
  Add: forwardRef((props, ref) => <Add {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const BrandAclsDialog = ({ open, handleAclsDialogClose, cname, bname, acls, brandAclsUrl }) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [acl, setAcl] = useState(acls);

  const tableColumns = [{
    title: "Username",
    field: "username"
  }];

  const tableOptions = {
    filterType: 'checkbox',
    responsive: "simple",
    actionsColumnIndex: -1,
    paging: false,
    addRowPosition: 'first',
    minBodyHeight: 300,
    showTitle: false,
  };

  const handleAclsDialogSubmit = async () => {

    let mangledAcl = {...acl}

    if (mangledAcl.custom && mangledAcl.custom.users) {
      mangledAcl.custom.users = mangledAcl.custom.users.map(u=>{
        delete u.tableData;
        return u;
      });
    }


    const resp = await fetch(brandAclsUrl, {
      method: "POST",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(mangledAcl)
    });

    if (resp.ok) {
      enqueueSnackbar('Operación realizada correctamente!', {
        variant: 'success',
        autoHideDuration: 2000,
      });
      handleAclsDialogClose();
    } else if (resp.status == 401) {
      enqueueSnackbar('Operación no autorizada!', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    } else {
      enqueueSnackbar('No se ha podido realizar la operación!', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  }

  return (
    <Dialog open={open}
      onClose={handleAclsDialogClose}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Permisos</DialogTitle>
      <DialogContent style={{ minWidth: 500 }}>
        <DialogContentText>
          Aquí puede cambiar los permisos de acceso a:
            </DialogContentText>
        <Grid container className={classes.classification}>
          <Grid item xs={12} className={classes.titleTxt}>{cname}</Grid>
          <Grid item xs={12} className={classes.titleTxt}>{bname}</Grid>
        </Grid>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox color="primary" checked={acls.ownusers.active} onChange={(e) => {
                acls.ownusers.active = e.target.checked;
                setAcl({ ...acls });
              }} name="ownusers" />}
              label="Mis usuarios"
            />
            <FormControlLabel
              control={<Checkbox color="primary" checked={acls.registered.active} onChange={(e) => {
                acls.registered.active = e.target.checked;
                setAcl({ ...acls });
              }} name="registered" />}
              label="Todos los usuarios registrados"
            />
            <FormControlLabel
              control={<Checkbox color="primary" checked={acls.custom.active} onChange={(e) => {
                acls.custom.active = e.target.checked;
                setAcl({ ...acls });
              }} name="custom" />}
              label="Personalizado"
            />
          </FormGroup>
        </FormControl>
        <Box className={classes.tableBox}>
          <MaterialTable
            className={classes.datatable}
            icons={tableIcons}
            responsive="simple"
            title={"Users"}
            data={acl.custom.users || []}
            columns={tableColumns}
            options={tableOptions}
            color="primary"
            components={{
              Row: props => (
                <MTableBodyRow
                  {...props}
                  onDoubleClick={e => {
                    props.actions[2]().onClick(e, props.data);
                  }} />
              ),
              Toolbar: props => (
                <MTableToolbar {...props}
                  classes={{
                    root: classes.muitableActionRoot,
                  }} />
              )
            }}
            editable={{
              isEditable: rowData => acls.custom.active,
              onRowAdd: acls.custom.active ? newData =>

                new Promise((resolve, reject) => {
                  fetch(brandAclsUrl + "/customuser", {
                    method: "POST",
                    credentials: 'include',
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(newData)
                  }).then((response) => {
                    if (response.ok) {
                      setAcl({
                        ...acl, custom: {
                          ...acl.custom,
                          users: [newData, ...acl.custom.users]
                        }
                      });
                      enqueueSnackbar('Usuario añadido correctamente!', {
                        variant: 'success',
                        autoHideDuration: 2000,
                      });
                    } else if (response.status === 401) {
                      enqueueSnackbar('Operación no autorizada!', {
                        variant: 'error',
                        autoHideDuration: 2000,
                      });
                    } else {
                      enqueueSnackbar('No se ha podido realizar la operación!', {
                        variant: 'error',
                        autoHideDuration: 2000,
                      });
                    }
                  });


                  resolve();
                }) : undefined,
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {

                    fetch(brandAclsUrl + "/customuser/" + oldData.username, {
                      method: "DELETE",
                      credentials: 'include',
                    }).then((response) => {
                      if (response.ok) {
                        const dataDelete = [...acl.custom.users];
                        const index = oldData.tableData.id;
                        dataDelete.splice(index, 1);
                        setAcl({
                          ...acl,
                          custom: {
                            ...acl.custom,
                            users: [...dataDelete]
                          }
                        });

                        enqueueSnackbar('Usuario eliminado correctamente!', {
                          variant: 'success',
                          autoHideDuration: 2000,
                        });
                      } else if (response.status === 401) {
                        enqueueSnackbar('Operación no autorizada!', {
                          variant: 'error',
                          autoHideDuration: 2000,
                        });
                      } else {
                        enqueueSnackbar('No se ha podido realizar la operación!', {
                          variant: 'error',
                          autoHideDuration: 2000,
                        });
                      }
                    });

                    resolve();
                  }, 1000);
                })
            }}
            onRowClick={(ev, rowData) => {
              console.log('CLICK');
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAclsDialogClose} variant="contained" color="primary">
          Cancelar
            </Button>
        <Button onClick={handleAclsDialogSubmit} variant="contained" color="primary">
          Guardar
            </Button>
      </DialogActions>
    </Dialog>
  )

}

export default BrandAclsDialog;