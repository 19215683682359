import Area from './Area.js';
import Helper from './Helper.js';
import utils from '../utils.js';

/* ---------- Constructors for real areas ---------- */

/**
 * The constructor for rectangles
 *
 * (x, y) -----
 * |          | height
 * ------------
 *     width
 *
 * @constructor
 * @param coords {Object} - object with parameters of new area (x, y, width, height)
 *                          if some parameter is undefined, it will set 0
 * @param attributes {Object} [attributes=undefined] - attributes for area (e.g. href, title)
 */
class Rectangle extends Area {

  constructor (coords, attributes, info, events) {
    super('rectangle', coords, attributes, info, events);

    /**
     * @namespace
     * @property {number} x - Distance from the left edge of the image to the left side of the rectangle
     * @property {number} y - Distance from the top edge of the image to the top side of the rectangle
     * @property {number} width - Width of rectangle
     * @property {number} height - Height of rectangle
     */
    this._coords = {
      x: coords.x || 0,
      y: coords.y || 0,
      width: coords.width || 0,
      height: coords.height || 0
    };

    this._el = document.createElementNS(Area.SVG_NS, 'rect');
    if (coords.height === 34.39035000000001) {
    this._el.setAttribute("zz", "red");
    }
    this._groupEl.appendChild(this._el);

    var x = coords.x - this._coords.width / 2,
      y = coords.y - this._coords.height / 2;

    this._helpers = {
      center: new Helper(this._groupEl, x, y, 'move'),
      top: new Helper(this._groupEl, x, y, 'editTop'),
      bottom: new Helper(this._groupEl, x, y, 'editBottom'),
      left: new Helper(this._groupEl, x, y, 'editLeft'),
      right: new Helper(this._groupEl, x, y, 'editRight'),
      topLeft: new Helper(this._groupEl, x, y, 'editTopLeft'),
      topRight: new Helper(this._groupEl, x, y, 'editTopRight'),
      bottomLeft: new Helper(this._groupEl, x, y, 'editBottomLeft'),
      bottomRight: new Helper(this._groupEl, x, y, 'editBottomRight')
    };

    this.redraw();
  }

showHelpers = () => {
  this._helpers.center.show();
  this._helpers.top.show();
  this._helpers.bottom.show();
  this._helpers.left.show();
  this._helpers.right.show();
  this._helpers.topLeft.show();
  this._helpers.topRight.show();
  this._helpers.bottomLeft.show();
  this._helpers.bottomRight.show();
}

hideHelpers = () => {
  this._helpers.center.hide();
  this._helpers.top.hide();
  this._helpers.bottom.hide();
  this._helpers.left.hide();
  this._helpers.right.hide();
  this._helpers.topLeft.hide();
  this._helpers.topRight.hide();
  this._helpers.bottomLeft.hide();
  this._helpers.bottomRight.hide();
}

/**
 * Set attributes for svg-elements of area by new parameters
 *
 * -----top------
 * |            |
 * ---center_y---
 * |            |
 * ----bottom----
 *
 * @param coords {Object} - Object with coords of this area (x, y, width, height)
 * @returns {Rectangle} - this rectangle
 */
setSVGCoords = (coords) => {
  this._el.setAttribute('x', coords.x);
  this._el.setAttribute('y', coords.y);
  this._el.setAttribute('width', coords.width);
  this._el.setAttribute('height', coords.height);

  var top = coords.y,
    center_y = coords.y + coords.height / 2,
    bottom = coords.y + coords.height,
    left = coords.x,
    center_x = coords.x + coords.width / 2,
    right = coords.x + coords.width;

  this._helpers.center.setCoords(center_x, center_y);
  this._helpers.top.setCoords(center_x, top);
  this._helpers.bottom.setCoords(center_x, bottom);
  this._helpers.left.setCoords(left, center_y);
  this._helpers.right.setCoords(right, center_y);
  this._helpers.topLeft.setCoords(left, top);
  this._helpers.topRight.setCoords(right, top);
  this._helpers.bottomLeft.setCoords(left, bottom);
  this._helpers.bottomRight.setCoords(right, bottom);

  return this;
};

/**
 * Set coords for this area
 *
 * @param coords {coords}
 * @returns {Rectangle} - this rectangle
 */
setCoords = (coords) => {
  this._coords.x = coords.x;
  this._coords.y = coords.y;
  this._coords.width = coords.width;
  this._coords.height = coords.height;

  return this;
};

/**
 * Calculates new coordinates in process of drawing
 *
 * @param x {number} - x-coordinate of cursor
 * @param y {number} - y-coordinate of cursor
 * @param isSquare {boolean}
 * @returns {Object} - calculated coords of this area
 */
dynamicDraw = (x, y, isSquare) => {
  var newCoords = {
    x: this._coords.x,
    y: this._coords.y,
    width: x - this._coords.x,
    height: y - this._coords.y
  };

  if (isSquare) {
    newCoords = Rectangle.getSquareCoords(newCoords);
  }

  newCoords = Rectangle.getNormalizedCoords(newCoords);

  this.redraw(newCoords);

  return newCoords;
};

/**
 * Handler for drawing process (by mousemove)
 * It includes only redrawing area by new coords
 * (this coords doesn't save as own area coords)
 *
 * @params e {MouseEvent} - mousemove event
 */
onProcessDrawing = (e) => {
  var coords = utils.getRightCoords(this.app.appState, e.pageX, e.pageY);

  this.dynamicDraw(coords.x, coords.y, e.shiftKey);
};

/**
 * Handler for drawing stoping (by second click on drawing canvas)
 * It includes redrawing area by new coords
 * and saving this coords as own area coords
 *
 * @params e {MouseEvent} - click event
 */
onStopDrawing = (e) => {
  var coords = utils.getRightCoords(this.app.appState, e.pageX, e.pageY);

  this.setCoords(this.dynamicDraw(coords.x, coords.y, e.shiftKey)).deselect();
  this.app.removeAllEvents()
    .setIsDraw(false)
    .resetNewArea();
};

/**
 * Changes area parameters by editing type and offsets
 *
 * @param {string} editingType - A type of editing (e.g. 'move')
 * @returns {Object} - Object with changed parameters of area
 */
edit = (editingType, dx, dy) => {
  var tempParams = Object.create(this._coords);

  switch (editingType) {
    case 'move':
      tempParams.x += dx;
      tempParams.y += dy;
      break;

    case 'editLeft':
      tempParams.x += dx;
      tempParams.width -= dx;
      break;

    case 'editRight':
      tempParams.width += dx;
      break;

    case 'editTop':
      tempParams.y += dy;
      tempParams.height -= dy;
      break;

    case 'editBottom':
      tempParams.height += dy;
      break;

    case 'editTopLeft':
      tempParams.x += dx;
      tempParams.y += dy;
      tempParams.width -= dx;
      tempParams.height -= dy;
      break;

    case 'editTopRight':
      tempParams.y += dy;
      tempParams.width += dx;
      tempParams.height -= dy;
      break;

    case 'editBottomLeft':
      tempParams.x += dx;
      tempParams.width -= dx;
      tempParams.height += dy;
      break;

    case 'editBottomRight':
      tempParams.width += dx;
      tempParams.height += dy;
      break;
      default: break; // fake default case for lint complian
  }

  return tempParams;
};

/**
 * Calculates new coordinates in process of editing
 *
 * @param coords {Object} - area coords
 * @param saveProportions {boolean}
 * @returns {Object} - new coordinates of area
 */
dynamicEdit = (coords, saveProportions) => {
  coords = Rectangle.getNormalizedCoords(coords);

  if (saveProportions) {
    coords = Rectangle.getSavedProportionsCoords(coords);
  }

  this.redraw(coords);

  return coords;
};

/**
 * Handler for editing process (by mousemove)
 * It includes only redrawing area by new coords
 * (this coords doesn't save as own area coords)
 *
 * @params e {MouseEvent} - mousemove event RECTANGLE
 */
 onProcessEditing = (area, scale, e) => {
   let event = e;
   let target = event.target; //originalEvent.target;

  /*console.log('ON PROCESS EDITING !!!! ' + event.pageX + ","
 -  + event.pageY + " --- " + area.editingStartPoint.x + ","
 -  + area.editingStartPoint.y + " / " + scale
 -  + " type: " + area.info.editType);*/

   return area.dynamicEdit(
     area.edit(
       area.info.editType,
       (event.pageX - area.editingStartPoint.x) /scale,
       (event.pageY - area.editingStartPoint.y) /scale
     ),
    e.shiftKey
  );
};

/**
 * Handler for editing stoping (by mouseup)
 * It includes redrawing area by new coords
 * and saving this coords as own area coords
 *
 * @params e {MouseEvent} - mouseup event
 */
onStopEditing = (area, scale, e) => {
  let coords = area.onProcessEditing(area, scale, e);
  area.setCoords(coords);
};

/**
 * Returns string-representation of this rectangle
 *
 * @returns {string}
 */
toString = () => {
  return 'Rectangle {x: ' + this._coords.x +
    ', y: ' + this._coords.y +
    ', width: ' + this._coords.width +
    ', height: ' + this._coords.height + '}';
}

/**
 * Returns html-string of area html element with params of this rectangle
 *
 * @returns {string}
 */
toHTMLMapElementString = () => {
  var x2 = this._coords.x + this._coords.width,
    y2 = this._coords.y + this._coords.height;

  return '<area shape="rect" coords="' // TODO: use template engine
    +
    this._coords.x + ', ' +
    this._coords.y + ', ' +
    x2 + ', ' +
    y2 +
    '"' +
    (this._attributes.href ? ' href="' + this._attributes.href + '"' : '') +
    (this._attributes.alt ? ' alt="' + this._attributes.alt + '"' : '') +
    (this._attributes.title ? ' title="' + this._attributes.title + '"' : '') +
    ' />';
};

toJSONElementString = () => {
  var x2 = this._coords.x + this._coords.width,
    y2 = this._coords.y + this._coords.height;
  return super.JSON();
  /*return {
    shape: "rectangle",
    coords: this._coords.x + ', ' + this._coords.y + ', ' + x2 + ', ' + y2,
    user_data: {}
  }*/
};

/**
 * Returns coords for area attributes form
 *
 * @returns {Object} - object width coordinates of point
 */
getCoordsForDisplayingInfo = () => {
  return {
    x: this._coords.x,
    y: this._coords.y
  };
};

/**
 * Returns true if coords is valid for rectangles and false otherwise
 *
 * @static
 * @param coords {Object} - object with coords for new rectangle
 * @return {boolean}
 */
testCoords = (coords) => {
  return coords.x && coords.y && coords.width && coords.height;
};

/**
 * Returns true if html coords array is valid for rectangles and false otherwise
 *
 * @static
 * @param coords {Array} - coords for new rectangle as array
 * @return {boolean}
 */
testHTMLCoords = (coords) => {
  return coords.length === 4;
};

/**
 * Return rectangle coords object from html array
 *
 * @param htmlCoordsArray {Array}
 * @returns {Object}
 */
getCoordsFromHTMLArray = (htmlCoordsArray) => {
  if (!Rectangle.testHTMLCoords(htmlCoordsArray)) {
    throw new Error('This html-coordinates is not valid for rectangle');
  }

  return {
    x: htmlCoordsArray[0],
    y: htmlCoordsArray[1],
    width: htmlCoordsArray[2] - htmlCoordsArray[0],
    height: htmlCoordsArray[3] - htmlCoordsArray[1]
  };
};

/**
 * Fixes coords if width or/and height are negative
 *
 * @static
 * @param coords {Object} - Coordinates of this area
 * @returns {Object} - Normalized coordinates of area
 */
static getNormalizedCoords = (coords) => {
  if (coords.width < 0) {
    coords.x += coords.width;
    coords.width = Math.abs(coords.width);
  }

  if (coords.height < 0) {
    coords.y += coords.height;
    coords.height = Math.abs(coords.height);
  }

  return coords;
};

/**
 * Returns coords with equivivalent width and height
 *
 * @static
 * @param coords {Object} - Coordinates of this area
 * @returns {Object} - Coordinates of area with equivivalent width and height
 */
getSquareCoords = (coords) => {
  var width = Math.abs(coords.width),
    height = Math.abs(coords.height);

  if (width > height) {
    coords.width = coords.width > 0 ? height : -height;
  } else {
    coords.height = coords.height > 0 ? width : -width;
  }

  return coords;
};

/**
 * Returns coords with saved proportions of original area
 *
 * @static
 * @param coords {Object} - Coordinates of this area
 * @param originalCoords {Object} - Coordinates of the original area
 * @returns {Object} - Coordinates of area with saved proportions of original area
 */
getSavedProportionsCoords = (coords, originalCoords) => {
  var originalProportions = coords.width / coords.height,
    currentProportions = originalCoords.width / originalCoords.height;

  if (currentProportions > originalProportions) {
    coords.width = Math.round(coords.height * originalProportions);
  } else {
    coords.height = Math.round(coords.width / originalProportions);
  }

  return coords;
};

/**
 * Creates new rectangle and adds drawing handlers for DOM-elements
 *
 * @static
 * @param firstPointCoords {Object}
 * @returns {Rectangle}
 */
static createAndStartDrawing = (firstPointCoords, attributes, info, events) => {
  var newArea = new Rectangle({
    x: firstPointCoords.x,
    y: firstPointCoords.y,
    width: 0,
    height: 0
  }, attributes, info, events);
  newArea._attributes = attributes;

  return newArea;
};
}

export default Rectangle;
