import React, { useState } from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    TextField,
} from "@material-ui/core";

import useStyles from "./AutocompleteWrapperStyles";

export default function AutocompleteWrapper({searchItems,handleSearchInput, handleSearchSelection, initialValue}) {
  var classes = useStyles();

  const [currentResult] = useState(null);

  const valueProps = (initialValue?{value: initialValue}:{placeholder: "Buscar"});

  return (
      <Autocomplete
      freeSolo
      id="free-solo-2-demo"
      disableClearable
      classes={{
        option: classes.option,
        listbox: classes.listbox,
      }}
      options={searchItems.map((option) => option)}
      getOptionLabel={(option)=> option.brand.name + " " + option.title}
      value={currentResult}
      onChange={handleSearchSelection}
      renderOption={(option) => (
        <React.Fragment>
          <div  className={classes.searchResultBox}>
            <div className={classes.searchResultTitle}>{option.brand.name + " " + option.title}</div>
            <div className={classes.searchResultSubitle}>{option.company.name}</div>
          </div>
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          classes={{
            root: classes.inputRoot,
            
          }}
          variant="standard"
          {...valueProps}
          margin="normal"
          InputProps={{ ...params.InputProps, type: 'search', classes: {root: classes.root, underline: classes.underline} }}
          onChange={handleSearchInput}
        />
      )}
    />
  )
}