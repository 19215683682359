import {
    makeStyles
  } from "@material-ui/styles";
  
  export default makeStyles(theme => ({
    muitableActionRoot: {
      minHeight: '0% !important',
      paddingTop: 2,
      paddingBottom: 2,
      backgroundColor: theme.palette.background.solid,
      marginBottom: 5,
      "& button": {
        marginLeft: 30,
        right: 10,
        backgroundColor: theme.palette.primary.main,
        padding: 5,
        "&:hover": {
          backgroundColor: theme.palette.primary.dark,
        }
      },
    },
  }))