import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: 60
  },
  levelContainer: {
    padding: "6px 6px 0px 6px",
    position: 'relative',
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  levelContainerExpanded: {
    flex: 1
  },
  levelIconsContainer: {
    position: 'absolute',
    right: 5,
    top: -8,
    display: 'flex',
    flexDirection: 'row',
    justifyItems: 'flex-end',
    alignItems: 'center',
  },
  levelItemButtonsStyle: {
    width: '20px !important'
  },
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    }
  },
  miniButtonSave: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.text.secondary,
    "&:hover": {
      backgroundColor: theme.palette.info.light,
    },
  },
  inputRoot: {
    position: 'relative',
    top: -10,
  },
  formControlRoot: {
    width: '100%',
  },
  autocompleteRoot: {
    flex: 1,
    "& .MuiInputBase-root": {
      paddingTop: 8
    },
    "& .MuiFilledInput-root.MuiFilledInput-marginDense .MuiAutocomplete-input": {
      padding: 8
    },
  },
  autocompleteInputRoot: {
    padding: "4.5px 0px 4.5px 3px"
  },
  securityActionWrapper: {
    height: '100%',
    position: 'relative',
    top: -8
  },
  securityIcon: {
    width: 18,
    opacity: 0.3,
    "&:hover": {
      cursor: "pointer",
    },
  },
  startAdornment: {
    marginRight: 3
  },
  textOnlySelector: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  levelSelectorSeparator: {
    width: 16,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));
