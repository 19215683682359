import { makeStyles } from "@material-ui/styles";


export default makeStyles(theme => ({
    content: {
        "minWidth": "30em",
        "paddingTop": theme.spacing(2)
    }, 
    title: {

    },
    detail1: {
        textAlign: 'left',
        paddingLeft: theme.spacing(1)
    },
    detail2: {
        textAlign: 'center',
        wordWrap: 'break-word',
        maxWidth: '50ch'
    }, 
    alertIcon: {
    },
    separator: {
        width: 20
    },
    questionWrapper: {
        display: 'flex', 
        flexDirection:'row', 
        alignItems: 'center', 
        justifyContent: 'center'
    },
    question: {
        textAlign: 'center',
        "& span": {
            fontWeight: 'bold',
            fontSize: 18
        }
    }
}));