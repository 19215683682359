import React from 'react';
import {
    setCursor,
    useCursor, useData, useSpareTreeDispatch
} from 'context/SpareTreeContext';


const useSpareTreeDriver = () => {
    const cursor = useCursor();
    const data = useData();
    const spareTreeDispatcher = useSpareTreeDispatch();

    const onToggle = (node, toggled, callback) => {
        if (cursor) {
            cursor.active = false;
      
            if (!node.path.startsWith(cursor.path)) {
                let c_parents = cursor.path.split('$$$');
                let n_parents = node.path.split('$$$');
      
                let i = 0;
                while (i < c_parents.length && i < n_parents.length && c_parents[i] === n_parents[i]) {
                  i++;
                }
      
                if (i < n_parents.length) {
                  let node2Collapse = data;
                  for (let c = 0; c <= i; c++) {
                    node2Collapse = node2Collapse.children[parseInt(c_parents[c])];
                  }
                  if (node2Collapse && node2Collapse.children) {
                    node2Collapse.children = [];
                    node2Collapse.toggled = false;
                  }
                }
            }
        }
      
        node.active = true;
      
        if (node.children) {
            node.toggled = toggled;
        }
        setCursor(spareTreeDispatcher, node);
      
        if (callback) {
          callback(node, data)
        }
    }

    return { onToggle }
}

export { useSpareTreeDriver }