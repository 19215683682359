import React from 'react';
import {
    Box,
    Typography
} from '@material-ui/core';

import useStyles from './styles';

export default function CompanyItem({ company }) {
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            {
                company._links && company._links.logo ?
                    <Typography
                        variant="h5"
                        size="sm"
                        className={classes.companyName}>

                        <img src={company._links.logo + "?d=" + Date.now()} alt={company.name} style={{ maxWidth: '200px' }} />

                    </Typography>
                    :
                    <Typography
                        variant="h5"
                        size="sm"
                        className={classes.companyName}>
                        {company.header.name}
                    </Typography>
            }
        </Box>
    )
}