import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import useStyles from './styles';

export default function AreYouSureDlg(props) {

  const title = props.title?props.title:'Atención!';
  const detail1 = props.detail1?props.detail1:null;
  const detail2 = props.detail2?props.detail2:null;
  const question = props.question?props.question:'Está seguro?';
  const onOkHandler = props.ok;
  const onCancelHandler = props.cancel;
  const classes = useStyles();

  return (
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent className={classes.content}>
            <>
            {detail1 && 
                <DialogContentText className={classes.detail1}>
                    {detail1}
                </DialogContentText>
            }
            {detail2 && 
            <DialogContentText className={classes.detail2}>
                {detail2}
            </DialogContentText>
            }
            <div className={classes.questionWrapper}>
                <div className={classes.alertIcon}>
                    <ErrorOutlineRoundedIcon 
                        fontSize="large" 
                        color="primary"/>
                </div>
                <div className={classes.separator}>

                </div>
                <div className={classes.question}>
                    <span>{question}</span>
                </div>
            </div>
            </>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancelHandler} color="primary">
            Cancelar
          </Button>
          <Button onClick={onOkHandler} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
  );
}