import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import {
  Menu as MenuIcon
 } from '@material-ui/icons';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const useStyles = makeStyles((theme) => ({

  speedDial: {
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      left: theme.spacing(2),
    },
    "& > .MuiFab-root": {
      width: 40,
      height: 40
    },
    "& .MuiSpeedDial-actions .MuiSpeedDialAction-fab": {
        color: theme.palette.primary.main
      }
  },

}));

export default function SpeedDials({dialActions}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClose = (e, action) => {
    if (action === 'toggle') {
      setOpen(false);
    }
  };

  const handleOpen = (e, action) => {
      if (action === 'toggle') {
        setOpen(true);
      }
  };

  const handleClickAway = () => {
    setOpen(false);
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <SpeedDial
        ariaLabel="SpeedDial example"
        className={classes.speedDial}
        hidden={false}
        icon={<MenuIcon className={classes.icon}/>}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction={"down"}
      >
        {dialActions && dialActions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={()=>{
              handleClose();
              action.onClick();
            }}
            size="small"
          />
        ))}
      </SpeedDial>
    </ClickAwayListener>
  );
}