import {
  makeStyles
} from "@material-ui/styles";

export default makeStyles(theme => ({
  muitableActionRootFloat: {
    backgroundImage: "radial-gradient(#c5c2c2 5%, #E0E0E0 12%)",
    backgroundSize: "15px 15px",
  },
  floatingDragger: {
    backgroundColor: "#EEEEEE",
    cursor: 'grab'
  },
}));