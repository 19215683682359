import React from "react";

var SpareTreeStateContext = React.createContext();
var SpareTreeDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "SET_DATA":
      return {...state, data: action.data}
    case "SET_CURSOR":
      localStorage.setItem("currentTreeSelection", JSON.stringify(action.cursor));
      return {...state, cursor: action.cursor}
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function SpareTreeProvider({ children }) {
  var savedData = getSavedCurrentTreeSelection();
  savedData = savedData?JSON.parse(savedData):null;

  const rootNode = savedData && savedData.treeData?savedData.treeData: {
    name: 'Despieces',
    toggled: true,
    filters: [],
    path: '',
    children: []
  };

  var [state, dispatch] = React.useReducer(userReducer, {
    rootNode: rootNode,
    data: rootNode,
    cursor: savedData?savedData.cursor:false
  });

  return (
    <SpareTreeStateContext.Provider value={state}>
      <SpareTreeDispatchContext.Provider value={dispatch}>
        {children}
      </SpareTreeDispatchContext.Provider>
    </SpareTreeStateContext.Provider>
  );
}

const getSavedCurrentTreeSelection = () => {
  return localStorage.getItem("currentTreeSelection");
}

function useSpareTreeState() {
  var context = React.useContext(SpareTreeStateContext);
  if (context === undefined) {
    throw new Error("useSpareTreeState must be used within a TreeProvider");
  }
  return context;
}

function useSpareTreeDispatch() {
  var context = React.useContext(SpareTreeDispatchContext);
  if (context === undefined) {
    throw new Error("useSpareTreeDispatch must be used within a TreeProvider");
  }
  return context;
}

const useData = () => {
  const { data } = useSpareTreeState();
  return data;
}

const useCursor = () => {
  const { cursor } = useSpareTreeState();
  return cursor;
}

const setData = (dispatch, data) => {
  dispatch({type: 'SET_DATA', data: data});
}

const setCursor = (dispatch, node) => {
  dispatch({type: 'SET_CURSOR', cursor: node});
}

const useRootNode = () => {
  const { rootNode } = useSpareTreeState();
  return rootNode;
}

export {
  SpareTreeProvider,
  useSpareTreeState,
  useSpareTreeDispatch,
  useData,
  useCursor,
  setData,
  setCursor,
  useRootNode,
};

// ###########################################################