import React from "react";
import { styled } from '@material-ui/styles';

import {
  Box,
  Fab,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import HomeIcon from '@material-ui/icons/Home';
import FullscreenIcon from '@material-ui/icons/Fullscreen';


const SparePickerOsdButton = styled(Fab)({
    width: '26px',
    height: '26px',
    minHeight: '26px',
    marginLeft: '0.5rem',
});

export default function ViewerToolbar(props) {

  return (
    <div id="osd-toolbar-96"
      style={{position: 'absolute', top: 5, right: '1rem', zIndex: 10}}>
        <Box style={{flex: 1, marginBottom: 15}}>
        <div id = "coords" style={{flex: 1, minWidth: 100}}> </div>
        </Box>
        <SparePickerOsdButton id="osd-zoom-out-96" color="primary" aria-label="add">
           <RemoveIcon />
        </SparePickerOsdButton>

         <SparePickerOsdButton id="osd-zoom-in-96" color="primary" aria-label="add">
            <AddIcon />
        </SparePickerOsdButton>

        <SparePickerOsdButton id="osd-home-96" color="primary" aria-label="add">
             <HomeIcon />
        </SparePickerOsdButton>

       {false && (<><SparePickerOsdButton id="osd-rleft-96" color="primary" aria-label="add">
            <RotateLeftIcon />
        </SparePickerOsdButton>

        <SparePickerOsdButton id="osd-rright-96" color="primary" aria-label="add">
             <RotateRightIcon />
        </SparePickerOsdButton>
        </>       )}

        <SparePickerOsdButton id="osd-fulls-96" color="primary" aria-label="add">
              <FullscreenIcon />
        </SparePickerOsdButton>
    </div>
  );
}
