import React, { useCallback, useEffect, useRef } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import clsx from 'clsx';
import useStyles from './styles';

export default function MaterialTableFix(props) {
    const classes = useStyles();

    // Fake top scrollbar to fix MaterialTable
    const updateTopScrollBar = () => {
        setTimeout(() => {
            let topDivWrapper = fakeTopScrollWrapperRef.current; //document.getElementById("fakeTopScrollWrapper-" + props.tableId);

            let scrollDiv = topDivWrapper.children[0];

            let table = tableContainerRef.current.querySelector(".MuiTable-root");
            let tableWrapper = table.parentNode.parentNode;

            scrollDiv.style.width = table.offsetWidth + 'px';

            topDivWrapper.onscroll = () => {
                tableWrapper.scrollLeft = topDivWrapper.scrollLeft;
            };
        }, 1);
    }

    const measuredRef = useCallback(node => {
        if (node !== null) {
            updateTopScrollBar();
        }
    });

    useEffect(()=>{
        console.log("FLOATING: " + props.floating);
    })

    const tableContainerRef = useRef();
    const fakeTopScrollWrapperRef = useRef();

    let newProps = { ...props };
    let components = { ...newProps.components };
    delete newProps.components;
    return (
        <div className={classes.container} ref={tableContainerRef}>

            <MaterialTable
                tableRef={measuredRef}
                components={{
                    Toolbar: props => (
                        <div id="toolbarCustomWrapper">
                            <div id="floatingDragger" className={classes.floatingDragger} classes={"floatingDraggerZ"}>
                                <MTableToolbar {...props}
                                    classes={{
                                        root: clsx({ [classes.muitableActionRootFloat]: props.floating })
                                    }}
                                >
                                </MTableToolbar>
                            </div>
                            <div id="fakeTopScrollWrapper" ref={fakeTopScrollWrapperRef} style={{ marginTop: 2, paddingTop: 1, width: '100%', overflowX: 'scroll' }}>
                                <div style={{ height: 1 }}>
                                </div>
                            </div>
                        </div>
                    ),
                    ...components
                }}
                {...newProps}

            />
        </div>
    )
}