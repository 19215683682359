import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    underline: {
      "&&&:before": {
        borderBottom: "none"
      },
      "&&:after": {
        borderBottom: "none"
      }
    },
    option: {
      minHeight: 60,
      paddingTop: 7,
      paddingBottom: 7,
      color: theme.palette.text.primary,
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.text.disabled,
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.text.primary,
        cursor: 'pointer',
      }
    },
    listbox: {
      paddingTop: 18
    },
    inputRoot: {
      position: 'relative',
      top: -5,
    },
    root: {
      color: 'white',
      maxWidth: '92%'
    },
    searchResultBox: {
      width: '100%', 
      display: 'flex', 
      flexDirection: 'column'
    },
    searchResultTitle: {
      fontSize: "0.8em"
    },
    searchResultSubitle: {
      width: '100%', 
      fontSize: 10
    }
  }));