import React from "react";
import treeTheme from "./theme";
import clsx from 'clsx';
import useStyles from "../styles";

import {
  Box,
} from '@material-ui/core';

import { Treebeard } from 'react-treebeard';

import Config from 'config';
import { useSnackbar } from "notistack";
import { apiFetch } from 'utils/fetchutils';
import { setData, useCursor, useData, useRootNode, useSpareTreeDispatch } from "context/SpareTreeContext";
import { useSpareTreeDriver } from '../SpareTreeDriver';
import { useSpareState } from 'context/SpareContext';
import { useHistory } from "react-router-dom";

const saveCurrentTreeSelection = (data, cursor) => {
  localStorage.setItem("currentTreeSelection", JSON.stringify({ treeData: data, cursor: cursor }));
}

export default function SpareTree(props) {
  const classes = useStyles();
  const history = useHistory();

  const spareTreeDispatcher = useSpareTreeDispatch();

  const { enqueueSnackbar } = useSnackbar();
  const { isSpareTreeOpened } = useSpareState();

  const rootNode = useRootNode();
  const data = useData();
  const cursor = useCursor();
  const { onToggle } = useSpareTreeDriver();

  const loadSpareClassifications = async (filters, callback) => {
    const data = await apiFetch(Config.CLASSIFICATIONSAPI_URL + "?p=" + filters, null, {}, {}, enqueueSnackbar);

    callback(data);
  }

  const toggle = (node, toggled) => {
    onToggle(node, toggled, (node, data) => {
      if (node.children) {
        if (toggled) {
          loadSpareClassifications(node.filters, items => {
            node.children = getNodesFromClassification(node, items);
            setData(spareTreeDispatcher, Object.assign({}, data));
          });

        } else {
          node.children = [];
          setData(spareTreeDispatcher, Object.assign({}, data));
        }
      } else {
        saveCurrentTreeSelection(data, cursor);
        props.onSelectSpare();

        let navUrl;
        if (Config.SpareActions.EDIT.url !== history.location.pathname
          && Config.SpareActions.BROWSE.url !== history.location.pathname) {
          navUrl = Config.SpareActions.BROWSE.url;
        } else {
          navUrl = history.location.pathname;
        }
        history.push(navUrl + "/" + node.spare_id);
      }
    })
  }

  const getNodesFromClassification = (node, levels) => {
    if (!levels || levels.length === 0) return [];

    return levels.map((val, idx) => {
      let newChild = {
        'name': val.name,
        'filters': [...node.filters, val.name],
        'path': (node.path !== '' ? node.path + "$$$" + idx : '' + idx),
        'spare_id': val.spare_id
      }
      if (!val.isLeaf) {
        newChild.children = [];
      }

      return newChild;
    }
    )
  }

  //  useEffect(() => {
  if (rootNode.children.length === 0) {
    loadSpareClassifications('', (child) => {
      let nodes = getNodesFromClassification(rootNode, child);
      rootNode.children = nodes;
      if (nodes && nodes.length > 0) {
        toggle(nodes[0], true);
      }
    });
  }
  //  }, []);

  return (
    <Box
      style={{ height: '100%', minHeight: '100%', width: '100%' }}
      title="">
      <div style={{ with: '100%', height: '100%' }} id="zz"
        className={clsx({ [classes.hidden]: !isSpareTreeOpened })}>

        <Treebeard
          data={data}
          onToggle={toggle}
          style={treeTheme} />

      </div>
    </Box>
  );
}
