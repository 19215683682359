/**
 * Agilemind Soft - Spareclick API
 * Spares API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: alex@agilemindsoft.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The Classification model module.
* @module model/Classification
* @version 1.0.0
*/
export default class Classification {
    /**
    * Constructs a new <code>Classification</code>.
    * @alias module:model/Classification
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>Classification</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Classification} obj Optional instance to populate.
    * @return {module:model/Classification} The populated <code>Classification</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Classification();
                        
            
            if (data.hasOwnProperty('company_id')) {
                obj['company_id'] = ApiClient.convertToType(data['company_id'], 'String');
            }
            if (data.hasOwnProperty('brand_id')) {
                obj['brand_id'] = ApiClient.convertToType(data['brand_id'], 'String');
            }
            if (data.hasOwnProperty('path')) {
                obj['path'] = ApiClient.convertToType(data['path'], ['String']);
            }
        }
        return obj;
    }

    /**
    * @member {String} company_id
    */
    'company_id' = undefined;
    /**
    * @member {String} brand_id
    */
    'brand_id' = undefined;
    /**
    * @member {Array.<String>} path
    */
    'path' = undefined;




}
