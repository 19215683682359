import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    stepLabel: {
        color: theme.palette.text.primary,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    details: {
        width: '75%',
        textAlign: 'center',
        color: theme.palette.primary.main
    },
    muitableActionRoot: {
        "& button": {
            marginLeft: 3,
            backgroundColor: theme.palette.primary.main,
            padding: 6,
            "&:hover": {
                backgroundColor: theme.palette.primary.dark,
            }
        },
        "& > .MuiFormControl-root": {
            marginRight: 10
        },
    },
    datatable: {
        width: '99%',
        maxWidth: '99%'
    },
    screen1: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        "& > *": {
            paddingBottom: 20,
        },
    },
    nextButtonContainer: {
        width: '100%',
        textAlign: 'right',
    },
    logoImageContainer: {
        width: '100%',
        minWidth: 560,
        height: 300,
        position: 'relative',
        textAlign: 'center'
    },
    logoImageTrashIcon: {
        float: 'right',
        marginRight: 50,
    },
    MuiDropzoneAreaRoot: {
        width: '100%',
        height: 300,
    },
    MuiDropzoneAreaText: {
        marginTop: '20%'
    },
    screen2ButtonsContainer: {
        display: 'flex',
        width: '100%',
        marginTop: 20,
        justifyContent: 'space-between'
    },
    logoMaxSizeWarning: {
        width: '100%',
        textAlign: 'center'
    }, 
    screen2: {

    },
    logo: {
        maxWidth: 320,
        maxHeight: 200,
        margin: '0 auto',
        marginTop: 40,
    }
}))